import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button,Card, Image, Row, Col,Container } from 'react-bootstrap';

import { initializeApp } from "firebase/app";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { app } from './firebase'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/Navbar';
import { Space, FooterNotFixed, ImageBar } from './component/PageElement';
import { Icon } from '@iconify/react';

import LineIcon from './images/line_crop.png';
import example2 from './images/example2.jpg';
import example3 from './images/example3.jpg';
import example4 from './images/example4.png';

function Home() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState({});
	
	const auth = getAuth(app);
	onAuthStateChanged(auth, (user) => {
	  if (user) {
		const uid = user.uid;
		console.log('user login:'+ uid);
		setCurrentUser(user);
		setIsLoading(false);	
	  } else {
		console.log('user not login');
		setIsLoading(false);	
	  }
	});
		
	useEffect(() => {
	}, [])
	
	const handleClickScroll = (id) => {
		console.log('click scroll:'+id);
		if(id === 'top') {
			window.scrollTo(0, 0)
		} else {
			const element = document.getElementById(id);
			if (element) {
			  // 👇 Will scroll smoothly to the top of the next section
			  console.log('click element to scroll:'+element);
			  element.scrollIntoView({ behavior: 'smooth' });
			}
		}
	  };
	
	return (		
		<div>
			<Navbar showTitle={false} auth={auth.currentUser} handleClickScroll={handleClickScroll}/>	
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
					<div className="row">
						<div className="alignCenter col-12" style={{marginTop:60}}>
						</div>	
						{
						isLoading ?
						<span style={{marginTop:0,fontSize:24}} className="alignCenter">Loading...</span>
						:
						<div className="col-12"  style={{padding:20,margin:0,backgroundColor:'#ffffff'}}>
							<Space h={16}/>	
							<div id="subscribe_bar"></div>									
							<SubscribeBar/>
							<Space h={48}/>	
							<div id="price_bar"></div>									
							<PriceBar/>
							<Space h={48}/>		
							<div id="feature_bar"></div>							
							<FeatureBar/>
							<Space h={48}/>	
							<div id="portfolio_bar"></div>	
							<PortfolioBar/>
							<Space h={48}/>			
							<div id="contact_bar"></div>									
							<ContactBar/>
							<Space h={32}/>
							
						</div>
						}
					</div>					
				</div>				
				<FooterNotFixed/>
		</div>
	);

}
function SubscribeBar(props) {
	return (
	<Container >
	<Row xs="auto">	 
		<Col xs={12} md={5} sm style={{backgroundColor:'white',color:'black'}} className="leftTopBar center">
			<Row xs="auto">	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white',color:'black'}} className="ql-align-center">
					<Row xs="auto">						
						<Col xs={12} md={12} sm><h2>เฮงเฮงเซลเพจ</h2></Col>
					</Row>
					<Row xs="auto">	 
						<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="ql-align-center">
						ช่วยเพิ่มยอดขายด้วยแพคเกจเริ่มต้น 99 บาท
						<Space color="#ffffff" h={24}/>			
						<Button type='button' className="blackButton">สมัครใช้บริการ</Button>
						</Col>
					</Row>				
				</Col>				
			</Row>
		</Col>	 
		<Col xs={12} md={7} sm className="ql-align-right" style={{backgroundColor:'white'}}>
			<img src={example2} style={{width:'100%'}}/>
		</Col>	 
		
	</Row>
	</Container>
	)
}

function PriceBar(props) {
	return (
	<Container >
	<Row xs="auto">	 
		<Col xs={12} md={12} className="ql-align-center" style={{marginTop:32,marginBottom:32}}>
			<h1>ราคาแพคเกจพรีเมี่ยม</h1>
		</Col>
		<Col xs={12} md={4} sm style={{backgroundColor:'white'}} className="center">
			<Card className="card-center text-center">
			  <Space color="#ffffff" h={16}/>	
			  <Card.Title><h4>แพคเกจพรีเมี่ยม 7 วัน</h4></Card.Title>
			  <span style={{marginTop:-16,marginBottom:20}}>Premium 7 days</span>
			  <Card.Img variant="top"  src={require('./images/example4.png')} style={{width:100}}/>
			  <Card.Body>
				<div className="ql-align-left" style={{backgroundColor:'white',margin:0}}>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  ใช้งานได้ 7 วัน.
  				    </div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  ระบบหลังบ้านใช้ได้ 1 เดือน
  				    </div>					
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					 ระบบเช็ค Order
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					Pixel Facebook
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					Google Tag
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  Export ไปยังขนส่ง
  				    </div>
					<div>
					<Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					1 Sale page
					</div>
				</div>
				<Space color="#ffffff" h={24}/>	
				<Button variant="primary" style={{paddingLeft:32,paddingRight:32}}>สมัครใช้งาน</Button>
			  </Card.Body>
			</Card>		
		</Col>
		<Col xs={12} md={4} sm style={{backgroundColor:'white'}} className="center">
			<Card className="card-center text-center">
			  <Space color="#ffffff" h={16}/>	
			  <Card.Title><h4>แพคเกจพรีเมี่ยม 14 วัน</h4></Card.Title>
			  <span style={{marginTop:-16,marginBottom:20}}>Premium 14 days</span>
			  <Card.Img variant="top"  src={require('./images/example4.png')} style={{width:100}}/>
			  <Card.Body>
				<div className="ql-align-left" style={{backgroundColor:'white',margin:0}}>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  ใช้งานได้ 14 วัน
  				    </div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  ระบบหลังบ้านใช้ได้ 1 เดือน
  				    </div>					
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					 ระบบเช็ค Order
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					Pixel Facebook
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					Google Tag
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  Export ไปยังขนส่ง
  				    </div>
					<div>
					<Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					1 Sale page
					</div>
				</div>
				<Space color="#ffffff" h={24}/>	
				<Button variant="primary" style={{paddingLeft:32,paddingRight:32}}>สมัครใช้งาน</Button>
			  </Card.Body>
			</Card>		
		</Col>
		<Col xs={12} md={4} sm style={{backgroundColor:'white'}} className="center">
			<Card className="card-center text-center">
			  <Space color="#ffffff" h={16}/>	
			  <Card.Title><h4>แพคเกจพรีเมี่ยม 1 เดือน</h4></Card.Title>
			  <span style={{marginTop:-16,marginBottom:20}}>Premium 1 month</span>
			  <Card.Img variant="top"  src={require('./images/example4.png')} style={{width:100}}/>
			  <Card.Body>
				<div className="ql-align-left" style={{backgroundColor:'white',margin:0}}>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  ใช้งานได้ 1 เดือน
  				    </div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  ระบบหลังบ้านใช้ได้ 2 เดือน
  				    </div>					
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					 ระบบเช็ค Order
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					Pixel Facebook
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					Google Tag
					</div>
					<div>
					  <Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					  Export ไปยังขนส่ง
  				    </div>
					<div>
					<Icon icon="material-symbols:check-small-rounded" className="iconSmall" style={{color:'#999999'}}/>
					1 Sale page
					</div>
				</div>
				<Space color="#ffffff" h={24}/>	
				<Button variant="primary" style={{paddingLeft:32,paddingRight:32}}>สมัครใช้งาน</Button>
			  </Card.Body>
			</Card>		
		</Col>		
	</Row>
	</Container>
	)
}
function FeatureBar(props) {
	return (
	<Container >
	<Row xs="auto">	 
		<Col xs={12} md={12} className="ql-align-center" style={{marginTop:32,marginBottom:32}}>
			<h1>ฟังก์ชั่นการใช้งาน</h1>
		</Col>
	</Row>
	<Row xs="auto" className="ql-align-center">	 
		<Col xs={12} md={3.5} sm style={{backgroundColor:'white'}} className="center">
			<Card className="card-center text-center" style={{padding:18}} border="light">
			  <Card.Img variant="top"  src={require('./images/example4.png')} style={{width:100}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
			  </Card.Body>
			</Card>
		</Col>
		<Col xs={12} md={3.5} sm style={{backgroundColor:'white'}} className="center" >
			<Card className="card-center text-center" style={{padding:18}} border="light">
			  <Card.Img variant="top"  src={require('./images/example4.png')} style={{width:100}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
			  </Card.Body>
			</Card>
		</Col>
		<Col xs={12} md={3.5} sm style={{backgroundColor:'white'}} className="center" >
			<Card className="card-center text-center" style={{padding:18}} border="light">
			  <Card.Img variant="top"  src={require('./images/example4.png')} style={{width:100}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
			  </Card.Body>
			</Card>
		</Col>
	</Row>
	</Container>
	)
}


function PortfolioBar(props) {
	return (
	<Container >
	<Row xs="auto">	 
		<Col xs={12} md={12} className="ql-align-center" style={{marginTop:32,marginBottom:32}}>
			<h1>ตัวอย่างผลงาน</h1>
		</Col>
	</Row>
	<Row xs="auto"  style={{marginTop:16,marginBottom:8}}> 
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
	</Row>
	<Row xs="auto"  style={{marginTop:16,marginBottom:8}}> 
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
	</Row>
	<Row xs="auto"  style={{marginTop:16,marginBottom:8}}> 
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
		<Col xs={12} md={4} className="ql-align-center">
			<img src={example3} style={{width:'95%',marginBottom:8}}/>	
		</Col>
	</Row>
	</Container>
	)
}

function ContactBar(props) {
	return (
	<Container>
	<Row xs="auto">	 
		<Col xs={12} md={12} className="ql-align-center" style={{marginTop:32,marginBottom:32}}>
			<h1>ติดต่อเรา</h1>
		</Col>
	</Row>
	<Row xs="auto"  style={{marginTop:16,marginBottom:16,backgroundColor:'red'}}>
		<Col xs={12} md={12} sm style={{backgroundColor:'white',color:'black'}} className="ql-align-center">
			<Row xs="auto" className="ql-align-center" style={{marginBottom:'10px'}}>	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="contentBox ql-align-left">
						<Row>
						<Col xs={2} md={1}><Icon icon='ic:outline-email' style={{color:'#000000',fontSize:'36px'}}/>
						</Col>
						<Col xs={10} md={5} style={{marginTop:'6px'}}><h5><b>Email</b></h5></Col>
						<Col xs={10} md={5} style={{marginTop:'6px',color:'#666666'}}><h5>teste@test.com</h5></Col>						
						</Row>
						<Space color="#ffffff" h={1}/>			
						
				</Col>
			</Row>
			<Row xs="auto" className="ql-align-center" style={{marginBottom:'10px'}}>	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="contentBox ql-align-left">
						<Row>
						<Col xs={2} md={1}>
						<img src={LineIcon} style={{color:'#000000',width:'36px'}}/>
						</Col>
						<Col xs={10} md={5} style={{marginTop:'6px'}}><h5><b>LINE</b></h5></Col>
						<Col xs={10} md={5} style={{marginTop:'6px',color:'#666666'}}><h5>@henghengsalepage</h5></Col>
						</Row>	
						
				</Col>
			</Row>
			<Row xs="auto" className="ql-align-center" style={{marginBottom:'10px'}}>	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="contentBox ql-align-left">
						<Row>
						<Col xs={2} md={1}><Icon icon='ic:baseline-phone' style={{color:'#000000',fontSize:'36px'}}/>
						</Col>
						<Col xs={10} md={5} style={{marginTop:'6px'}}><h5><b>Telephone</b></h5></Col>
						<Col xs={10} md={5} style={{marginTop:'6px',color:'#666666'}}><h5>081-xxx-xxxx</h5></Col>
						</Row>	
						
				</Col>
			</Row>			
		</Col>				
	</Row>
	
	
	
	</Container>
	)
}

function ContactBar1(props) {
	return (
	<Container>
	<Row xs="auto">	 
		<Col xs={12} md={12} className="ql-align-center" style={{marginTop:32,marginBottom:32}}>
			<h1>ติดต่อเรา</h1>
		</Col>
	</Row>
	<Row xs="auto"  style={{marginTop:16,marginBottom:16,backgroundColor:'red'}}>
		<Col xs={12} md={12} sm style={{backgroundColor:'white',color:'black'}} className="ql-align-center">
			<Row xs="auto" className="ql-align-center" style={{marginBottom:'10px'}}>	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="contentBox ql-align-left">
						<Row>
						<Col xs={1} md={1}><Icon icon='ic:outline-email' style={{color:'#000000',fontSize:'36px'}}/>
						</Col>
						<Col xs={11} md={11} style={{marginTop:'8px'}}><h5><b>Email</b></h5></Col>
						</Row>
						<Space color="#ffffff" h={1}/>			
						teste@test.com
				</Col>
			</Row>
			<Row xs="auto" className="ql-align-center" style={{marginBottom:'10px'}}>	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="contentBox ql-align-left">
						<Row>
						<Col xs={1} md={1}>
						<img src={LineIcon} style={{color:'#000000',width:'36px'}}/>
						</Col>
						<Col xs={11} md={11} style={{marginTop:'8px'}}><h5><b>Email</b></h5></Col>
						</Row>
						<Space color="#ffffff" h={1}/>			
						@henghengsalepage
				</Col>
			</Row>
			<Row xs="auto" className="ql-align-center" style={{marginBottom:'10px'}}>	 
				<Col xs={12} md={12} sm style={{backgroundColor:'white'}} className="contentBox ql-align-left">
						<Row>
						<Col xs={1} md={1}><Icon icon='ic:baseline-phone' style={{color:'#000000',fontSize:'36px'}}/>
						</Col>
						<Col xs={11} md={11} style={{marginTop:'8px'}}><h5><b>Telephone</b></h5></Col>
						</Row>
						<Space color="#ffffff" h={1}/>			
						081-xxx-xxxx
				</Col>
			</Row>			
		</Col>				
	</Row>
	
	
	
	</Container>
	)
}


export default Home;



/*
function PriceBarTest(props) {
	return (
	<Container>
	<Row xs="auto" className="center-mobile" >	 
		<Col sm className="center">
			<Card style={{ width: '18rem', marginBottom:16 }}>
			  <Card.Img variant="top" src={LineIcon} height="200" style={{padding:16}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
				<Button variant="primary">Go somewhere</Button>
			  </Card.Body>
			</Card>
		</Col>	 
		<Col sm className="center">
			<Card style={{ width: '18rem', marginBottom:16 }}>
			  <Card.Img variant="top" src={LineIcon} height="200" style={{padding:16}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
				<Button variant="primary">Go somewhere</Button>
			  </Card.Body>
			</Card>
		</Col>	 
		<Col sm className="center">
			<Card style={{ width: '18rem' , marginBottom:16}}>
			  <Card.Img variant="top" src={LineIcon} height="200" style={{padding:16}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
				<Button variant="primary">Go somewhere</Button>
			  </Card.Body>
			</Card>
		</Col>
	</Row>
	</Container>
	)
}

function Banner(props) {
	const [showLoading, setShowLoading] = useState(false);
	console.log('user:'+props.currentUser);
	return(
		<div style={{margin:20}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>Loading...</div>
				</div>
			</div>
			<div style={{ margin:0,backgroundColor:'#cccccc'}}>
			<img src={LineIcon} width='60%' className='ql-align-center' style={{backgroundColor:'red'}}/>  
			</div>
			<Space h={16}/>	
			
		</div>			
	);
}

function MenuTest(props) {
	const [showLoading, setShowLoading] = useState(false);
	console.log('user:'+props.currentUser);
	return(
		<div style={{margin:20}}>			
			<Link to="/salepage?hhid=XBZHYXce5XQ45NcDGQCGsfO1UAB2_1680015380736">
				<Button className="bigButton">Test SalePage</Button>
			</Link>
			<Space color='#ffffff' h={20}/>
			<Link to="/login">
				<Button className="bigButton">Login</Button>
			</Link>
			<Space color='#ffffff' h={20}/>
			<Link to="/register">
				<Button className="bigButton">Register</Button>
			</Link>		
		</div>			
	);
}



<Row xs="auto">	 
		<Col sm>
			<Card >
			  <Card.Img variant="top" src={LineIcon} height="200" style={{padding:16}}/>
			  <Card.Body>
				<Card.Title>Card Title</Card.Title>
				<Card.Text>
				  Some quick example text to build on the card title and make up the
				  bulk of the card's content.
				</Card.Text>
				<Button variant="primary">Go somewhere</Button>
			  </Card.Body>
			</Card>
        </Col>

	</Row>
return (		
		<div>
			<Navbar showTitle={false} />	
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
					<div className="row">
						<div className="alignCenter col-12" style={{marginTop:20}}>
							<h3><b>HOME</b></h3>
						</div>	
						{
						isLoading ?
						<span style={{marginTop:50,fontSize:24}} className="alignCenter">Loading...</span>
						:
						!auth.currentUser ?
							<div className="col-12">
							<div className="alignCenter" style={{margin:30}}>กรุณา login ก่อนเข้าใช้งาน</div>
								<Link to="/login">
								<Button type='submit' className="loginButton">เข้าสู่ระบบ</Button>
								</Link>
							</div>
							:
							<div className="col-12">
								<Banner/>
								<Space h={16}/>							
								<PriceBar/>
								<Space h={16}/>	
								<MenuTest/>
							</div>
						}
					</div>
				</div>
		</div>
	);
*/

/*
function Banner(props) {
	const [showLoading, setShowLoading] = useState(false);
	console.log('user:'+props.currentUser);
	return(
		<div style={{margin:20}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>Loading...</div>
				</div>
			</div>
			<div style={{ margin:20 }}>
				<ImageBar src={LineIcon} alt=""/>	
			</div>
			<Space h={16}/>
			<Link to="/salepage?hhid=XBZHYXce5XQ45NcDGQCGsfO1UAB2_1680015380736">
				<Button className="bigButton">Test SalePage</Button>
			</Link>
			<Space color='#ffffff' h={20}/>
			<Link to="/login">
				<Button className="bigButton">Login</Button>
			</Link>
			<Space color='#ffffff' h={20}/>
			<Link to="/register">
				<Button className="bigButton">Register</Button>
			</Link>			
		</div>			
	);
}

*/