import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col, Modal, ModalHeader, ModalTitle, ModalFooter, ModalBody} from 'react-bootstrap';
import { Icon } from '@iconify/react';

import { initializeApp } from "firebase/app";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { app } from './firebase'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Editor from './Editor';
import renderHTML from 'react-render-html';
import { format } from 'date-fns';


import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { setDoc, doc, collection, addDoc, serverTimestamp, updateDoc, getDoc	} from "firebase/firestore"; 
import { db } from './firebase'; 


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import SideBar from './component/SideBarMember';
import { Space, FooterMain } from './component/PageElement';

function EditSalePage() {
	
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState({});
	const [pageData, setPageData] = useState({});
	
	const auth = getAuth();
	onAuthStateChanged(auth, (user) => {
	  if (user) {
		const uid = user.uid;
		console.log('user login:'+ uid);
		//setCurrentUser(user);
		getSalepageData(locationRef.current.state.data.id);
		//setIsLoading(false);	
	  } else {
		console.log('user not login');
		setIsLoading(false);	
	  }
	});
		
	useEffect(() => {
		//checkAuthen();
		//setIsLoading(false);	
	}, [])

	/*const checkAuthen = async () => {
		const auth = await getAuth(app);
		const user = auth.currentUser;
		if(!user){
			console.log('login user:'+auth.currentUser+','+auth.currentUser);
			setCurrentUser(user);
		}			
	}*/
	
	const getSalepageData = async (id) => {
		console.log('get salepage data by id:'+id);
		const docRef = doc(db, "salepages", id);
		console.log('search2:'+docRef);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			console.log("Document data:", docSnap.data());
			pageData.data = docSnap.data();
		} else {
			console.log("No such document!");
		}	
		setIsLoading(false);			
	}
	
	return (		
		<div>
			<Navbar showTitle={false} />	
				<div className="row">
				<div className="col-2">
					<SideBar />
				</div>
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="col-10 alignCenter">
					<div className="row"  style={{marginTop:60}}>
						<div className="alignCenter col-12" style={{marginTop:20}}>
							<h3><b>แก้ไข Sale Page</b></h3>
						</div>	
						{
						isLoading ?
						<span style={{marginTop:50,fontSize:24}} className="alignCenter">Loading...</span>
						:
						!auth.currentUser ?
							<div className="col-12">
							<div className="alignCenter" style={{margin:30}}>กรุณา login ก่อนเข้าใช้งาน</div>
								<Link to="/login">
								<Button type='submit' className="loginButton">เข้าสู่ระบบ</Button>
								</Link>
							</div>
							:
							<div className="col-12">
								<EditSalePageForm user={auth.currentUser} data={pageData.data}/>
							</div>
						}
					</div>
				</div>
				</div>
		</div>
	);
	

}

function EditSalePageForm(props) {
	const [showLoading, setShowLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	
	const [showIconSelect, setShowIconSelect] = useState(false);
	const [iconNo, setIconNo] = useState(0);
	const [remarkIcon, setRemarkIcon] = useState([]);
	
	const [showCreateLink, setShowCreateLink] = useState(false);
	const [linkNo, setLinkNo] = useState(0);
	const [linkContentList, setLinkContentList] = useState([]);
	const [linkHtml, setLinkHtml] = useState('');
	const [showDraftLink, setShowDraftLink] = useState(false);
	const [draftHtml, setDraftHtml] = useState('');

	const quillRef = useRef();	
	const navigate = useNavigate();
	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})	
	const [errorProductDesc, setErrorProductDesc] = useState('');	
	const setField = (field, value) => {
		console.log('set field:'+field+','+value);
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	  }
		
	const [deliveryOptionCount,setDeliveryOptionCount] = useState(1);
	const [promotionCount,setPromotionCount] = useState(1);
	const [imageCount,setImageCount] = useState(1);
	const [remarkCount,setRemarkCount] = useState(1);
	const [linkCount,setLinkCount] = useState(1);
		
	const [saleId, setSaleId] = useState('');	
	const [imageList, setImageList] = useState([]);
	const [saveImageList, setSaveImageList] = useState([]); //for edit
	const [promotions, setPromotions] = useState([]);
	const [links, setLinks] = useState([]);	
	
	const [html, setHtml] = useState("");
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [instructionFile, setInstructionFile] = useState(null);
	const [instructionURL, setInstructionURL] = useState('');
	const [instructionImages, setInstructionImages] = useState([]); //for edit
	
	//for editing
	const [isPreparing, setIsPreparing] = useState(true);
	const [deliveryOwnList, setDeliveryOwnList] = useState([]);
	const [deliveryList, setDeliveryList] = useState([]);
	const [remarks, setRemarks] = useState([]);
	const [showInstructionUpload, setShowInstructionUpload] = useState(true);
	
	const prepareDefaultValue = () => {
			console.log('preparing default value:'+props.data.product_name);
			console.log('delivery:'+JSON.stringify(props.data.delivery_options));
			
			
			console.log('delivery options:'+props.data.delivery_options+','+props.data.delivery_options[0]);
			var i = 1;
			var dcount = deliveryOptionCount;				
			var delivery_option1=false;
			var delivery_option2=false;
			var delivery_option_other=false;
			var delivery_own = [undefined,undefined,undefined,undefined];
			props.data.delivery_options.map((data,key)=>{	
				console.log('test:'+data+','+i)
				if(data === 'ส่งฟรี') {
					delivery_option1=true;
				} else if(data === 'มีเก็บเงินปลายทาง') {
					delivery_option2=true;
				} else {
					delivery_option_other = true;
					delivery_own[i-1] = data; 
					deliveryOwnList.push(data);
					setDeliveryOptionCount(dcount);
					i++;
					dcount++;
				}
			});
			setDeliveryList([delivery_option1,delivery_option2]);
			console.log('deliverList:'+deliveryList);
			
			//promotion
			var i = 1;
			var pcount = promotionCount;
			var promoTemp = [undefined,undefined,undefined,undefined,undefined];
			var priceTemp = [undefined,undefined,undefined,undefined,undefined];
			var discountTemp = [undefined,undefined,undefined,undefined,undefined];
			var amountTemp = [undefined,undefined,undefined,undefined,undefined];
			props.data.promotions.map((data,key)=>{	
				console.log('pro:'+i+','+data.promo_desc+','+data.promo_price)	
				var desc = data.promo_desc;
				var price = data.promo_price;
				var amt = data.promo_amount;
				var disc = data.promo_discount;
				setPromotionCount(pcount);
				//setField('promotion_desc'+i,desc);	
				//setField('promotion_price'+i,price);
				promoTemp[i-1]=desc;
				priceTemp[i-1]=price;
				discountTemp[i-1]=disc;
				amountTemp[i-1]=amt;
				i++;
				pcount++;
			});
			//remarks
			var i = 1;
			var rcount = remarkCount;
			var remarkTemp = [undefined,undefined,undefined,undefined,undefined];
			//var remarkIconTemp = [undefined,undefined,undefined,undefined,undefined];
			//var remarkTempList = [];
			props.data.remarks.map((data,key)=>{		
				var desc = data.remark_desc;
				var icon = data.remark_icon;		
				setRemarkCount(rcount);
				remarkTemp[i-1] = desc;
				//remarkIconTemp[i-1] = icon;
				//setField('remark_desc'+i,desc);	
				//setField('remark_icon'+i,icon);
				console.log('remark data:'+desc+','+icon);
				if(icon!==undefined && icon!=='') {
					setIconNo(i-1);
					handleSelectIcon(icon);
				}
				i++;
				rcount++;
			});
			
			var i = 1;
			var lcount = linkCount;
			var linkTemp = [undefined,undefined,undefined,undefined,undefined];
			var linkUrlTemp = [undefined,undefined,undefined,undefined,undefined];
			props.data.links.map((data,key)=>{		
				var desc = data.link_desc;
				var url = data.link_url;	
				var content = data.link_content;		
				setLinkCount(lcount);
				linkTemp[i-1] = desc;
				linkUrlTemp[i-1] = url;
				console.log('link data:'+desc+','+url);
				if(url === 'ใช้ที่สร้างใหม่'){
					linkContentList[i-1] = content;				
				} else {
					linkContentList[i-1] = undefined;		
				}
				i++;
				lcount++;
			});
			
			
			/*setField("product_name",props.data.product_name);
			setField("product_id",props.data.product_id);
			setField("sale_price",props.data.sale_price);
			setField("ori_price",props.data.ori_price);
			setField("discount",props.data.discount);
			
			setField("contact_type",props.data.store_contact.contact_type);
			setField("contact_detail",props.data.store_contact.contact_info);			
			setField("aboutus",props.data.about_us.about_detail);
			setField("facebookPixel",props.data.facebook_pixel);
			setField("googleTag",props.data.google_tag);
			
			console.log('delivery options:'+props.data.delivery_options+','+props.data.delivery_options[0]);
			var i = 1;
			var dcount = deliveryOptionCount;				
			var a=false;
			var b=false;
			props.data.delivery_options.map((data,key)=>{	
				console.log('test:'+data+','+i)

				if(data === 'ส่งฟรี') {
					setField('delivery_option1',true);
					a = true;
					//console.log('delivery options:'+delivery_option1);
				} else if(data === 'มีเก็บเงินปลายทาง') {
					setField('delivery_option2',true);
					b = true;
				} else {
					setField('delivery_option_other',true);
					setField('delivery_option_own'+i,data);
					deliveryOwnList.push(data);
					setDeliveryOptionCount(dcount);
					i++;
					dcount++;
				}
			});
			setDeliveryList([a,b]);
			console.log('deliverList:'+deliveryList);
			*/
			/*
			//promotion
			var i = 1;
			var pcount = promotionCount;
			props.data.promotions.map((data,key)=>{	
				console.log('pro:'+i+','+data.promo_desc+','+data.promo_price)	
				var desc = data.promo_desc;
				var price = data.promo_price;		
				setPromotionCount(pcount);
				setField('promotion_desc'+i,desc);	
				setField('promotion_price'+i,price);
				i++;
				pcount++;
			});
			//remarks
			var i = 1;
			var rcount = remarkCount;
			props.data.remarks.map((data,key)=>{		
				var desc = data.remark_desc;
				var icon = data.remark_icon;		
				setRemarkCount(rcount);
				setField('remark_desc'+i,desc);	
				setField('remark_icon'+i,icon);
				//remarkIcon.push(icon);
				console.log('remark data:'+desc+','+icon);
				if(icon!==undefined && icon!=='') {
					setIconNo(i-1);
					handleSelectIcon(icon);
				}
				i++;
				rcount++;
			});
			//remarks
			var i = 1;
			var lcount = linkCount;
			props.data.links.map((data,key)=>{		
				var desc = data.link_desc;
				var url = data.link_url;	
				var content = data.link_content;		
				setLinkCount(lcount);
				setField('link_desc'+i,desc);	
				setField('link_url'+i,url);
				console.log('link data:'+desc+','+url);
				if(url === 'ใช้ที่สร้างใหม่'){
					linkContentList[i-1] = content;				
				} else {
					linkContentList[i-1] = undefined;		
				}
				i++;
				lcount++;
			});
			
			//console.log('prepared field value:'+JSON.stringify(form));
			console.log('remark icon:'+remarkIcon);
			console.log('links:'+linkContentList);
			*/
			let data = 
			{
				"product_name": props.data.product_name,
				"product_id": props.data.product_id,
				"sale_price": props.data.sale_price,
				"ori_price": props.data.ori_price,
				"discount": props.data.discount,
				"contact_type": props.data.store_contact.contact_type,
				"contact_detail": props.data.store_contact.contact_info,
				"aboutus": props.data.about_us.about_detail,
				"facebookPixel": props.data.facebook_pixel,				
				"googleTag": props.data.google_tag,
				"delivery_option1" : delivery_option1,
				"delivery_option2" : delivery_option2,
				"delivery_option_other" : delivery_option_other,
				"delivery_option_own1" : delivery_own[0],
				"delivery_option_own2" : delivery_own[1],
				"delivery_option_own3" : delivery_own[2],
				"delivery_option_own4" : delivery_own[3],
				"promotion_desc1": promoTemp[0],
				"promotion_desc2": promoTemp[1],
				"promotion_desc3": promoTemp[2],
				"promotion_desc4": promoTemp[3],
				"promotion_desc5": promoTemp[4],
				"promotion_price1": priceTemp[0],
				"promotion_price2": priceTemp[1],
				"promotion_price3": priceTemp[2],
				"promotion_price4": priceTemp[3],
				"promotion_price5": priceTemp[4],
				"promotion_discount1": discountTemp[0],
				"promotion_discount2": discountTemp[1],
				"promotion_discount3": discountTemp[2],
				"promotion_discount4": discountTemp[3],
				"promotion_discount5": discountTemp[4],
				"promotion_amount1": amountTemp[0],
				"promotion_amount2": amountTemp[1],
				"promotion_amount3": amountTemp[2],
				"promotion_amount4": amountTemp[3],
				"promotion_amount5": amountTemp[4],				
				"remark1": remarkTemp[0],
				"remark2": remarkTemp[1],
				"remark3": remarkTemp[2],
				"remark4": remarkTemp[3],
				"link_desc1": linkTemp[0],
				"link_desc2": linkTemp[1],
				"link_desc3": linkTemp[2],
				"link_url1": linkUrlTemp[0],
				"link_url2": linkUrlTemp[1],
				"link_url3": linkUrlTemp[2],
				"html": props.data.product_detail_html 
			};
			
			console.log('set field data:'+JSON.stringify(data));
			setForm(data);			

			//prepare image list
			props.data.product_images.map((data,key)=>{		
				saveImageList.push(data);				
			});
			console.log('save image list start:'+saveImageList);		
			//prepare order instruction
			console.log('instruction image:'+props.data.order_instruction);
			if(props.data.order_instruction!==undefined && props.data.order_instruction !== '') {
				console.log('found order instruction image');
				setInstructionURL(props.data.order_instruction);
				setShowInstructionUpload(false);
			}
			console.log('show order:'+showInstructionUpload);
			
			setIsPreparing(false);
			console.log('finished prepare value1:');
		}
	
	useEffect(() => {
		console.log('use effect in EditSalePage:'+JSON.stringify(props.data));
				
		generateSaleId();
		generateRemarkIcon();
		
		prepareDefaultValue();
		console.log('finished prepare value2');
		
	}, [])
	
	const generateSaleId = () => {
		/*let time = new Date().getTime();
		let uid = props.user.uid;
		
		var id = uid +'_'+time;
		console.log('sale id:'+id);*/
		var id = props.data.id;
		setSaleId(id);
	}
	
	const generateRemarkIcon = () => {
		for (let i = 0; i < 7; i++) {
			remarkIcon[i] = 'vscode-icons:file-type-go-white';
		} 
		console.log('icon 0:'+remarkIcon[0]);
	}
	
	
	
	const handleSubmit = e => {
		
		e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);
				
		// get our new errors
		const newErrors = findFormErrors()
		// Conditional logic:
		if ( Object.keys(newErrors).length > 0 ) {
		  // We got errors!
		  console.log('set error');
		  setErrors(newErrors);
		  setSubmitting(false);  
		  setShowLoading(false);
		} else {
		  // No errors! Put any logic here for the form submission!
		  setSubmitting(false);  
		  console.log('create sale page success');
		  
		  //todo
		  uploadInstructionImage();
		}
		
	}	
	
	const goToStatusPage = (props) => {
		console.log('Product id:'+props.prodId);
		console.log('props:'+props);
		let link1 = {desc: 'สร้างใหม่', path : '/createsalepage'}
		let link2 = {desc: 'จัดการ sale page', path : '/member'}
		navigate("/status", {state: { statusCode: props.statusCode, prodId: props.prodId, errorMessage: props.errorMessage, link1 , link2, expiredDate: props.expiredDate}});
	}
	const goToStatusPageTest = e => {
		let created_date = serverTimestamp()
		
		//expired date
		const expireInDays = 8; 
		const now = Date.now()
		const expiresAt = new Date(now + 24*60*60*1000*expireInDays)
		console.log('expire:'+expiresAt);
		expiresAt.setHours(23,59,59,999);		
		console.log('expire after:'+expiresAt);
		const canUseDays = 15
		const useUntil = new Date(now + 24*60*60*1000*canUseDays);
		useUntil.setHours(23,59,59,999);
		
		
		let link1 = {desc: 'สร้างใหม่', path : '/createsalepage'}
		let link2 = {desc: 'จัดการ sale page', path : '/member'}
		let expiredDate = format(expiresAt, 'dd/MM/yyyy kk:mm:ss');
		let lastDate = format(useUntil, 'dd/MM/yyyy kk:mm:ss');
		let state = { statusCode: 1, prodId: 'XBZHYXce5XQ45NcDGQCGsfO1UAB2_1680015380736', errorMessage: '', link1 , link2, expiredDate, lastDate}
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/status", {state})
	}
	
	const uploadInstructionImage = async() => {
		console.log('start to upload instruction image');
		const storage = getStorage();
		if(instructionFile!==null && instructionFile!==undefined) {
					console.log('instruction has file');
					var file = instructionFile;
					console.log('file:'+file.size);
					let time = new Date().getTime();
					let ext = file.type.substring(file.type.lastIndexOf('/')+1, file.type.length);
					console.log('file name:'+storage, '/'+saleId+'/'+time+'.'+ext);
					const storageRef = ref(storage, '/salepages/'+saleId+'/instruction_'+time+'.'+ext);
				
					uploadBytes(storageRef, file).then((snapshot) => {
						console.log('Uploaded a blob or file!:'+snapshot);
						getDownloadURL(snapshot.ref).then((downloadURL) => {
							console.log('Instruction File available at', downloadURL);
							//save url
							//setInstructionURL(downloadURL);
							//change to [] 
							instructionImages.push(downloadURL);							
							console.log('instruction url:'+instructionImages);
							uploadImages(storage);
						});
					}, (error) => {
						// Handle unsuccessful uploads
						console.log('error upload:'+error);
						alert('พบปัญหาในการอัพโหลดรูป กรุณาลองใหม่อีกครั้ง');
					});
		} else {
			console.log('instruction empty');
			instructionImages.push(instructionURL);	
			await uploadImages(storage);
		}
	}

	const uploadImages = async(storage) => {
		
		
		//await uploadInstructionImage(storage);
		console.log('start upload product images:'+saveImageList+','+imageList);

		//prepare image list
		saveImageList.map((data,key)=>{		
			imageList.push(data);				
		});
		
		console.log('image list before upload:'+imageList);
	
		if (selectedFiles.length > 0) {
			console.log('files:'+selectedFiles.length);
			selectedFiles.map((file) => {
					//formData.append("file", file);
					console.log('file:'+file.size);
					let time = new Date().getTime();
					let ext = file.type.substring(file.type.lastIndexOf('/')+1, file.type.length);
					console.log('file name:'+storage, '/'+saleId+'/'+time+'.'+ext);
					const storageRef = ref(storage, '/salepages/'+saleId+'/'+time+'.'+ext);
				
					uploadBytes(storageRef, file).then((snapshot) => {
						console.log('Uploaded a blob or file!:'+snapshot);
						getDownloadURL(snapshot.ref).then((downloadURL) => {
							console.log('File available at', downloadURL);
							//save url
							imageList.push(downloadURL);							
							console.log('imageList:'+imageList[0]);
							//saveSalePage();
							updateSalePage();
						});
					}, (error) => {
						// Handle unsuccessful uploads
						console.log('error upload:'+error);
						setShowLoading(false);
						alert('พบปัญหาในการอัพโหลดรูป กรุณาลองใหม่อีกครั้ง');
					});
			});
			
		} else {
			console.log('file is empty');
			if(imageList.lenght<=0) {
				setShowLoading(false);
				alert('กรุณาใส่รูปภาพสินค้า');		
			} else {
				console.log('use existing image');
				await updateSalePage();
			}				
		}
	}
	/*
	const saveSalePage = async() => {
		//const { email, mobile, password, repassword } = form
		const { product_name, product_id, sale_price, ori_price, discount, promotion_desc1,promotion_price1,promotion_desc2,promotion_price2,
		promotion_desc3,promotion_price3,promotion_desc4,promotion_price4,promotion_desc5,promotion_price5,aboutus,
		link_desc1,link_url1,link_desc2,link_url2,link_desc3,link_url3,
		remark1,remark2,remark3,remark4,
		delivery_option1,delivery_option2,delivery_option_own1, delivery_option_own2, delivery_option_own3, delivery_option_own4,
		facebookPixel, googleTag, contact_type, contact_detail
		} = form;
		
		//delivery options
		console.log('delivery options:'+delivery_option1+','+delivery_option2);
		let delivery_options = [];
		if(delivery_option1 === true) {
			delivery_options.push('ส่งฟรี');
		}
		if(delivery_option2 === true) {
			delivery_options.push('มีเก็บเงินปลายทาง');
		}
		if(!( !delivery_option_own1 || delivery_option_own1 === '' )){			
			delivery_options.push(delivery_option_own1);
		}
		if(!( !delivery_option_own2 || delivery_option_own2 === '' )){			
			delivery_options.push(delivery_option_own2);
		}
		if(!( !delivery_option_own3 || delivery_option_own3 === '' )){			
			delivery_options.push(delivery_option_own3);
		}
		if(!( !delivery_option_own4 || delivery_option_own4 === '' )){			
			delivery_options.push(delivery_option_own4);
		}
		
		//detail
		let product_detail_html = html; 		
		
		//remarks
		let remarks = [];
		console.log('remark1:'+remark1+','+remarkIcon[0]);
		console.log('remark icon:'+remarkIcon);
				
		if (!( !remark1 || remark1 === '' )){			
			remarks.push({remark_desc: remark1,remark_icon: remarkIcon[0]});
		}
		if (!( !remark2 || remark2 === '' )){			
			remarks.push({remark_desc: remark2,remark_icon: remarkIcon[1]});
		}
		if (!( !remark3 || remark3 === '' )){			
			remarks.push({remark_desc: remark3,remark_icon: remarkIcon[2]});
		}
		if (!( !remark4 || remark4 === '' )){			
			remarks.push({remark_desc: remark4,remark_icon: remarkIcon[3]});
		}
		console.log('remarks:'+JSON.stringify(remarks));
		
		let product_images = imageList;
		 
		console.log('editor content:'+html);
		
		
		let about_us = {about_detail: aboutus};
		let order_instruction = instructionURL;
		let id = saleId;
		console.log('order:'+instructionURL);
		let facebook_pixel = '';
		let google_tag = '';
		
		if (!( !facebookPixel || facebookPixel === '' )){	
			facebook_pixel = facebookPixel;
		}
		if (!( !googleTag || googleTag === '' )){	
			google_tag = googleTag;
		}
		console.log('pixel:'+facebookPixel+','+facebook_pixel);		
		let created_by = props.user.uid
		let created_date = serverTimestamp()
		let contact_info = '';
		
		//expired date
		const expireInDays = 8; 
		const now = Date.now()
		const expiresAt = new Date(now + 24*60*60*1000*expireInDays)
		expiresAt.setHours(23,59,59,999);	
		console.log('expire:'+expiresAt);
		const canUseDays = 15
		const useUntil = new Date(now + 24*60*60*1000*canUseDays);
		useUntil.setHours(23,59,59,999);		
		let expiredDate = format(expiresAt, 'dd/MM/yyyy kk:mm:ss');
		let lastDate = format(useUntil, 'dd/MM/yyyy kk:mm:ss');		
		
		var store_contact={contact_info:'',contact_type:''};
		if(contact_type ==='line') {
			contact_info = 'https://line.me/R/ti/p/'+contact_detail;
			//store_contact = {contact_info, contact_type}
			store_contact.contact_type='line'
			store_contact.contact_info = contact_info
		} else if(contact_type ==='facebook') {
			contact_info = 'http://m.me/'+contact_detail;
			store_contact.contact_type='facebook'
			store_contact.contact_info = contact_info		
		} else if(contact_type ==='email') {
			contact_info = 'mailto:'+contact_detail;
			store_contact.contact_type='email'
			store_contact.contact_info = contact_info
		} 
		
		console.log('store_contact:'+store_contact);
		//let store_contact = {contact_info, contact_type}
		let status = 'active'
		let total_order = 0
		let total_sale = 0
		
		let saveData = {id, product_name, product_id, sale_price,ori_price,discount,promotions,about_us,links,
						remarks,delivery_options,product_detail_html,product_images,order_instruction,
						facebook_pixel,google_tag,store_contact,created_by,created_date,expiredDate,lastDate,status,total_order,total_sale}
		
		console.log('data:'+JSON.stringify(saveData));
		
		await setDoc(doc(db, "salepages", saleId), saveData);
		
		setShowLoading(false);	
		
		console.log("Document written with ID: ", saleId);
		
		//goToStatusPage({statusCode:1,prodId:saleId,errorMessage:'', expiredDate, lastDate});
		let state = { statusCode: 1, prodId: saleId, errorMessage: '', expiredDate, lastDate}
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/status", {state})
	}*/
	
	const updateSalePage = async() => {
		console.log('start to update sale page');
		
		//const { email, mobile, password, repassword } = form
		const { product_name, product_id, sale_price, ori_price, discount, promotion_desc1,promotion_price1,promotion_discount1,promotion_amount1,promotion_desc2,promotion_price2,promotion_discount2,promotion_amount2,
		promotion_desc3,promotion_price3,promotion_discount3,promotion_amount3,promotion_desc4,promotion_price4,promotion_discount4,promotion_amount4,promotion_desc5,promotion_price5,promotion_discount5,promotion_amount5,aboutus,
		link_desc1,link_url1,link_desc2,link_url2,link_desc3,link_url3,
		remark1,remark2,remark3,remark4,
		delivery_option1,delivery_option2,delivery_option_own1, delivery_option_own2, delivery_option_own3, delivery_option_own4,
		facebookPixel, googleTag, contact_type, contact_detail
		} = form;
		
		//delivery options
		console.log('delivery options:'+delivery_option1+','+delivery_option2);
		let delivery_options = [];
		if(delivery_option1 === true) {
			delivery_options.push('ส่งฟรี');
		}
		if(delivery_option2 === true) {
			delivery_options.push('มีเก็บเงินปลายทาง');
		}
		if(!( !delivery_option_own1 || delivery_option_own1 === '' )){			
			delivery_options.push(delivery_option_own1);
		}
		if(!( !delivery_option_own2 || delivery_option_own2 === '' )){			
			delivery_options.push(delivery_option_own2);
		}
		if(!( !delivery_option_own3 || delivery_option_own3 === '' )){			
			delivery_options.push(delivery_option_own3);
		}
		if(!( !delivery_option_own4 || delivery_option_own4 === '' )){			
			delivery_options.push(delivery_option_own4);
		}
		
		//detail
		let product_detail_html = html; 		
		
		//remarks
		let remarks = [];
		console.log('remark1:'+remark1+','+remarkIcon[0]);
		console.log('remark icon:'+remarkIcon);
				
		if (!( !remark1 || remark1 === '' )){			
			remarks.push({remark_desc: remark1,remark_icon: remarkIcon[0]});
		}
		if (!( !remark2 || remark2 === '' )){			
			remarks.push({remark_desc: remark2,remark_icon: remarkIcon[1]});
		}
		if (!( !remark3 || remark3 === '' )){			
			remarks.push({remark_desc: remark3,remark_icon: remarkIcon[2]});
		}
		if (!( !remark4 || remark4 === '' )){			
			remarks.push({remark_desc: remark4,remark_icon: remarkIcon[3]});
		}
		console.log('remarks:'+JSON.stringify(remarks));
		
		let product_images = imageList;
		console.log('product_images to update:'+product_images);
		 
		console.log('editor content:'+html);		
		
		let about_us = {about_detail: aboutus};
		//console.log('instruction url:'+instructionURL);
		//let order_instruction = instructionURL;
		//fix no value of order instruction
		let order_instruction = instructionImages[0];
		console.log('order instruction:'+order_instruction)
		
		let id = saleId;
		console.log('id:'+id);
		console.log('order:'+instructionURL);
		let facebook_pixel = '';
		let google_tag = '';
		
		if (!( !facebookPixel || facebookPixel === '' )){	
			facebook_pixel = facebookPixel;
		}
		if (!( !googleTag || googleTag === '' )){	
			google_tag = googleTag;
		}
		console.log('pixel:'+facebookPixel+','+facebook_pixel);		
		let contact_info = '';
		
		//update date
		const now = Date.now()
		let updated_date = serverTimestamp()				
		let updated_by = props.user.uid
		
		var store_contact={contact_info:'',contact_type:''};
		contact_info = contact_detail;
		if(contact_type ==='line') {
			//contact_info = 'https://line.me/R/ti/p/'+contact_detail;
			//store_contact = {contact_info, contact_type}
			store_contact.contact_type='line'
			store_contact.contact_info = contact_info
		} else if(contact_type ==='facebook') {
			//contact_info = 'http://m.me/'+contact_detail;
			store_contact.contact_type='facebook'
			store_contact.contact_info = contact_info		
		} else if(contact_type ==='email') {
			//contact_info = 'mailto:'+contact_detail;
			store_contact.contact_type='email'
			store_contact.contact_info = contact_info
		} 
		
		console.log('store_contact:'+store_contact);
		//let store_contact = {contact_info, contact_type}
		let status = 'active'
		let total_order = 0
		let total_sale = 0
		
		let saveData = {product_name, product_id, sale_price,ori_price,discount,promotions,about_us,links,
						remarks,delivery_options,product_detail_html,product_images,order_instruction,
						facebook_pixel,google_tag,store_contact,updated_by,updated_date}
						
		//todo update
		//product image, order_instruction
		
		console.log('updated data:'+JSON.stringify(saveData));
		
		//await setDoc(doc(db, "salepages", saleId), saveData);
		await updateDoc(doc(db, "salepages", saleId), saveData);
		
		setShowLoading(false);	
		
		console.log("Document update with ID: ", saleId);
		
		//goToStatusPage({statusCode:1,prodId:saleId,errorMessage:'', expiredDate, lastDate});
		let state = { statusCode: 2, prodId: saleId, errorMessage: ''}
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/status", {state})
	}
	
	const findFormErrors = () => {
		console.log('find error');
		const newErrors = {}
		
		const { product_name,product_id, sale_price, ori_price, discount,promotion_desc1,promotion_price1,promotion_discount1,promotion_amount1,promotion_desc2,promotion_price2,promotion_discount2,promotion_amount2,
		promotion_desc3,promotion_price3,promotion_discount3,promotion_amount3,promotion_desc4,promotion_price4,promotion_discount4,promotion_amount4,promotion_desc5,promotion_price5,promotion_discount5,promotion_amount5,aboutus,
		link_desc1,link_url1,link_desc2,link_url2,link_desc3,link_url3,contact_type,contact_detail} = form;
		console.log('form:'+JSON.stringify(form));	
		setPromotions([]);
		setLinks([]);
		
		if ( !product_name || product_name === '') {
			newErrors.product_name = 'กรุณาระบุชื่อสินค้า'
		}
		if ( !product_id || product_id === '') {
			newErrors.product_id = 'กรุณาระบุ product id'
		}
		if ( !sale_price || sale_price === '') {
			newErrors.sale_price = 'กรุณาระบุราคาขาย'
		}
		//if ( !ori_price || ori_price === '' || ori_price>sale_price) {
		if ( !ori_price || ori_price === '') {
			newErrors.ori_price = 'กรุณาระบุราคาก่อนลด'
		}		
		if ( !discount || discount === '') {
			newErrors.discount = 'กรุณาระบุส่วนลด'
		}
		//promotion
		if ( !promotion_desc1 || promotion_desc1 === '') {
			newErrors.promotion_desc1 = 'กรุณาระบุชื่อโปรโมชั่น'
		}
		if ( !promotion_price1 || promotion_price1 === '') {
			newErrors.promotion_price1 = 'กรุณาระบุราคาโปรโมชั่น'
		} 
		if (!promotion_discount1 || promotion_discount1 === '') {
			newErrors.promotion_discount1 = 'กรุณาระบุส่วนลดโปรโมชั่น'
		}
		if (!promotion_amount1 || promotion_amount1 === '') {
			newErrors.promotion_amount1 = 'กรุณาระบุจำนวน'
		} else {
			promotions.push({promo_desc: promotion_desc1,promo_price: promotion_price1,promo_amount: promotion_amount1,promo_discount:promotion_discount1});		
		}
		
		if (!( !promotion_desc2 || promotion_desc2 === '' )){
			if (!promotion_price2 || promotion_price2 === '') {
				newErrors.promotion_price2 = 'กรุณาระบุราคาโปรโมชั่น'
			} 
			if (!promotion_discount2 || promotion_discount2 === '') {
				newErrors.promotion_discount2 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			}
			if (!promotion_amount2 || promotion_amount2 === '') {
				newErrors.promotion_amount2 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc2,promo_price: promotion_price2,promo_amount: promotion_amount2,promo_discount:promotion_discount2});
			}
		}
		
		if (!( !promotion_desc3 || promotion_desc3 === '' )){
			if (!promotion_price3 || promotion_price3 === '') {
				newErrors.promotion_price3 = 'กรุณาระบุราคาโปรโมชั่น'
			}
			if (!promotion_discount3 || promotion_discount3 === '') {
				newErrors.promotion_discount3 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			}
			if (!promotion_amount3 || promotion_amount3 === '') {
				newErrors.promotion_amount3 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc3,promo_price: promotion_price3,promo_amount: promotion_amount3,promo_discount:promotion_discount3});
			}
		}
		if (!( !promotion_desc4 || promotion_desc4 === '' )){
			if (!promotion_price4 || promotion_price4 === '') {
				newErrors.promotion_price4 = 'กรุณาระบุราคาโปรโมชั่น'
			}
			if (!promotion_discount4 || promotion_discount4 == '') {
				newErrors.promotion_discount4 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			} 
			if (!promotion_amount4 || promotion_amount4 === '') {
				newErrors.promotion_amount4 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc4,promo_price: promotion_price4,promo_amount: promotion_amount4,promo_discount:promotion_discount4});
			}
		}
		if (!( !promotion_desc5 || promotion_desc5 === '' )){
			if (!promotion_price5 || promotion_price5 === '') {
				newErrors.promotion_price5 = 'กรุณาระบุราคาโปรโมชั่น'
			}
			if (!promotion_discount5 || promotion_discount5 === '') {
				newErrors.promotion_discount5 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			}
			if (!promotion_amount5 || promotion_amount5 === '') {
				newErrors.promotion_amount5 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc5,promo_price: promotion_price5,promo_amount: promotion_amount5,promo_discount:promotion_discount5});
			}
		}
		
		/*if ( !promotion_desc1 || promotion_desc1 === '') {
			newErrors.promotion_desc1 = 'กรุณาระบุชื่อโปรโมชั่น'
		}
		if ( !promotion_price1 || promotion_price1 === '') {
			newErrors.promotion_price1 = 'กรุณาระบุราคาโปรโมชั่น'
		} else if (!promotion_discount1 || promotion_discount1 === '') {
				newErrors.promotion_discount1 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			} else if (!promotion_amount1 || promotion_amount1 === '') {
				newErrors.promotion_amount1 = 'กรุณาระบุจำนวน'
			} else {
				promotions.push({promo_desc: promotion_desc1,promo_price: promotion_price1,promo_amount: promotion_amount1,promo_discount:promotion_discount1});		
			}			
		
		if (!( !promotion_desc2 || promotion_desc2 === '' )){
			if (!promotion_price2 || promotion_price2 === '') {
				newErrors.promotion_price2 = 'กรุณาระบุราคาโปรโมชั่น'
			} else if (!promotion_discount2 || promotion_discount2 === '') {
				newErrors.promotion_discount2 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			} else if (!promotion_amount2 || promotion_amount2 === '') {
				newErrors.promotion_amount2 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc2,promo_price: promotion_price2,promo_amount: promotion_amount2,promo_discount:promotion_discount2});
			}
		}
		
		if (!( !promotion_desc3 || promotion_desc3 === '' )){
			if (!promotion_price3 || promotion_price3 === '') {
				newErrors.promotion_price3 = 'กรุณาระบุราคาโปรโมชั่น'
			} else if (!promotion_discount3 || promotion_discount3 === '') {
				newErrors.promotion_discount3 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			} else if (!promotion_amount3 || promotion_amount3 === '') {
				newErrors.promotion_amount3 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc3,promo_price: promotion_price3,promo_amount: promotion_amount3,promo_discount:promotion_discount3});
			}
		}
		if (!( !promotion_desc4 || promotion_desc4 === '' )){
			if (!promotion_price4 || promotion_price4 === '') {
				newErrors.promotion_price4 = 'กรุณาระบุราคาโปรโมชั่น'
			} else if (!promotion_discount4 || promotion_discount4 == '') {
				newErrors.promotion_discount4 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			} else if (!promotion_amount4 || promotion_amount4 === '') {
				newErrors.promotion_amount4 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc4,promo_price: promotion_price4,promo_amount: promotion_amount4,promo_discount:promotion_discount4});
			}
		}
		if (!( !promotion_desc5 || promotion_desc5 === '' )){
			if (!promotion_price5 || promotion_price5 === '') {
				newErrors.promotion_price5 = 'กรุณาระบุราคาโปรโมชั่น'
			} else if (!promotion_discount5 || promotion_discount5 === '') {
				newErrors.promotion_discount5 = 'กรุณาระบุส่วนลดโปรโมชั่น'
			} else if (!promotion_amount5 || promotion_amount5 === '') {
				newErrors.promotion_amount5 = 'กรุณาระบุจำนวน'
			} else {			
				promotions.push({promo_desc: promotion_desc5,promo_price: promotion_price5,promo_amount: promotion_amount5,promo_discount:promotion_discount5});
			}
		}*/
		
		/*
		if ( !promotion_desc1 || promotion_desc1 === '') {
			newErrors.promotion_desc1 = 'กรุณาระบุชื่อโปรโมชั่น'
		}
		if ( !promotion_price1 || promotion_price1 === '') {
			newErrors.promotion_price1 = 'กรุณาระบุราคาโปรโมชั่น'
		} else {
				promotions.push({promo_desc: promotion_desc1,promo_price: promotion_price1});				
		}
		if (!( !promotion_desc2 || promotion_desc2 === '' )){
			if (!promotion_price2 || promotion_price2 === '') {
				newErrors.promotion_price2 = 'กรุณาระบุราคาโปรโมชั่น'
			} else {			
				promotions.push({promo_desc: promotion_desc2,promo_price: promotion_price2});
			}
		}
		if (!( !promotion_desc3 || promotion_desc3 === '' )){
			if (!promotion_price3 || promotion_price3 === '') {
				newErrors.promotion_price3 = 'กรุณาระบุราคาโปรโมชั่น'
			} else {			
				promotions.push({promo_desc: promotion_desc3,promo_price: promotion_price3});
			}
		}
		if (!( !promotion_desc4 || promotion_desc4 === '' )){
			if (!promotion_price4 || promotion_price4 === '') {
				newErrors.promotion_price4 = 'กรุณาระบุราคาโปรโมชั่น'
			} else {			
				promotions.push({promo_desc: promotion_desc4,promo_price: promotion_price4});
			}
		}
		if (!( !promotion_desc5 || promotion_desc5 === '' )){
			if (!promotion_price5 || promotion_price5 === '') {
				newErrors.promotion_price5 = 'กรุณาระบุราคาโปรโมชั่น'
			} else {			
				promotions.push({promo_desc: promotion_desc5,promo_price: promotion_price5});
			}
		}*/
		
		console.log('promotions:'+JSON.stringify(promotions));
		
		if ( !aboutus || aboutus === '') {
			newErrors.aboutus = 'กรุณาระบุข้อมูลเกี่ยวกับเรา'
		}
		console.log('check link url:'+link_url1+','+link_url2+','+link_url3);
		setField('link_desc1','ข้อกำหนดและเงือนไขการให้บริการ');
		console.log('check link desc:'+link_desc1+','+link_desc2+','+link_desc3);
		if (!( !link_desc1 || link_desc1 === '' )){
			console.log('inside link1');
			if (!link_url1 || link_url1 === '') {
				newErrors.link_url1 = 'กรุณาระบุ URL'
			} else {
				console.log('to push link1');
				if(link_url1 === 'ใช้ที่สร้างใหม่') {
					console.log('push link1 new:'+link_url1);
					links.push({link_desc: link_desc1,link_url: link_url1,link_content: linkContentList[0]});
				} else {
					console.log('push link1:'+link_url1);
					links.push({link_desc: link_desc1,link_url: link_url1});
				}
			}
		}
		console.log('link2:'+link_desc2+','+link_url2);
		if (!( !link_desc2 || link_desc2 === '' )){
			if (!link_url2 || link_url2 === '') {
				newErrors.link_url2 = 'กรุณาระบุ URL'
			} else {
				console.log('link url2:'+link_url2);
				if(link_url2 === 'ใช้ที่สร้างใหม่') {
					links.push({link_desc: link_desc2,link_url: link_url2,link_content: linkContentList[1]});
				} else {
					links.push({link_desc: link_desc2,link_url: link_url2});
				}				
			}
		}
		if (!( !link_desc3 || link_desc3 === '' )){
			if (!link_url3 || link_url3 === '') {
				newErrors.link_url3 = 'กรุณาระบุ URL'
			} else {				
				if(link_url3 === 'ใช้ที่สร้างใหม่') {
					links.push({link_desc: link_desc3,link_url: link_url3,link_content: linkContentList[2]});
				} else {
					links.push({link_desc: link_desc3,link_url: link_url3});
				}
			}
		}
		console.log('links:'+JSON.stringify(links));
		
		if (!( !contact_type || contact_type === '' )){
			if (!contact_detail || contact_detail === '') {
				newErrors.contact_detail = 'กรุณาระบุช่องทางติดต่อ'
			}
		}

		return newErrors
	}
	
	const addMoreDeliveryOptions = () => {
		console.log('add more options');
		const count = deliveryOptionCount;
		if(count<=4) {
			setDeliveryOptionCount(count+1);
			console.log('delivery count:'+count+','+deliveryOptionCount);
		} else {
			console.log('over 4, stop');
		}
		
	}

	const addMorePromotions = () => {
		console.log('handle add promotion');
		const count = promotionCount;
		if(count<=5) {
			setPromotionCount(count+1);
			console.log('pro count:'+count+','+promotionCount);
		} else {
			console.log('over 4, stop');
		}
		
	}
	
	const addMoreRemark	= () => {
		console.log('add more remark');
		const count = remarkCount;
		if(count<=4) {
			setRemarkCount(count+1);
			console.log('remark count:'+count+','+remarkCount);
		} else {
			console.log('over 4, stop');
		}
		
	}
	
	const addMoreLink	= () => {
		console.log('add more link');
		const count = linkCount;
		if(count<=4) {
			setLinkCount(count+1);
			console.log('link count:'+linkCount);
		} else {
			console.log('over 4, stop');
		}
		
	}	
	
	const modules = {
        toolbar: {
            container: [
                [{ 'header': [1,2,3,4,5,6,false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
				[{ 'color': [] }],
				['link'],
                ['clean'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
            ],  
		},
		
    }
	
	const formats = [
	  'header', 'font', 'size', 'color',
	  'bold', 'italic', 'underline', 'strike', 'blockquote',
	  'list', 'bullet', 'indent',
	  'link', 
	  'align',
	];
	
	const deleteHtml = () => {
		if (window.confirm("ต้องการลบข้อมูล?")){
			setDraftHtml('');
			setLinkHtml('');
			linkContentList[linkNo] = undefined;
			setShowCreateLink(false);
		} else {
			console.log('no');	
		}
	}
	
	const showDraftLinkDialog = () => {
		console.log('show draft');
		
		const quill = quillRef.current.getEditor();
		const html = quill.root.innerHTML;		
		console.log('editor content:'+html);
		setDraftHtml(html);
		setLinkHtml(html);
		setShowDraftLink(true);
	
    }
	
	const saveLinkContent = () => {
		const quill = quillRef.current.getEditor();
		const html = quill.root.innerHTML;		
		console.log('editor content:'+html+','+linkNo);
		linkContentList[linkNo] = html;
		setField('link_url'+(linkNo+1),'ใช้ที่สร้างใหม่');
		if(linkNo === 0){
			setField('link_desc'+(linkNo+1),'ข้อกำหนดและเงือนไขการให้บริการ');
		}
		setShowCreateLink(false);
	}
	
	const showCreateLinkDialog = (no) => {
		console.log('create link page:'+no);
		setLinkNo(no-1);
		if(linkContentList[no-1] !== undefined) {
			console.log('show editing content');
			setLinkHtml(linkContentList[no-1]);
		} else {
			console.log('new link');
			setLinkHtml(linkContentList[no-1]);
		}
        setShowCreateLink(true);		
    }
	
	const showIconSelectDialog = (no) =>{
		console.log('select icon no:'+no);
		setIconNo(no-1);
        setShowIconSelect(true);
    }
	
	const handleCallback = (childData) =>{
        this.setState({data: childData})
    }
	
	
	
	const addMoreImage = () => {
		console.log('handle add image');
		const count = imageCount;
		if(count<=6) {
			setImageCount(count+1);
			console.log('image count:'+count+','+imageCount);
		} else {
			console.log('over 6, stop');
		}
		
	}
	
	const handleInstructionFileInput = (e) => {
	  // handle validations
	  const file = e.target.files[0];
	  if (file.size > 3072000) {
		alert('File size cannot exceed more than 3MB:'+file.size);
	  } else {
		setInstructionFile(file);
	  }
	};
	
	const handleFileInput = (e) => {
	  // handle validations
	  const file = e.target.files[0];
	  var files = selectedFiles;
	  if (file.size > 3072000) {
		alert('File size cannot exceed more than 3MB:'+file.size);
	  } else {
		files.push(file);
		setSelectedFiles(files);
	  }
	};
		
	const handleSelectIcon = (icon) => {
		console.log('select icon:'+icon);
		remarkIcon[iconNo] = icon;
		console.log('select icon:'+icon+','+remarkIcon[iconNo]);
		setShowIconSelect(false);
	}
	
	const showDeleteImageConfirm = (data) => {
		
		console.log('save image list before:'+saveImageList);
		var list = saveImageList;
		if (window.confirm("ต้องการลบรูปสินค้า ?")) {
			console.log('to delete image:'+data);
			list = list.filter(v => v !== data); 
			
			console.log('save image list after:'+list);
			setSaveImageList(list);
			console.log('save image list after2:'+saveImageList);
			
			
		}

	}
	const showUploadInstructionImage = () => {
		console.log('show upload instruction');
		setInstructionURL('');
		setShowInstructionUpload(true);
	}
	
	const ProductImages = () => {
		console.log('product iamges:'+props.data.product_images);
		var imageListTemp = [];
		var i = 1;
		//props.data.product_images.map((data,key)=>{		
		saveImageList.map((data,key)=>{	
			imageListTemp.push(
			<div key={i} className="row" style={{marginBottom:8}} >
				<div className="col-9"><img src={data} height="80" style={{border:'1px solid #999999',marginBottom:8}}/></div>
				<div className="col-3"><div onClick={() => showDeleteImageConfirm(data)} style={{color:'blue', cursor: 'pointer'}}>ลบรูป</div></div>
			</div> 
			
			);
			i++;			
		});
		return (
			<div>{imageListTemp}</div>
			
		);
	}
	let pageURL = 'http://localhost:3000/salepage?hhid='+props.data.id
	
	return(
		<div style={{margin:'0.25rem'}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>กำลังบันทึกข้อมูล ...</div>
				</div>
			</div>
			<div style={{ display: showIconSelect ? 'flex' : 'none' }} className='modal'>
				<div style={{width:'auto', margin:'24px', padding:'24px', height:'auto', backgroundColor:'#ffffff'}}>
				<h4>กดเลือกไอคอน</h4>
				<Row className="center" >  
					<Icon icon='icons8:support' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}} onClick={() => handleSelectIcon('icons8:support')}/>
					<Icon icon='carbon:delivery-truck' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}} onClick={() => handleSelectIcon('carbon:delivery-truck')} />
					<Icon icon='mdi:payment-on-delivery-outline' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}} onClick={() => handleSelectIcon('mdi:payment-on-delivery-outline')} />
				</Row>
				<Row className="center" >  
					<Icon icon='material-symbols:contact-support-outline' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}}  onClick={() => handleSelectIcon('material-symbols:contact-support-outline')} />
					<Icon icon='ic:round-support-agent' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}}  onClick={() => handleSelectIcon('ic:round-support-agent')} />
					<Icon icon='material-symbols:home-outline' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}}  onClick={() => handleSelectIcon('material-symbols:home-outline')} />
				</Row>
				<Row className="center" >  
					<Icon icon='material-symbols:phone-android-rounded' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}}  onClick={() => handleSelectIcon('material-symbols:phone-android-rounded')} />
					<Icon icon='ic:outline-access-time' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}}  onClick={() => handleSelectIcon('ic:outline-access-time')} />
					<Icon icon='mdi:currency-thai-baht' className="iconBox col-4" style={{color:'#999999', fontSize:'60px'}}  onClick={() => handleSelectIcon('mdi:currency-thai-baht')} />
				</Row>
				<Row className="center" style={{marginTop:'20px'}} >  
				<Button className="center loginButton" style={{width:'auto'}} onClick={()=>setShowIconSelect(false)}>ปิด</Button>
				</Row>
				</div>
			</div>
			<div style={{ display: showCreateLink ? 'flex' : 'none' }} className='modal'>
				<div style={{width:'auto', margin:'24px', padding:'32px', height:'auto', backgroundColor:'#ffffff'}}>
				<Row style={{marginTop:12,marginBottom:24}}>
					<h4 className="col-8"><b>สร้างหน้าลิงค์ข้อมูล</b></h4>					
					<div className="col-4 right" style={{color:'blue'}} onClick={() => showDraftLinkDialog()}>แสดงตัวอย่าง</div>
				</Row>
				<Row className="center" >  
					<div className="text-editor" >
						<ReactQuill
						  ref={quillRef}
						  value={linkHtml}
						  modules={modules}
						  formats={formats}
						/>
					</div>
				</Row>
				<Row>
					<div className="right" style={{color:'blue',marginTop:12}} onClick={deleteHtml}>ลบ</div>
				</Row>
				<Row className="center" style={{marginTop:'20px'}} >  
					<Button className="center cancelButton" style={{width:'auto',marginRight:16}} onClick={()=>setShowCreateLink(false)}>ยกเลิก</Button>
					<Button className="center loginButton" style={{width:'auto'}} onClick={saveLinkContent}>บันทึก</Button>					
				</Row>
				
				</div>
			</div>
			<div style={{ display: showDraftLink ? 'flex' : 'none' }} className='modal'>
				<div style={{width:'80%', margin:'24px', padding:'32px', height:'auto', backgroundColor:'#ffffff'}}>
				<Row style={{marginTop:12,marginBottom:24}}>
				<h4><b>แบบร่าง</b></h4>
				</Row>
				<Row className="center" >  
					<div style={{width:'100%',height:400,padding:20,border: '1px solid #888'}}>
					<span className="normalText">{renderHTML(draftHtml)}</span>
					</div>					
				</Row>
				<Row className="center" style={{marginTop:'20px'}} >  
				<Button className="center loginButton" style={{width:'auto'}} onClick={()=>setShowDraftLink(false)}>กลับ</Button>
				</Row>
				</div>
			</div>
			{
			!isPreparing?
			<div style={{ margin:'0.25rem' }}>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Form.Group  controlId="form.product_name" style={{marginBottom:12,textAlign:'left'}}>				
					<Form.Label>ชื่อสินค้า *</Form.Label>
					<Form.Control type="text" placeholder="" required 
					defaultValue={props.data.product_name}
					onChange={ e => setField('product_name', e.target.value) }	
					isInvalid={ !!errors.product_name }/>
					<Form.Control.Feedback type="invalid">{ errors.product_name }</Form.Control.Feedback>
				</Form.Group>								
				<Form.Group  controlId="form.product_id" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Label>Product Id*</Form.Label>
					<Form.Control type="number" maxLength="10" placeholder=""  required
					defaultValue={props.data.product_id}					
					onChange={ e => setField('product_id', e.target.value) }
					isInvalid={ !!errors.product_id}/>
					<Form.Control.Feedback type="invalid">{ errors.product_id}</Form.Control.Feedback>
				</Form.Group>	
				<Form.Group  controlId="form.sale_price" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Label>ราคาขาย*</Form.Label>
					<Form.Control type="number" maxLength="10" placeholder=""  required 
					defaultValue={props.data.sale_price}					
					onChange={ e => setField('sale_price', e.target.value) }
					isInvalid={ !!errors.sale_price}/>
					<Form.Control.Feedback type="invalid">{ errors.sale_price}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group  controlId="form.ori_price" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Label>ราคาก่อนลด*</Form.Label>
					<Form.Control type="number" maxLength="10" placeholder=""  required 
					defaultValue={props.data.ori_price}					
					onChange={ e => setField('ori_price', e.target.value) }
					isInvalid={ !!errors.ori_price}/>
					<Form.Control.Feedback type="invalid">{ errors.ori_price}</Form.Control.Feedback>
				</Form.Group>	
				<Form.Group  controlId="form.discount" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Label>ส่วนลด*</Form.Label>
					<Form.Control type="number" maxLength="10" placeholder=""  required 
					defaultValue={props.data.discount}					
					onChange={ e => setField('discount', e.target.value) }
					isInvalid={ !!errors.discount}/>
					<Form.Control.Feedback type="invalid">{ errors.discount}</Form.Control.Feedback>
				</Form.Group>	
					
				<div className="col-12">
				  <div style={{marginBottom:16,marginTop:28}}>Delivery Options</div>
				  <Form.Check
					style={{marginBottom:16}}
					className="col-12"
					inline
					label="ส่งฟรี"
					name="group1"
					type="checkbox"
					defaultChecked={deliveryList[0]===true?true:false}
					id="delivery_option1"	
					onClick={(e) => {
						console.log('delivery1:'+e.target.checked);
						setField('delivery_option1', e.target.checked)
					}}							
				  />
				  <Form.Check
					style={{marginBottom:16}}
					className="col-12"
					inline
					label="มีเก็บเงินปลายทาง"
					name="group1"
					type="checkbox"
					defaultChecked={deliveryList[1]===true?true:false}
					id="delivery_option2"
					onClick={(e) => {
						console.log(e.target.checked);
						setField('delivery_option2', e.target.checked)
					}}
										
				  />
				  <Form.Check
					style={{margin:'0.25rem'}}
					inline
					label="ระบุเอง"
					name="group1"
					type="checkbox"
					id="delivery_option_other"
					defaultChecked={deliveryOwnList.length>0? true:false}/>
					<Icon icon="material-symbols:add-circle-outline-rounded" width='30' onClick={addMoreDeliveryOptions}
					style={{cursor: 'pointer'}}/>
			      <div className="row">
					<Form.Group className="col-12" controlId="form.delivery_option_own1" style={{marginBottom:20,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={deliveryOwnList[0]}
					onChange={ e => setField('delivery_option_own1', e.target.value) }
					isInvalid={ !!errors.delivery_option_own1}/>
					<Form.Control.Feedback type="invalid">{ errors.delivery_option_own1}</Form.Control.Feedback>
					</Form.Group>								
				  </div>
				  <div className="row" style={{ display: (deliveryOptionCount>1) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.delivery_option_own2" style={{marginBottom:20,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={deliveryOwnList[1]}	
					onChange={ e => setField('delivery_option_own2', e.target.value) }
					isInvalid={ !!errors.delivery_option_own2}/>
					<Form.Control.Feedback type="invalid">{ errors.delivery_option_own2}</Form.Control.Feedback>
					</Form.Group>								
				  </div>
				  <div className="row" style={{ display: (deliveryOptionCount>2) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.delivery_option_own3" style={{marginBottom:20,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={deliveryOwnList[2]}	
					onChange={ e => setField('delivery_option_own3', e.target.value) }
					isInvalid={ !!errors.delivery_option_own3}/>
					<Form.Control.Feedback type="invalid">{ errors.delivery_option_own3}</Form.Control.Feedback>
					</Form.Group>								
				  </div>
				  <div className="row" style={{ display: (deliveryOptionCount>3) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.delivery_option_own4" style={{marginBottom:20,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={deliveryOwnList[3]}	
					onChange={ e => setField('delivery_option_own4', e.target.value) }
					isInvalid={ !!errors.delivery_option_own4}/>
					<Form.Control.Feedback type="invalid">{ errors.delivery_option_own4}</Form.Control.Feedback>
					</Form.Group>								
				  </div>
				</div>
				<div style={{marginBottom:20}}>
				  <div className="col">
				  โปรโมชั่น
				  <Icon icon="material-symbols:add-circle-outline-rounded" width='30' style={{marginLeft:10, cursor: 'pointer'}} onClick={addMorePromotions}/></div>
				</div>
				<div className="row" style={{marginBottom:10}}>
					<div className="col-6 cell-smallmargin">ชื่อโปรที่เแสดง</div>
					<div className="col-2 cell-smallmargin">จำนวน</div>
					<div className="col-2 cell-smallmargin">ส่วนลด<span style={{fontSize:10}}> (บาท)</span></div>
					<div className="col-2 cell-smallmargin">ราคา<span style={{fontSize:10}}> (บาท)</span></div>
				</div>
				<div className="row" style={{marginBottom:12}}>
				  <Form.Group className="col-6 cell-smallmargin" controlId="form.promotion_desc1" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[0].promo_desc}	
					onChange={ e => setField('promotion_desc1', e.target.value) }
					isInvalid={ !!errors.promotion_desc1}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_desc1}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_amount1" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[0].promo_amount}
					onChange={ e => setField('promotion_amount1', e.target.value) }
					isInvalid={ !!errors.promotion_amount1}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_amount1}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_discount1" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[0].promo_discount}
					onChange={ e => setField('promotion_discount1', e.target.value) }
					isInvalid={ !!errors.promotion_discount1}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_discount1}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_price1" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[0].promo_price}	
					onChange={ e => setField('promotion_price1', e.target.value) }
					isInvalid={ !!errors.promotion_price1}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_price1}</Form.Control.Feedback>
				  </Form.Group>
				</div>
				<div className="row" style={{ display: (promotionCount>1) ? 'flex' : 'none', marginBottom:12 }}>
				  <Form.Group className="col-6 cell-smallmargin" controlId="form.promotion_desc2" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[1]===undefined? '':props.data.promotions[1].promo_desc}	
					onChange={ e => setField('promotion_desc2', e.target.value) }
					isInvalid={ !!errors.promotion_desc2}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_desc2}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_amount2" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[1]===undefined? '':props.data.promotions[1].promo_amount}
					onChange={ e => setField('promotion_amount2', e.target.value) }
					isInvalid={ !!errors.promotion_amount2}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_amount2}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_discount2" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[1]===undefined? '':props.data.promotions[1].promo_discount}
					onChange={ e => setField('promotion_discount2', e.target.value) }
					isInvalid={ !!errors.promotion_discount2}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_discount2}</Form.Control.Feedback>
				  </Form.Group>		
				   <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_price2" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[1]===undefined? '':props.data.promotions[1].promo_price}	
					onChange={ e => setField('promotion_price2', e.target.value) }
					isInvalid={ !!errors.promotion_price2}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_price2}</Form.Control.Feedback>
				  </Form.Group>
				</div>
				<div className="row" style={{ display: (promotionCount>2) ? 'flex' : 'none', marginBottom:12  }}>
				  <Form.Group className="col-6 cell-smallmargin" controlId="form.promotion_desc3" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[2]===undefined? '':props.data.promotions[2].promo_desc}
					onChange={ e => setField('promotion_desc3', e.target.value) }
					isInvalid={ !!errors.promotion_desc3}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_desc3}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_amount3" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[2]===undefined? '':props.data.promotions[2].promo_amount}
					onChange={ e => setField('promotion_amount3', e.target.value) }
					isInvalid={ !!errors.promotion_amount3}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_amount3}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_discount3" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[2]===undefined? '':props.data.promotions[2].promo_discount}
					onChange={ e => setField('promotion_discount3', e.target.value) }
					isInvalid={ !!errors.promotion_discount3}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_discount3}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_price3" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[2]===undefined? '':props.data.promotions[2].promo_price}
					onChange={ e => setField('promotion_price3', e.target.value) }
					isInvalid={ !!errors.promotion_price3}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_price3}</Form.Control.Feedback>
				  </Form.Group>
				</div>
				<div className="row" style={{ display: (promotionCount>3) ? 'flex' : 'none', marginBottom:12  }}>
				  <Form.Group className="col-6 cell-smallmargin" controlId="form.promotion_desc4" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[3]===undefined? '':props.data.promotions[3].promo_desc}
					onChange={ e => setField('promotion_desc4', e.target.value) }
					isInvalid={ !!errors.promotion_desc4}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_desc4}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_amount4" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[3]===undefined? '':props.data.promotions[3].promo_amount}
					onChange={ e => setField('promotion_amount4', e.target.value) }
					isInvalid={ !!errors.promotion_amount4}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_amount4}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_discount4" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[3]===undefined? '':props.data.promotions[3].promo_discount}
					onChange={ e => setField('promotion_discount4', e.target.value) }
					isInvalid={ !!errors.promotion_discount4}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_discount4}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_price4" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[3]===undefined? '':props.data.promotions[3].promo_price}
					onChange={ e => setField('promotion_price4', e.target.value) }
					isInvalid={ !!errors.promotion_price4}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_price4}</Form.Control.Feedback>
				  </Form.Group>
				</div>
				<div className="row" style={{ display: (promotionCount>4) ? 'flex' : 'none' , marginBottom:12 }}>
				  <Form.Group className="col-6 cell-smallmargin" controlId="form.promotion_desc5" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[4]===undefined? '':props.data.promotions[4].promo_desc}
					onChange={ e => setField('promotion_desc5', e.target.value) }
					isInvalid={ !!errors.promotion_desc5}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_desc5}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_amount5" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[4]===undefined? '':props.data.promotions[4].promo_amount}
					onChange={ e => setField('promotion_amount5', e.target.value) }
					isInvalid={ !!errors.promotion_amount5}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_amount5}</Form.Control.Feedback>
				  </Form.Group>
				  <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_discount5" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="number" placeholder=""  required 
					defaultValue={props.data.promotions[4]===undefined? '':props.data.promotions[4].promo_discount}
					onChange={ e => setField('promotion_discount5', e.target.value) }
					isInvalid={ !!errors.promotion_discount5}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_discount5}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-2 cell-smallmargin" controlId="form.promotion_price5" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.promotions[4]===undefined? '':props.data.promotions[4].promo_price}
					onChange={ e => setField('promotion_price5', e.target.value) }
					isInvalid={ !!errors.promotion_price5}/>
				  <Form.Control.Feedback type="invalid">{ errors.promotion_price5}</Form.Control.Feedback>
				  </Form.Group>
				</div>
				<div style={{marginBottom:20}}>
				  <div className="col">
				  รูปสินค้า
				  <Icon icon="material-symbols:add-circle-outline-rounded" width='30' style={{marginLeft:10, cursor: 'pointer'}} onClick={addMoreImage}/></div>
				</div>
				<ProductImages/>
				
				<div className="row">
					<Form.Group className="col-12" controlId="form.product_image1" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Control type="file" isInvalid={ !!errors.product_image1} onChange={handleFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
					<Form.Control.Feedback type="invalid">{ errors.product_image1}</Form.Control.Feedback>
					</Form.Group>								
				</div>
				<div className="row" style={{ display: (imageCount>1) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.product_image2" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Control type="file" isInvalid={ !!errors.product_image2} onChange={handleFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
					</Form.Group>								
				</div>
				<div className="row" style={{ display: (imageCount>2) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.product_image3" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Control type="file" isInvalid={ !!errors.product_image3} onChange={handleFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
					</Form.Group>								
				</div>		
				<div className="row" style={{ display: (imageCount>3) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.product_image4" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Control type="file" isInvalid={ !!errors.product_image4} onChange={handleFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
					</Form.Group>								
				</div>
				<div className="row" style={{ display: (imageCount>4) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.product_image5" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Control type="file" isInvalid={ !!errors.product_image5} onChange={handleFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
					</Form.Group>								
				</div>
				<div className="row" style={{ display: (imageCount>5) ? 'flex' : 'none' }}>
					<Form.Group className="col-12" controlId="form.product_image6" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Control type="file" isInvalid={ !!errors.product_image6} onChange={handleFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
					</Form.Group>								
				</div>		
				<div style={{marginBottom:'20px', marginTop:'20px'}}>
				  <div className="col">
				  คำโฆษณา/รับประกัน
				  <Icon icon="material-symbols:add-circle-outline-rounded" width='30' style={{marginLeft:10, cursor: 'pointer'}} onClick={addMoreRemark}/></div>
				</div>
				<div className="row" style={{display:'flex',marginBottom:16}}>
					<div  className="col-2" onClick={() => showIconSelectDialog(1)} style={{color:'blue', cursor: 'pointer'}}>เลือก<br/>ไอคอน</div>
					
					<div className="col-1">
						<Icon icon={remarkIcon[0]} width='30' style={{marginLeft:'10px'}} />
					</div>					
					<Form.Group className="col-8" controlId="form.remark1" style={{textAlign:'left', marginLeft:'24px'}}>
						<Form.Control type="text" placeholder=""  required 
						defaultValue={props.data.remarks[0]===undefined? '':props.data.remarks[0].remark_desc}
					onChange={ e => setField('remark1', e.target.value) }
					isInvalid={ !!errors.remark1}/>
						<Form.Control.Feedback type="invalid">{ errors.remark1}</Form.Control.Feedback>
					</Form.Group>								
				</div>
				<div className="row" style={{ marginBottom:16, display: (remarkCount>1) ? 'flex' : 'none' }}>
					<div  className="col-2" onClick={() => showIconSelectDialog(2)} style={{color:'blue', cursor: 'pointer'}}>เลือก<br/>ไอคอน</div>
					
					<div className="col-1">
						<Icon icon={remarkIcon[1]} width='30' style={{marginLeft:'10px'}} />
					</div>					
					<Form.Group className="col-8" controlId="form.remark2" style={{textAlign:'left', marginLeft:'24px'}}>
						<Form.Control type="text" placeholder=""  required 
						defaultValue={props.data.remarks[1]===undefined? '':props.data.remarks[1].remark_desc}
					onChange={ e => setField('remark2', e.target.value) }
					isInvalid={ !!errors.remark2}/>
						<Form.Control.Feedback type="invalid">{ errors.remark2}</Form.Control.Feedback>
					</Form.Group>									
				</div>
				<div className="row" style={{ marginBottom:16, display: (remarkCount>2) ? 'flex' : 'none' }}>
					<div  className="col-2" onClick={() => showIconSelectDialog(3)} style={{color:'blue', cursor: 'pointer'}}>เลือก<br/>ไอคอน</div>
					
					<div className="col-1">
						<Icon icon={remarkIcon[2]} width='30' style={{marginLeft:'10px'}} />
					</div>					
					<Form.Group className="col-8" controlId="form.remark3" style={{textAlign:'left', marginLeft:'24px'}}>
						<Form.Control type="text" placeholder=""  required 
						defaultValue={props.data.remarks[2]===undefined? '':props.data.remarks[2].remark_desc}
					onChange={ e => setField('remark3', e.target.value) }
					isInvalid={ !!errors.remark3}/>
						<Form.Control.Feedback type="invalid">{ errors.remark3}</Form.Control.Feedback>
					</Form.Group>									
				</div>
				<div className="row" style={{ marginBottom:16, display: (remarkCount>3) ? 'flex' : 'none' }}>
					<div  className="col-2" onClick={() => showIconSelectDialog(4)} style={{color:'blue', cursor: 'pointer'}}>เลือก<br/>ไอคอน</div>
					
					<div className="col-1">
						<Icon icon={remarkIcon[3]} width='30' style={{marginLeft:'10px'}} />
					</div>					
					<Form.Group className="col-8" controlId="form.remark4" style={{textAlign:'left', marginLeft:'24px'}}>
						<Form.Control type="text" placeholder=""  required 
						defaultValue={props.data.remarks[3]===undefined? '':props.data.remarks[3].remark_desc}
					onChange={ e => setField('remark4', e.target.value) }
					isInvalid={ !!errors.remark4}/>
						<Form.Control.Feedback type="invalid">{ errors.remark4}</Form.Control.Feedback>
					</Form.Group>									
				</div>
				<Form.Group  controlId="form.aboutus" style={{marginBottom:20,marginTop:20,textAlign:'left'}}>				
					<Form.Label>เกี่ยวกับเรา *</Form.Label>
					<Form.Control as="textarea" rows={3} placeholder="" required 
					defaultValue={props.data.about_us.about_detail}					
					onChange={ e => setField('aboutus', e.target.value) }	
					isInvalid={ !!errors.aboutus }/>
					<Form.Control.Feedback type="invalid">{ errors.aboutus }</Form.Control.Feedback>
				</Form.Group>	
				<div style={{marginBottom:'20px', marginTop:'20px'}} className="row">
				  <div className="col-6">
				  ขั้นตอนการสั่งซื้อ
				  </div>				 
				</div>
			    {!showInstructionUpload ?
				<div className="row" style={{marginBottom:8}}>
					<div className="col-9"><img src={instructionURL} height="80" style={{border:'1px solid #999999',marginBottom:8}}/></div>
					<div className="col-3"><div onClick={() => showUploadInstructionImage(instructionURL)} style={{color:'blue', cursor: 'pointer'}}>ลบรูป</div></div>
				</div> 
				:  
				<Form.Group className="col-12" controlId="form.instruction_image" style={{marginBottom:20,textAlign:'left'}}>
				<Form.Control type="file" isInvalid={ !!errors.instruction_image} onChange={handleInstructionFileInput}
						accept=".png,.jpg,.jpeg,.webp"/>
				<Form.Control.Feedback type="invalid">{ errors.instruction_image}</Form.Control.Feedback>
				</Form.Group>		
				}
				<div style={{marginBottom:'20px', marginTop:'32px'}}>
				  <div className="col">
				  ลิงค์ข้อมูล
				  <Icon icon="material-symbols:add-circle-outline-rounded" width='30' style={{marginLeft:10, cursor: 'pointer'}} onClick={addMoreLink}/></div>
				</div>				
				<div className="row" style={{marginBottom:10}}>
					<div className="col-5">ชื่อลิงค์</div>
					<div className="col-5">URL</div>
					<div className="col-2"></div>
				</div>
				<div className="row" style={{marginBottom:12}}>
				  <Form.Group className="col-5" controlId="form.link_desc1" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					onChange={ e => setField('link_desc1', e.target.value) }
					isInvalid={ !!errors.link_desc1} 
					value="ข้อกำหนดและเงือนไขการให้บริการ"
					disabled />
				  <Form.Control.Feedback type="invalid">{ errors.link_desc1}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-5" controlId="form.link_url1" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					onChange={ e => setField('link_url1', e.target.value) }
					isInvalid={ !!errors.link_url1}
					value={linkContentList[0]!==undefined ? 'ใช้ที่สร้างใหม่' : props.data.links[0]!==undefined? props.data.links[0].link_url:''} disabled={linkContentList[0]!==undefined ? true : false} />
				  <Form.Control.Feedback type="invalid">{ errors.link_url1}</Form.Control.Feedback>
				  </Form.Group>				
				  <div className="col-2 right" onClick={() => showCreateLinkDialog(1)} style={{color:'blue', cursor: 'pointer'}}>
				  {linkContentList[0]!==undefined ? 'แก้ไข' : 'สร้างใหม่'} 
				  </div>				  
				</div>
				<div className="row" style={{ display: (linkCount>1) ? 'flex' : 'none', marginBottom:12 }}>				  
				  <Form.Group className="col-5" controlId="form.link_desc2" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.links[1]!==undefined ? props.data.links[1].link_desc:''}
					onChange={ e => setField('link_desc2', e.target.value) }
					isInvalid={ !!errors.link_desc2}  />
				  <Form.Control.Feedback type="invalid">{ errors.link_desc2}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-5" controlId="form.link_url2" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					onChange={ e => setField('link_url2', e.target.value) }
					isInvalid={ !!errors.link_url2}
					value={linkContentList[1]!==undefined ? 'ใช้ที่สร้างใหม่' : props.data.links[1]!==undefined? props.data.links[1].link_url:''} disabled={linkContentList[1]!==undefined ? true : false}/>
				  <Form.Control.Feedback type="invalid">{ errors.link_url2}</Form.Control.Feedback>
				  </Form.Group>				  
				  <div className="col-2 right" onClick={() => showCreateLinkDialog(2)} style={{color:'blue', cursor: 'pointer'}}>
				  {linkContentList[1]!==undefined ? 'แก้ไข' : 'สร้างใหม่'}</div>	
				</div>
				<div className="row" style={{ display: (linkCount>2) ? 'flex' : 'none', marginBottom:12 }}>
				  <Form.Group className="col-5" controlId="form.link_desc3" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					defaultValue={props.data.links[2]!==undefined ? props.data.links[2].link_desc:''}
					onChange={ e => setField('link_desc3', e.target.value) }
					isInvalid={ !!errors.link_desc3}  />
				  <Form.Control.Feedback type="invalid">{ errors.link_desc3}</Form.Control.Feedback>
				  </Form.Group>
				   <Form.Group className="col-5" controlId="form.link_url3" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Control type="text" placeholder=""  required 
					onChange={ e => setField('link_url3', e.target.value) }
					isInvalid={ !!errors.link_url3}
					value={linkContentList[2]!==undefined ? 'ใช้ที่สร้างใหม่' : props.data.links[2]!==undefined? props.data.links[2].link_url:''} disabled={linkContentList[2]!==undefined ? true : false}/>
				  <Form.Control.Feedback type="invalid">{ errors.link_url3}</Form.Control.Feedback>
				  </Form.Group>				  
				  <div className="col-2 right" onClick={() => showCreateLinkDialog(3)} style={{color:'blue', cursor: 'pointer'}}>
				  {linkContentList[2]!==undefined ? 'แก้ไข' : 'สร้างใหม่'} </div>	
				</div>
				
				<div style={{marginBottom:'20px', marginTop:'32px'}}>				  
				  <Form.Group  controlId="form.facebook_pixel" style={{marginBottom:12,textAlign:'left'}}>				
					<Form.Label>Facebook Pixel</Form.Label>
					<Form.Control type="text" placeholder="" required 
					defaultValue={props.data.facebook_pixel}					
					onChange={ e => setField('facebookPixel', e.target.value) }	
					isInvalid={ !!errors.facebook_pixel}/>
				  <Form.Control.Feedback type="invalid">{ errors.facebook_pixel }</Form.Control.Feedback>
				</Form.Group>	
				</div>
				<div style={{marginBottom:'20px', marginTop:'20px'}}>				  
				  <Form.Group  controlId="form.google_tag" style={{marginBottom:12,textAlign:'left'}}>				
					<Form.Label>Google Tag</Form.Label>
					<Form.Control type="text" placeholder="" required 
					defaultValue={props.data.google_tag}					
					onChange={ e => setField('googleTag', e.target.value) }	
					isInvalid={ !!errors.google_tag }/>
				  <Form.Control.Feedback type="invalid">{ errors.google_tag }</Form.Control.Feedback>
				</Form.Group>	
				</div>
				<div style={{marginBottom:'20px', marginTop:'20px'}} className="row">
				  <div className="col-6">
				  ช่องทางติดต่อร้าน
				  </div>				 
				</div>	
				<div style={{marginBottom:'20px', marginTop:'20px'}}>	
				<Form.Select aria-label="Default select example" onChange={ e => setField('contact_type', e.target.value) }	
				defaultValue={props.data.store_contact.contact_type} >
				  <option value=''>เลือกช่องทางการติดต่อ</option>
				  <option value="line">LINE</option>
				  <option value="email">Email</option>
				  <option value="facebook">Facebook Messenger</option>
				</Form.Select>				
				  <Form.Group  controlId="form.contact_detail" style={{marginBottom:12,marginTop:20,textAlign:'left'}}>				
					<Form.Control type="text" placeholder="ระบุ LineID, Facebook Username หรือ อีเมล์" required 
					defaultValue={props.data.store_contact.contact_info}					
					onChange={ e => setField('contact_detail', e.target.value) }	
					isInvalid={ !!errors.contact_detail }/>
				  <Form.Control.Feedback type="invalid">{ errors.contact_detail }</Form.Control.Feedback>
				</Form.Group>	
				</div>
				
				<div style={{marginTop:32,marginBottom:32,}}>
					<Editor setHtml = {setHtml} user = {props.user} value={props.data.product_detail_html} />
					<div style={{color:'red'}} className='text-danger'>{errorProductDesc}</div>
				</div>
				<div style={{marginBottom:40, marginTop:20}}>
				  ลิงค์ Sale Page
				  <div className="wrapword">
					<a href={pageURL} target='_blank'>{pageURL}</a>
				  </div>
				</div>	
				<Button type='submit' className="loginButton"  disabled={isSubmitting}>บันทึก</Button>				

				</Form>	
				<Space h={40}/>	

								
			</div>
			:
			<div>
			Preparing
			</div>
			}
			<Space h={16}/>
		</div>			
	);
}


export default EditSalePage;
