import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword , onAuthStateChanged  } from "firebase/auth";
import { app } from './firebase'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import { Space, FooterFixed } from './component/PageElement';

function Login() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	
		
	useEffect(() => {
		setIsLoading(false);	
	}, [])

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		return (
			<div>
				<Navbar showTitle={false} />	
				<div style={{backgroundColor:'#ffffff', marginTop:100}} className="alignCenter">
					<div className="roundBox" style={{backgroundColor:'#eeeeee',width:'400px'}}>
						<div className="row">
							<div className="col-12" style={{marginTop:20}}>
								<h3><b>เข้าสู่ระบบ</b></h3>
							</div>
							<div className="col-12" style={{}}>
								<LoginForm/>
							</div>
						</div>
					</div>
				</div>
				<FooterFixed/>	
			</div>	  
		);
	}
	
	
}

function LoginForm() {
	const [showLoading, setShowLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [errorLogin, setErrorLogin] = useState('');	

	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})	
	const navigate = useNavigate();
	
	// Initialize Firebase Authentication and get a reference to the service
	const auth = getAuth(app);
	console.log('auth:'+auth.name+','+auth.currentUser);
	onAuthStateChanged(auth, (user) => {
	  if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		const uid = user.uid;
		console.log('user login:'+ uid);
		navigate("/member", {state: { }});
	  } else {
		console.log('user not login');
	  }
	});
	
	const setField = (field, value) => {
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	  }
	
	const handleSubmit = e => {
		console.log('handle submit');
		const { username, password } = form;
		e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);
		
		// get our new errors
		const newErrors = findFormErrors()
		// Conditional logic:
		if ( Object.keys(newErrors).length > 0 ) {
		  // We got errors!
		  console.log('set error');
		  setErrors(newErrors);
		  setSubmitting(false);  
		  setShowLoading(false);
		} else {
		  // No errors! Put any logic here for the form submission!
		  console.log('to login with email/password:'+username+','+password);
		  signInWithEmailAndPassword(auth, username, password)
		  .then((userCredential) => {
				// Signed in 
				setSubmitting(false);  
				setShowLoading(false);
				const user = userCredential.user;
				console.log('login success:'+user.uid);
				navigate("/member", {state: { }});
		  })
		  .catch((error) => {
				setShowLoading(false);
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log('error login:'+error.message+','+error.code);			
				const firebaseErrors = findFirebaseErrors(errorCode);
				console.log('firebase error:'+findFormErrors.length+','+firebaseErrors);
				if(Object.keys(firebaseErrors).length > 0){
					setErrors(firebaseErrors);					
					setSubmitting(false);  
					setShowLoading(false);
				} else {
					//alert('พบปัญหาในการสมัครสมาชิก กรุณาลองใหม่อีกครั้งค่ะ');
					newErrors.password = 'พบปัญหาในการเข้าสู่ระบบ กรุณาลองใหม่อีกครั้งค่ะ'
					setErrors(newErrors);
					setSubmitting(false);  
					setShowLoading(false);
				}
				
		  });
		  
		  
		}
		
		//navigate("/landing", {state: { isSuccess: true, orderId: '444444444asdfasdf234234', errorMessage: 'sdfasdfasdfasdfdasdfasdfasdf' }});
	}	
	
	const findFirebaseErrors = (code) => {
		console.log('find error:'+code);
		const newErrors = {}
		if ( code === 'auth/wrong-password' ) {
			console.log('error wrong password');
			newErrors.password = 'ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง'
		} else if ( code === 'auth/user-not-found' ) {
			console.log('error user-not-found');
			newErrors.password = 'ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง'
		}

		return newErrors;
	}
	
	/*const signInWithEmailPassword = () => {
		//console.log('login1:'+username+','+password);
		console.log('login1');
		const { username, password} = form		
		console.log('login2:'+username+','+password);
		
		signInWithEmailAndPassword(auth, username, password)
		  .then((userCredential) => {
			// Signed in 
			setShowLoading(false);
			const user = userCredential.user;
			console.log('login success:'+user.uid);
			navigate("/member", {state: { }});
		  })
		  .catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			console.log('error register:'+error.message);			
			setShowLoading(false);
			//alert('พบปัญหาในการสมัครสมาชิก กรุณาลองใหม่อีกครั้งค่ะ');
		  });
	}*/
	
	const findFormErrors = () => {
		console.log('find error');
		const { username, password } = form
		const newErrors = {}
		console.log('username:'+username+','+password);
		if ( !username || username === '') {
			newErrors.username = 'กรุณาระบุชื่อผู้ใช้งาน'
		} else if ( !username || username === '' || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(username)) ) {
			newErrors.username = 'ชื่อผู้ใช้งานไม่ถูกต้อง'
		}
		if ( !password || password === '') {
			newErrors.password = 'กรุณาระบุรหัสผ่าน'
		}	

		return newErrors
	}
	
	return(
		<div style={{margin:20}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>Loading...</div>
				</div>
			</div>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Form.Group  controlId="form.username" style={{marginBottom:12,textAlign:'left'}}>				
					<Form.Label>ชื่อผู้ใช้งาน/อีเมล์ *</Form.Label>
					<Form.Control type="text" placeholder="" required 
					onChange={ e => setField('username', e.target.value) }	
					isInvalid={ !!errors.username }/>
					<Form.Control.Feedback type="invalid">{ errors.username }</Form.Control.Feedback>
				</Form.Group>
				<Form.Group  controlId="form.password" style={{marginBottom:40,textAlign:'left'}}>
					<Form.Label>รหัสผ่าน *</Form.Label>
					<Form.Control type="password" placeholder=""  required 
					onChange={ e => setField('password', e.target.value) }
					isInvalid={ !!errors.password }/>
					<Form.Control.Feedback type="invalid">{ errors.password }</Form.Control.Feedback>
				</Form.Group>
				<div style={{color:'red'}} className='text-danger'>{errorLogin}</div>
				<Form.Control.Feedback type="invalid">{ errors.login }</Form.Control.Feedback>
				<Button type='submit' className="loginButton"  disabled={isSubmitting}>เข้าสู่ระบบ</Button>
			</Form>
			<Space h={40}/>
			<div className="row">
				<div className="col-6 alignLeft">
					<Link to="/register" className="linkMinimal">
					สมัครใช้บริการ
					</Link>
				</div>
				<div className="col-6 alignRight">
					<Link to="/register" className="linkMinimal">
					ลืมรหัสผ่าน
					</Link>
				</div>
			</div>
			<Space h={16}/>
		</div>			
	);
}


export default Login;