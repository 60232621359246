import {React, useState, useEffect, useRef} from "react";
import { useLocation, Link, useNavigate,  } from "react-router-dom";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { collection, query, where, getDocs, getDoc, orderBy, getCountFromServer, limit, startAt, startAfter, endAt, setDoc, doc, serverTimestamp} from "firebase/firestore";
import { db } from './firebase'; 
import { initializeApp } from "firebase/app";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { Icon } from '@iconify/react';
import renderHTML from 'react-render-html';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import SideBar from './component/SideBarMember';
import { Space,FooterFixed, FooterNotFixed } from './component/PageElement';
import axios from 'axios';

function ManagePages() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState({});
	const [pageList, setPageList] = useState([]);
	const [paging, setPaging] = useState({totalRecord:0,totalPage:0,current:0,currentPage:0,status:''});	
	const [firstVisibleList, setFirstVisibleList] = useState([]);
	const [lastVisibleList, setLastVisibleList] = useState([]);
	const [showUpgradePage, setShowUpgradePage] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	
	const [button1, setButton1] = useState('');
	const [button2, setButton2] = useState('');
	const [button3, setButton3] = useState('');
	
	const pageLimit = 2;	
	const auth = getAuth();
	
	const [ searchData, setSearchData ] = useState({searchType:'',searchText:''})	
	const [ searchError, setSearchError ] = useState('')
	
	const [validated, setValidated] = useState(false);
	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})	
	
		
	useEffect(() => {		
		onAuthStateChanged(auth, (user) => {
		  if (user) {
			const uid = user.uid;
			console.log('user login:'+ uid);
			setCurrentUser(user);
			//querySalePages(uid);
			//querySalePagesWithPaging(uid);	
			paging.status = 'active';
			queryTotalSalePages(uid,'active');
		  } else {
			console.log('user not login');
			goToPage('/login');
		  }
		});			
	}, [])
	
	/*
	const querySalePages =  async (uid) => {
		console.log('query sales:'+uid);
		//const q = query(collection(db, "salepages"), where("created_by", "==", uid), where("status", "==", "active"));
		const q = query(collection(db, "salepages"), where("created_by", "==", uid), orderBy("created_date","desc"));
		const querySnapshot = await getDocs(q);
		await querySnapshot.forEach((doc) => {
		  console.log(doc.id, " => ", doc.data());
		  pageList.push(doc.data());
		});
		setIsLoading(false);
		console.log('page list:'+pageList);		
		
	}
	*/
	const queryTotalSalePages =  async (uid,status) => {
		console.log('query sales:'+uid);
		const coll = collection(db, "salepages");
		const q1 = query(coll, where("created_by", "==", uid), where("status", "==", status ));
		//const q1 = query(coll, where("created_by", "==", uid));
		const snapshot = await getCountFromServer(q1);
		console.log('count: ', snapshot.data().count);
		var count = snapshot.data().count;
		var totalPage = Math.ceil(count / pageLimit);
		paging.totalRecord = count;
		paging.totalPage = totalPage;
		paging.currentPage = 0;
		console.log('total page:'+paging.totalPage+','+paging.totalRecord);
		firstVisibleList.length=0;
		lastVisibleList.length=0;
		querySalePagesWithPaging(uid,status);
		//querySalePages(uid);
	}
	
	
	const querySalePagesWithPaging =  async (uid,status) => {	
		var pageListTemp = [];	
		var q;
		var page = paging.currentPage;
		console.log('query page:'+page);
		console.log('firstVisible:'+firstVisibleList.length+',last:'+lastVisibleList.length);
		if(paging.currentPage===0) {
			q = query(collection(db, "salepages"), 
				where("created_by", "==", uid), 
				where("status", "==", status ), 
				orderBy("created_date","desc"),
				limit(pageLimit));
		} else {			
			console.log('last visible:'+lastVisibleList[page-1]);
			q = query(collection(db, "salepages"), 
				where("created_by", "==", uid), 
				where("status", "==", status ), 
				orderBy("created_date","desc"),
				startAfter(lastVisibleList[page-1]),
				limit(pageLimit));
		}
		//setFirstVisibleList([]);
		//setLastVisibleList([]);
		const querySnapshot = await getDocs(q);
		await querySnapshot.forEach((doc) => {
			  pageListTemp.push(doc.data());			  
		});
		paging.currentPage = page+1;	
		firstVisibleList.push(querySnapshot.docs[0]);
		lastVisibleList.push(querySnapshot.docs[querySnapshot.docs.length-1]);
		
		console.log('lastVisibleList:'+lastVisibleList.length+','+firstVisibleList.length);
	
		setPageList(pageListTemp);
		setIsLoading(false);	
		console.log('page list:'+pageList.length+',current page:'+paging.currentPage);
	}
	const querySalePagesWithPagingPrevious =  async (uid,status) => {	
		var pageListTemp = [];	
		var q;
		var page = paging.currentPage;
		console.log('query page:'+page);
		if(paging.currentPage>1) {
			q = query(collection(db, "salepages"), 
				where("created_by", "==", uid), 
				where("status", "==", status ),  
				orderBy("created_date","desc"),
				startAt(firstVisibleList[page-2]),
				limit(pageLimit));
		}
		
		const querySnapshot = await getDocs(q);
		await querySnapshot.forEach((doc) => {
			  pageListTemp.push(doc.data());			  
		});
		paging.currentPage = page-1;	
	
		setPageList(pageListTemp);
		setIsLoading(false);	
		console.log('page list:'+pageList.length+',current page:'+paging.currentPage);
	}
	const gotoNextPage = (lastVisible) => {
		console.log('goto next page:'+paging.status);
		querySalePagesWithPaging(currentUser.uid,paging.status);	
	}
	const gotoPrevPage = (prevLastVisible) => {
		console.log('goto prev page:'+paging.status);
		querySalePagesWithPagingPrevious(currentUser.uid,paging.status);	
	}
			
	const goToPage = (path) => {
		console.log('goto:'+path);
		navigate(path, {state: { }});
	}
	const openOrderList = (data) => {
		console.log('open order list');
		let state = { saleid: data.id, product: data.product_name}
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/orders", {state})
	}
	const openEditPage = (data) => {
		console.log('open edit page');
		let state = { data }
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/editsalepage", {state})
		//todo test
		//navigate("/edittest", {state})
	}
	/*const openUpgradePage = async (data) => {
		setShowLoading(true);
		console.log('add upgrade log first');
		await addUpgradeLog(data);
		
		setShowUpgradePage(true);
	}*/
	const openUpgradePage = async (data) => {
		//setShowLoading(true);
		console.log('add upgrade log first');
		await addUpgradeLog(data);
		
		setShowUpgradePage(true);
	}
	
	const addUpgradeLog = async (data) => {
        let created_date = serverTimestamp();
		let time = new Date().getTime();
		//var refid = time.toString().substring(0,12);
		var timestr = time.toString();
		var refid = timestr.substring(timestr.length - 12);
				
		var uid = currentUser.uid;
		var sid = data.id;
		var merchantId = '18141942';
		var customerEmail = 'qqqq@qqqq.com'
		
		console.log('add upgrade log:'+uid+','+sid+','+refid);
		
		try {
			await setDoc(doc(db, "upgrade_log", refid), {
				salepage: sid,
				upgrade_type: 'one-time',
				status: 'new',
				created_user: uid,
				created_date: created_date,				
			});
			
			setShowLoading(false);
			
			var buttonText1 = "<form key='1' method='post' action='https://www.thaiepay.com/epaylink/payment.aspx'><input type='hidden' name='refno' value='"+refid+"'><input type='hidden' name='merchantid' value='"+merchantId+"'><input type='hidden' name='customeremail' value='"+customerEmail+"'><input type='hidden' name='cc' value='00'><input type='hidden' name='productdetail' value='PremiumSalePage07Days'><input type='hidden' name='total' value='99'><br><button type='submit' name='Submit' class='roundBoxButton col-12'><span>ซื้อ SalePage 7 วัน<br><h3>99 บาท</h3></span></button></form>"
			setButton1(buttonText1);
			console.log('buttonText1:'+button1);
			
			var buttonText2 = "<form key='2' method='post' action='https://www.thaiepay.com/epaylink/payment.aspx'><input type='hidden' name='refno' value='"+refid+"'><input type='hidden' name='merchantid' value='"+merchantId+"'><input type='hidden' name='customeremail' value='"+customerEmail+"'><input type='hidden' name='cc' value='00'><input type='hidden' name='productdetail' value='PremiumSalePage14Days'><input type='hidden' name='total' value='159'><br><button type='submit' name='Submit' class='roundBoxButton col-12'><span>ซื้อ SalePage 14 วัน<br><h3>159 บาท</h3></span></button></form>"
			setButton2(buttonText2);
				
			var buttonText3 = "<form key='3' method='post' action='https://www.thaiepay.com/epaylink/payment.aspx'><input type='hidden' name='refno' value='"+refid+"'><input type='hidden' name='merchantid' value='"+merchantId+"'><input type='hidden' name='customeremail' value='"+customerEmail+"'><input type='hidden' name='cc' value='00'><input type='hidden' name='productdetail' value='PremiumSalePage01Month'><input type='hidden' name='total' value='199'><br><button type='submit' name='Submit' class='roundBoxButton col-12'><span>ซื้อ SalePage 1 เดือน<br><h3>199 บาท</h3></span></button></form>"
			setButton3(buttonText3);
			
		} catch (e) {
            console.error("Error adding upgrade log document: ", e);
			alert('Upgrade error try again later');
			setShowLoading(false);
        }
		
	}
	
	const callRedirectPayment = async (pack,total) => {
		console.log('call redirect to payment:');
		var url = 'https://us-central1-henghengsalepage.cloudfunctions.net/widgets/redirectPayment';
		try {
		  const response = await axios.post(url, {
			
			"refno" : "ref00001",
			"merchantid" : "18141942",
			"productdetail" : "PremiumPackage-"+pack,
			"customeremail" : "test@test.com",
			"total" : total,
			"cc" : "00"

		  });
		  console.log('response POST :'+response.data);
		} catch (error) {
		  console.error(error);
		}
		
	}
	
	const openPaymentLink = async (pack,total) => {
		console.log('open payment link:'+pack+','+total);
		//var url = 'https://pay.sn/bigtreeideas/'+total+'/'+pack;
		//console.log('url:'+url);
		//window.open(url, '_blank', 'noreferrer')
		
		//todo post to API
		/*try {
		  const response = await axios.post('https://www.thaiepay.com/epaylink/payment.aspx', {
			
			"refno" : "ref00001",
			"merchantid" : "123456",
			"productdetail" : "PremiumPackage-"+pack,
			"customeremail" : "test@test.com",
			"total" : total,
			"cc" : "00"

		  });
		  console.log('response POST :'+response.data);
		} catch (error) {
		  console.error(error);
		}*/
		
		var url = 'https://www.thaiepay.com/epaylink/payment.aspx';		
		const data = {
			"refno" : "ref00001",
			"merchantid" : "123456",
			"productdetail" : "PremiumPackage-"+pack,
			"customeremail" : "test@test.com",
			"total" : total,
			"cc" : "00"
		};
		const options = {
		  headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': '*',
			'Access-Control-Allow-Headers':'*',
			'Content-Type': 'application/json',
		  },
		  mode: 'no-cors',
		  withCredentials: true,
		  credentials: 'same-origin',
		};
		axios.post(url, data, options)
		.then((result) => {
			console.log("RESPONSE11 ==== : ", result.data);
			//var data = {
			//	status:200,
			//	data:result.data
			//}	   
			console.log("RESPONSE22 ==== : ", data);
			//return res.status(200).send(JSON.stringify(data));
		})
		.catch((err) => {
			console.log("ERROR11: ====", err);
			//var data = {
			//	status:400,
			//	error:err
			//}	
			console.log("ERROR22: ====", data);
			//return res.status(400).send(JSON.stringify(data));
		})
	}
	
	const handleSubmitPayment = (e) => {
		console.log('handle submit payment');
		
		
		console.log('call redirect to payment:');
		var url = 'https://www.thaiepay.com/epaylink/payment.aspx';
		const options = {
		  headers: {
			  "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*', 
		  },
		};
		try {
		  const response = axios.post(url, {			
			"refno" : "ref00001",
			"merchantid" : "18141942",
			"productdetail" : "PremiumPackage-",
			"customeremail" : "test@test.com",
			"total" : "199",
			"cc" : "00"
		  }, options);
		  console.log('response POST :'+response.data);
		} catch (error) {
		  console.error(error);
		}
		
		e.preventDefault();
	}
	
	const handleSearchBy = (searchBy) => {
		console.log('search by:'+searchBy);
		searchData.searchType=searchBy;
	}
	const handleSearchKey = (searchText) => {
		console.log('search text:'+searchText);
		searchData.searchText=searchText;
	}
	const searchPageClick = async () => {
		console.log('search page agolia:'+searchData.searchText);
				
		var searchKey = searchData.searchText;
		if(searchKey !== '') {			
			paging.totalRecord = 0;
			paging.totalPage = 0;
			//paging.status = '';
			paging.currentPage = 0;		
			var uid = auth.currentUser.uid;
			var pageListTemp = [];	
			
			if(searchData.searchType==='byName') {	
				console.log('search by name');
				//todo use Agolia
				var data = {keyword:''};
				data.keyword = searchKey;
				const response = await fetch('https://us-central1-henghengsalepage.cloudfunctions.net/widgets/searchSalepageName', {
				  method: 'POST',
				  body: JSON.stringify(data),
				  headers: {
					'Content-Type': 'application/json'
				  }
				});
				const result = await response.json();
				var i=0;
				result.map((data,key)=>{
					console.log('search result '+i+':'+data+','+JSON.stringify(data));
					var uid = auth.currentUser.uid;
					if(data.created_by === uid) {
						var showResult = {
							id: data.id,
							product_name: data.product_name,
							sale_price: data.sale_price,
							total_order: data.total_order,
							total_sale: data.total_sale,
							expiredDate: data.expiredDate
						}
						pageListTemp.push(showResult);
					}
					i++;
				});				
				
				setPageList(pageListTemp);
				setIsLoading(false);
						
			} else {
				console.log('search by id:'+searchKey);
				const docRef = doc(db, "salepages", searchKey);
				console.log('search2:'+docRef);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					console.log("Document data:", docSnap.data());
					pageListTemp.push(docSnap.data());
				} else {
					console.log("No such document!");
				}	
				
				setPageList(pageListTemp);
				setIsLoading(false);		
			}	
		} else {
			//todo
			alert('กรุณาระบุคำค้นหา');
		}	
	}	
	const searchPageClickV1 = async () => {
		console.log('search page:'+searchData.searchText);
				
		var searchKey = searchData.searchText;
		if(searchKey !== '') {			
			paging.totalRecord = 0;
			paging.totalPage = 0;
			//paging.status = '';
			paging.currentPage = 0;		
			var uid = auth.currentUser.uid;
			var pageListTemp = [];	
			
			if(searchData.searchType==='byName') {	
				//todo use Agolia
				console.log('search by name');
				var q = query(collection(db, "salepages"), 
				where("created_by", "==", uid), 
				orderBy('product_name'), 
				startAt(searchKey), 
				endAt(searchKey+'\uf8ff'));
				const querySnapshot = await getDocs(q);
				await querySnapshot.forEach((doc) => {
					  pageListTemp.push(doc.data());			  
				});
				setPageList(pageListTemp);
				setIsLoading(false);
						
			} else {
				console.log('search by id:'+searchKey);
				const docRef = doc(db, "salepages", searchKey);
				console.log('search2:'+docRef);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					console.log("Document data:", docSnap.data());
					pageListTemp.push(docSnap.data());
				} else {
					console.log("No such document!");
				}	
				setPageList(pageListTemp);
				setIsLoading(false);		
			}	
		} else {
			//todo
			alert('กรุณาระบุคำค้นหา');
		}	
	}
	const filterByStatus = (status) => {
		
		var uid = auth.currentUser.uid;
		console.log('filter by:'+status+','+uid);
		paging.status = status;
		queryTotalSalePages(uid,status);
		//paging.searchText='';
		//queryTotalOrders(status)
	}
	const hideUpgradeDialog = () => {
		console.log('hide upgrade dialog');
		//paging.searchText='';
		//queryTotalOrders(status)
	}
	
	
	if(isLoading) {
		return (
			<div>
			<Navbar showTitle={false} />	
				<div style={{margin:30}} className="row alignCenter">
					Loading...				
				</div>
				<FooterFixed/>	
			</div>
		);
	} else {
		console.log('page list:'+pageList.length);
		var i = 0;
		var x = 1;
			
		return (
			<div>
			<Navbar showTitle={false} />
				<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
					<div className='modal-content'>
					  <div className='loader'></div>
					  <div className='modal-text'>Loading ...</div>
					</div>
				</div>
				<div style={{ display: showUpgradePage ? 'flex' : 'none' }} className='modal'>
					<div style={{width:'auto', margin:'24px', padding:'24px', height:'auto', backgroundColor:'#ffffff'}}>
						<h4>เลือกแพคเกจที่ต้องการซื้อ</h4>
						 
						<div style={{backgroundColor:'#ffffff'}}>
						<Row className="center" style={{margin:0}}>
							{
								showUpgradePage && button1 !== undefined ? renderHTML(button1) : renderHTML('') 
							}
							{
								showUpgradePage && button2 !== undefined ? renderHTML(button2) : renderHTML('') 
							}
							{
								showUpgradePage && button3 !== undefined ? renderHTML(button3) : renderHTML('') 
							}
						</Row>
						<Row className="center" style={{marginTop:'20px'}} onClick={()=>setShowUpgradePage(false)}>  
						<Button className="center loginButton" style={{width:'auto'}} onClick={()=>hideUpgradeDialog()}>ปิด</Button>
						</Row>
						
						</div>
					</div>					
				</div>
				<div className="row">
					<div className="col-2">
						<SideBar />
					</div>
					<div style={{backgroundColor:'#ffffff', marginTop:100}} className="col-10 ql-align-center">
					<Container fluid="md">
						<Form noValidate validated={validated}>					
						<Row style={{padding:0}}>
							<Col md="auto">
								<Form.Select aria-label="" onChange={ e => handleSearchBy(e.target.value) }	style={{fontSize:12}} >
								  <option value="byNo">ค้นหาจากเลขที่</option>
								  <option value="byName">ค้นหาจากชื่อ</option>
								</Form.Select>	
							</Col>
							<Col md={3}>
								<Form.Group  controlId="form.searchKey" style={{marginBottom:0,textAlign:'left'}}>				
									<Form.Control type="text" placeholder="" required
									placeholder="ระบุคำค้นหา"	style={{fontSize:12}}
									onChange={ e => handleSearchKey(e.target.value) }
									value={paging.searchText}
									isInvalid={ !!errors.searchKey }/>
									<Form.Control.Feedback type="invalid">{ errors.searchKey}</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md="auto">
								<div className="d-grid gap-2">
								<Button onClick={searchPageClick} style={{fontSize:12,marginTop:0,marginBottom:0}}>ค้นหา</Button>
	
								
								</div>
							</Col>
							<Col md="auto">
								<Form.Select aria-label="" onChange={ e => filterByStatus(e.target.value) }	style={{fontSize:12}} >
								  <option value="active">แสดงเซลเพจที่ใช้งานอยู่</option>
								  <option value="expired">แสดงเซลเพจที่หมดอายุ</option>
								</Form.Select>	
							</Col>							
							<Col md="auto" className="ql-align-right">
								<div className="d-grid gap-2">
								</div>
							</Col>
						</Row>
						</Form>
					</Container>
					
					<div className="row" style={{marginTop:16}}>
						<div className="col-6 ql-align-left" style={{paddingLeft:24}}>
						{
						paging.currentPage>1 ?
						<div onClick={() => gotoPrevPage(paging.prevLastVisible)} style={{fontSize:14,color:'blue', cursor: 'pointer'}}>
						{'<<'}Prev
						</div>
						:
						<div>
						</div>
						}	
						</div>
						<div className="col-6 ql-align-right" style={{paddingRight:24}}>
						{
						paging.currentPage<paging.totalPage ?
						<div onClick={() => gotoNextPage(paging.lastVisible)} style={{fontSize:14,color:'blue', cursor: 'pointer'}}>
						Next>>
						</div>
						:
						<div>
						</div>
						}
						</div>
					</div>
					<div style={{marginTop:4,marginLeft:8, marginRight:8,marginBottom:60}} className="row alignCenter">
						<div className="row table-border" id={i} style={{backgroundColor:'#dddddd'}}>
							<div style={{margin:0,padding:0}} className="align-items-center">
								<div className="col-6 cell-border ql-align-left"><b>ชื่อสินค้า</b></div>
								<div className="col-2 cell-border ql-align-center"><b>จำนวน<br/>คำสั่งซื้อ</b></div>
								<div className="col-2 cell-border ql-align-center"><b>ยอดขาย</b><br/>(บาท)</div>
								<div className="col-2 cell-border"></div>
							</div>
						</div>
						{
						pageList.map((data,key)=>{
							i++;
							const str = data.expiredDate;
							const expire = str?.substring(0, 10);
							const url = '/salepage?hhid='+data.id;
							//console.log('id:'+url);
							 return(
							 <div key={i} className="row table-border" style={{padding:4,paddingTop:8,margin:0}}>
								<div style={{margin:0,padding:0}} className="align-items-center">
									<div className="col-6 cell-border ql-align-left align-top" style={{paddingRight:6}}>
									<b>{data.product_name}</b>
									
									</div>
									<div className="col-2 cell-border ql-align-center align-top" onClick={() => openOrderList(data)}>
										<Link>{data.total_order}</Link>
									</div>
									<div className="col-2 cell-border ql-align-center align-top">{data.total_sale}</div>
									<div className="col-2 cell-border ql-align-right" style={{marginLeft:0,marginRight:0,marginBottom:8}}>								
											<Link to={url} target="_blank">
											<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin" style={{marginLeft:6}}/>
											</Link>
									</div>
									<div className="row" style={{paddingRight:12,paddingLeft:12}}>	
										<div className="col-6 cell-border ql-align-left" style={{marginTop:8}}>
											<i style={{paddingRight:6,fontSize:11}}>ใช้ได้ถึง:</i> {expire}									
										</div>																
									</div>
									<div className="row" style={{paddingRight:12,paddingLeft:12}}>	
										<div className="col-6 cell-border ql-align-left" style={{marginTop:8}}>
											<i style={{paddingRight:6,fontSize:11}}>เลขที่เซลเพจ:</i> {data.id}									
										</div>		
										<div className="col-6 cell-border-bottom ql-align-right">
											<Button style={{fontSize:12,marginBottom:6}} onClick={ () => openEditPage(data)}>แก้ไข</Button>
											<Button style={{fontSize:12,marginBottom:6,marginLeft:6,backgroundColor:'green',border:'1px solid green'}} onClick={ () => openUpgradePage(data)}>Upgrade</Button>									
										</div>
									</div>
							  </div>
							 </div>
							 )
						  })  
						}
					</div>
				</div></div>
			</div>
		);
	}
}
export default ManagePages;


/*
<Container>
										<Row>
											<Col style={{padding:0}}>											
												<Row style={{padding:1,marginBottom:8}}>
													<Col className="ql-align-left" sm>
													<b>{data.product_name}</b></Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left" sm >
													<i style={{paddingRight:6,fontSize:11}}>ใช้ได้ถึง:</i>
														{expire}
													</Col>
												</Row>
												
											</Col>
										</Row>											
									</Container>
	<div className="col-6 cell-border ql-align-left" style={{marginTop:8}}>
											<span className="ql-align-left"><b>ใช้ได้ถึง:</b> {expire}</span>										
										</div>
														
<Row className="center" style={{margin:0}}>  							
							<Button type="submit" className="roundBox col-6" style={{color:'#000000',background:'#cccccc', fontSize:'60px',paddingTop:16,paddingBottom:8,marginBottom:6}} >
								<span>
								ซื้อ SalePage 7 วัน<br/>
								<h3>99 บาท</h3>
								</span>
							</Button>
							<Button type="submit" className="roundBox col-6" style={{color:'#000000',background:'#cccccc', fontSize:'60px',paddingTop:16,paddingBottom:8,marginBottom:6}} >
								<span>
								ซื้อ SalePage 14 วัน<br/>
								<h3>159 บาท</h3>
								</span>
							</Button>
							<Button type="submit" className="roundBox col-6" style={{color:'#000000',background:'#cccccc', fontSize:'60px',paddingTop:16,paddingBottom:8,marginBottom:6}} >
								<span>
								ซื้อ SalePage 1 เดือน<br/>
								<h3>199 บาท</h3>
								</span>
							</Button>
						</Row>

						  <div className="row">ใช้ได้ถึง: {expire}</div>
*/



