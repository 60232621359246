import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

import { initializeApp } from "firebase/app";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { app } from './firebase'
import { collection, query, where, getDocs,getDocFromCache, orderBy, limit, doc, startAt, endAt, endBefore, limitToLast, getDoc, getCountFromServer, startAfter } from "firebase/firestore";
import { db } from './firebase'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import SideBar from './component/SideBarMember';
import { Space, FooterFixed } from './component/PageElement';
import { Icon } from '@iconify/react';
import { read, utils, writeFile } from 'xlsx';

function OrderList() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [orderList, setOrderList] = useState([]);
	const [orderId, setOrderId] = useState([]);
	const [orderJson, setOrderJson] = useState([]);
	const auth = getAuth();	
	
	//paging
	const perPage = 2;
	const [paging, setPaging] = useState({totalRecord:0,totalPage:0,currentPage:0,status:''});	
	
	const [ selectedList, setSelectedList ] = useState([])
	const [ searchData, setSearchData ] = useState({searchType:'',searchText:''})	
	const [ searchError, setSearchError ] = useState('')
	
	
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	
	
	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})	
	const setField = (field, value) => {
		
		console.log('set field:'+field+','+value);
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	}
		
	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
		  if (user) {
			const uid = user.uid;
			console.log('user login:'+ uid);
			queryTotalOrders('to-ship');
		  } else {
			console.log('user not login');
			goToPage('/login');
		  }
		});		
	}, [])
	
	const goToPage = (path) => {
		console.log('goto:'+path);
		navigate(path, {state: { }});
	}
	const openOrderDetail = (id) => {
		console.log('open order detail');
		let state = { order_id: id}
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/orderdetails", {state})
	}
	
	const handleSelectChange = (e) => {
		console.log('select change:'+e.target.checked+','+e.target.value);
		//setField('selected_order'+e.target.value, e.target.checked)
		//selectedList.push({'selected_order'+e.target.value, e.target.checked})
	}
	const prepareJsonToExport = () => {
		console.log('prepare json');
		var i=0;
		orderList.map((data,key)=>{
			console.log('i:'+i+','+data.name);
			var json = {
				name: data.name,
				mobile: data.mobile,
				product: data.product_name,
				promotion: data.promotion,
				purchased_price: data.purchase_price,
			}
			orderJson.push(json);
			i++;
		});
		console.log('order json:'+JSON.stringify(orderJson));
		
	}
	
	const exportToExcel = () => {
		prepareJsonToExport();
		const headings = [[
            'name',
            'mobile',
            'product',
			'promotion',
			'purchased_price'
		]];
			
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
		utils.sheet_add_aoa(ws, headings);
		utils.sheet_add_json(ws, orderJson , { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'DeliveryList.xlsx');
		
	}
		
	const handleSearchBy = (searchBy) => {
		console.log('search by:'+searchBy);
		searchData.searchType=searchBy;
	}	
	const handleSearchKey = (searchText) => {
		console.log('search text:'+searchText);
		searchData.searchText=searchText;
	}	
	
	/*const handleSearch = e => {
		console.log('handle search');
		e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);
				
		// get our new errors
		const newErrors = findFormErrors()
		// Conditional logic:
		if ( Object.keys(newErrors).length > 0 ) {
		  // We got errors!
		  console.log('set error');
		  setErrors(newErrors);
		  setSubmitting(false);  
		  setShowLoading(false);
		} else {
		  // No errors! Put any logic here for the form submission!
		  setSubmitting(false);  
		  console.log('create sale page success');
		  
		  //todo
		  searchOrder();
		}		
	}	
	
	const searchOrder = async () => {
		console.log('search order');
		const { searchType, searchKey } = form;		
		console.log('form:'+JSON.stringify(form));
		setOrderList([]);
		const orderTemp=[];
		if(searchType==='byName') {
			console.log('search by name');
			const q = query(collection(db, "purchases"), orderBy('name'), startAt(searchKey), endAt(searchKey+'\uf8ff'));
			const querySnapshot = await getDocs(q);
			await querySnapshot.forEach((doc) => {
			  console.log(doc.id, " => ", doc.data());
			  //orderList.push(doc.data());
			  //orderId.push(doc.id);
			  orderTemp.push(doc.data());
			});
		} else {
			console.log('search by id');
			//const q = query(collection(db, "purchases"), where("sale_id", "==", saleid));
			const docRef = doc(db, "purchases", searchKey);
			const docSnap = await getDoc(docRef);

			if (docSnap.exists()) {
			  console.log("Document data:", docSnap.data());
			  //orderList.push(doc.data());
			  //orderId.push(doc.id);
			  orderTemp.push(doc.data());
			} else {
			  // docSnap.data() will be undefined in this case
			  console.log("No such document!");
			}
		}			
		
		setIsLoading(false);
		setOrderList(orderTemp);
		console.log('order list:'+orderList);
	}
	
	const findFormErrors = () => {
		console.log('find error');
		const newErrors = {}
		
		const { searchType, searchKey } = form;
		
		console.log('form:'+JSON.stringify(form));			
		
		if ( !searchKey || searchKey === '') {
			newErrors.searchKey = 'กรุณาระบุคำค้นหา'
		}		

		return newErrors
	}
	*/
	const filterByStatus = (status) => {
		console.log('filter by:'+status);
		paging.searchText='';
		queryTotalOrders(status)
		//queryByStatus(status);
	}
	
	const queryTotalOrders =  async (status) => {
		var uid = auth.currentUser.uid;
		console.log('query sales:'+uid);
		const coll = collection(db, "purchases");
		const q1 = query(coll, where("uid", "==", uid), where("status", "==", status));
		const snapshot = await getCountFromServer(q1);
		console.log('count: ', snapshot.data().count);
		var count = snapshot.data().count;
		var totalPage = Math.ceil(count / perPage);
		paging.totalRecord = count;
		paging.totalPage = totalPage;
		paging.status = status;
		paging.currentPage = 0;
		console.log('total page:'+paging.totalPage+','+paging.totalRecord);
		queryByStatus(status)
	}
	
	const queryByStatus =  async (status) => {
		console.log('query by status:'+status);
		var count = parseInt(paging.currentPage);
		var uid = auth.currentUser.uid;
		const q = query(collection(db, "purchases"), where("uid", "==", uid), where("status", "==", status), orderBy("purchased_date", "desc"), limit(perPage));
		const querySnapshot = await getDocs(q);
		const first = querySnapshot.docs[0];
		paging.firstVisible = first;
		
		// Get the last visible document
		const last = querySnapshot.docs[querySnapshot.docs.length-1];
		paging.lastVisible = last;
		
		count++;
		paging.currentPage = count;
		
		setOrderList([]);
		setOrderId([]);
		const orderTemp=[];
		const orderIdTemp=[];
		await querySnapshot.forEach((doc) => {
		  console.log(doc.id, " => ", doc.data());
		  orderIdTemp.push(doc.id);
		  orderTemp.push(doc.data());
		});
		setIsLoading(false);
		setOrderId(orderIdTemp);
		setOrderList(orderTemp);
		console.log('order list count:'+orderTemp.length);	
	}
	
	
	const gotoNextPage = async () => {
		console.log('goto next page, current:'+paging.currentPage);
		var count = parseInt(paging.currentPage);
		var uid = auth.currentUser.uid;
		const q = query(collection(db, "purchases"), where("uid", "==", uid), 
							where("status", "==", paging.status), orderBy("purchased_date", "desc"), 
							startAfter(paging.lastVisible),limit(perPage));
							
		const querySnapshot = await getDocs(q);
		const first = querySnapshot.docs[0];
		paging.firstVisible = first;
		
		// Get the last visible document
		const last = querySnapshot.docs[querySnapshot.docs.length-1];
		paging.lastVisible = last;		
		
		count++;
		paging.currentPage = count;
		
		setOrderList([]);
		setOrderId([]);
		const orderTemp=[];
		const orderIdTemp=[];
		await querySnapshot.forEach((doc) => {
		  orderIdTemp.push(doc.id);
		  orderTemp.push(doc.data());
		});
		setIsLoading(false);
		setOrderId(orderIdTemp);
		setOrderList(orderTemp);
		console.log('order list:'+orderList);	
	}
	const gotoPrevPage = async() => {
		console.log('goto prev page:'+JSON.stringify(paging.firstVisible));		
		var count = parseInt(paging.currentPage);
		var uid = auth.currentUser.uid;
		const q = query(collection(db, "purchases"), where("uid", "==", uid), 
							where("status", "==", paging.status), orderBy("purchased_date", "desc"),
							endBefore(paging.firstVisible),limitToLast(perPage));
							
		const querySnapshot = await getDocs(q);
		const first = querySnapshot.docs[0];
		paging.firstVisible = first;
		
		// Get the last visible document
		const last = querySnapshot.docs[querySnapshot.docs.length-1];
		paging.lastVisible = last;		
		
		count--;
		paging.currentPage = count;
		
		setOrderList([]);
		setOrderId([]);
		const orderTemp=[];
		const orderIdTemp=[];
		await querySnapshot.forEach((doc) => {
		  orderIdTemp.push(doc.id);
		  orderTemp.push(doc.data());
		});
		setIsLoading(false);
		setOrderId(orderIdTemp);
		setOrderList(orderTemp);
		console.log('order list:'+orderList);
	}
	
	const handleSearch = e => {
		e.preventDefault();
		searchOrderClick();
	}
	
	const searchOrderClick = async () => {
		console.log('search order:'+searchData.searchText);
				
		var searchKey = searchData.searchText;
		if(searchKey !== '') {
			
			paging.totalRecord = 0;
			paging.totalPage = 0;
			paging.status = '';
			paging.currentPage = 0;		
			var uid = auth.currentUser.uid;
			const orderTemp=[];
			const orderIdTemp=[];
			
			if(searchData.searchType==='byName') {
				//todo use Agolia
				console.log('search by name');
				const q = query(collection(db, "purchases"), where("uid", "==", uid), orderBy('name'), startAt(searchKey), endAt(searchKey+'\uf8ff'));
				const querySnapshot = await getDocs(q);
				await querySnapshot.forEach((doc) => {
				  console.log(doc.id, " => ", doc.data());
				  orderIdTemp.push(doc.id);
				  orderTemp.push(doc.data());
				});
				setIsLoading(false);
				setOrderId(orderIdTemp);
				setOrderList(orderTemp);
				console.log('order list:'+orderList);
			} else {
				console.log('search by id:'+searchKey);
				const docRef = doc(db, "purchases", searchKey);
				console.log('search2:'+docRef);
				const docSnap = await getDoc(docRef);
				if (docSnap.exists()) {
					console.log("Document data:", docSnap.data());
					orderIdTemp.push(searchKey);
					orderTemp.push(docSnap.data());
				} else {
					console.log("No such document!");
				}	

				setIsLoading(false);
				setOrderId(orderIdTemp);
				setOrderList(orderTemp);
				console.log('order list:'+orderList);				
			}	
		} else {
			//todo
			console.log('enter search text');
			alert('กรุณาระบุคำค้นหา');
			//setErrors({searchError:'กรุณาระบุคำค้นหา'});
			/*paging.totalRecord = 0;
			paging.totalPage = 0;
			paging.status = '';
			paging.currentPage = 0;		
			setIsLoading(false);
			setOrderId([]);
			setOrderList([]);
			setErrors({searchError:'กรุณาระบุคำค้นหา'});
			console.log('error:'+errors.searchError);*/
			
			//searchError='กรุณาระบุคำค้นหา';
			//setSearchError('กรุณาระบุคำค้นหา');
			//const newErrors = {}
			//newErrors.searchKey = 'กรุณาระบุคำค้นหา'	
			//queryTotalOrders(paging.status);
			//setErrors(newErrors);
			//setIsLoading(false);
		}			
		
		
	}
	
	/*
	<Container fluid="md">
						<Form noValidate validated={validated} onSubmit={handleSearch}>					
						<Row style={{padding:0}}>
							<Col md="auto">
								<Form.Select aria-label="" onChange={ e => handleSearchBy(e.target.value) }	style={{fontSize:12}} >
								  <option value="byOrderNo">ค้นหาจากเลขที่คำสั่งซื้อ</option>
								  <option value="byName">ค้นหาจากชื่อ</option>
								</Form.Select>	
							</Col>
							<Col md={3}>
								<Form.Group  controlId="form.searchKey" style={{marginBottom:0,textAlign:'left'}}>				
									<Form.Control type="text" placeholder="" required
									placeholder="ระบุคำค้นหา"	style={{fontSize:12}}
									onChange={ e => setField('searchKey', e.target.value) }	
									isInvalid={ !!errors.searchKey }/>
									<Form.Control.Feedback type="invalid">{ errors.searchKey}</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md="auto">
								<div className="d-grid gap-2">
								<Button onClick={searchOrderClick} style={{fontSize:12,marginTop:0,marginBottom:0}}>SearchTest</Button>
								
								
								</div>
							</Col>
							<Col md="auto">
								<Form.Select aria-label="" onChange={ e => filterByStatus(e.target.value) }	style={{fontSize:12}} >
								  <option value="to-ship">แสดงคำสั่งซื้อที่ต้องจัดส่ง</option>
								  <option value="to-paid">รอชำระเงิน</option>
								  <option value="packing">เตรียมจัดส่ง</option>
								  <option value="shipped">จัดส่งขนส่งแล้ว</option>
								  <option value="cancelled">ยกเลิก</option>
								</Form.Select>	
							</Col>							
							<Col md="auto" className="ql-align-right">
								<div className="d-grid gap-2">
								<Button onClick={exportToExcel} style={{fontSize:12,marginTop:0,marginBottom:0}}>Export</Button>
								</div>
							</Col>
						</Row>
						</Form>
					</Container>
					
													<Button type='submit' disabled={isSubmitting} style={{fontSize:12,marginTop:0,marginBottom:0}}>ค้นหา</Button>

	*/
	
	if(isLoading) {
		return (
			<div>
			<Navbar showTitle={false} />	
				<div style={{margin:30}} className="row alignCenter">
					Loading...				
				</div>
				<FooterFixed/>	
			</div>
		);
	} else {
		var i = 0,ii=1000;
		return (
			<div>
			<Navbar showTitle={false} />
				<div className="row">
				<div className="col-2">
					<SideBar />
				</div>
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="col-10 ql-align-center">			
				<div style={{marginTop:60,marginBottom:60,marginLeft:8,marginRight:8}} className="row">
						
					<Container fluid="md">
						<Form noValidate validated={validated} onSubmit={handleSearch}>					
						<Row style={{padding:0}}>
							<Col md="auto">
								<Form.Select aria-label="" onChange={ e => handleSearchBy(e.target.value) }	style={{fontSize:12}} >
								  <option value="byOrderNo">ค้นหาจากเลขที่คำสั่งซื้อ</option>
								  <option value="byName">ค้นหาจากชื่อ</option>
								</Form.Select>	
							</Col>
							<Col md={3}>
								<Form.Group  controlId="form.searchKey" style={{marginBottom:0,textAlign:'left'}}>				
									<Form.Control type="text" placeholder="" required
									placeholder="ระบุคำค้นหา"	style={{fontSize:12}}
									onChange={ e => handleSearchKey(e.target.value) }
									value={paging.searchText}
									isInvalid={ !!errors.searchKey }/>
									<Form.Control.Feedback type="invalid">{ errors.searchKey}</Form.Control.Feedback>
								</Form.Group>
								 <span className="text-danger" style={{fontSize:14}}>{errors.searchError}</span>
							</Col>
							<Col md="auto">
								<div className="d-grid gap-2">
								<Button onClick={searchOrderClick} style={{fontSize:12,marginTop:0,marginBottom:0}}>SearchTest</Button>
								
								
								</div>
							</Col>
							<Col md="auto">
								<Form.Select aria-label="" onChange={ e => filterByStatus(e.target.value) }	style={{fontSize:12}} >
								  <option value="to-ship">ที่ต้องจัดส่ง</option>
								  <option value="to-paid">รอชำระเงิน</option>
								  <option value="packing">เตรียมจัดส่ง</option>
								  <option value="shipped">จัดส่งขนส่งแล้ว</option>
								  <option value="cancelled">ยกเลิก</option>
								  <option value="all">---</option>
								</Form.Select>	
							</Col>							
							<Col md="auto" className="ql-align-right">
								<div className="d-grid gap-2">
								<Button onClick={exportToExcel} style={{fontSize:12,marginTop:0,marginBottom:0}}>Export</Button>
								</div>
							</Col>
						</Row>
						</Form>
					</Container>	
					<Container>
						<Row>
							<Col>
								<div className="row" style={{marginTop:16}}>
									<div className="col-6 ql-align-left" style={{paddingLeft:8}}>
									{
									paging.currentPage>1 ?
									<div onClick={async () => gotoPrevPage()} style={{fontSize:14,color:'blue', cursor: 'pointer'}}>
									{'<<'}Prev
									</div>
									:
									<div>
									</div>
									}	
									</div>
									<div className="col-6  ql-align-right" style={{paddingRight:8}}>
									{
									paging.currentPage<paging.totalPage ?
									<div onClick={async() => gotoNextPage()} style={{fontSize:14,color:'blue', cursor: 'pointer'}}>
									Next>>
									</div>
									:
									<div>
									</div>
									}
									</div>
								</div>
							</Col>
						</Row>
					</Container>					
					<Container style={{marginTop:8}} className="d-none d-md-block">
						<Row style={{padding:8,backgroundColor:'#dddddd'}} className="table-border">
							<Col className="ql-align-left" xs={1} md={1} style={{width:40}}></Col>
							<Col className="ql-align-left" sm>รายละเอียด</Col>
							<Col className="ql-align-left" xs={1} md={1}><b>ราคา</b></Col>
							<Col className="ql-align-left" xs={2} md={2}><b>สถานะ</b></Col>
							<Col className="ql-align-left" xs={1} md={1}></Col>
						</Row>
					</Container>
					<Container style={{marginTop:24}} className="d-block d-sm-none">
						<Row style={{padding:8,backgroundColor:'#dddddd'}} className="table-border">
							<Col className="ql-align-left" xs={1} md={1} style={{width:40}}></Col>
							<Col className="ql-align-left" sm>รายละเอียด</Col>
						</Row>
					</Container>
					{
					orderList.length>0 ?
					orderList.map((data,key)=>{
						const id = orderId[i];
						console.log('order id:'+id);
						i++;
						ii++
						var statusText = '';
						if(data.status === 'to-ship' || data.status === 'new') {
							statusText = 'ที่ต้องจัดส่ง'
						} else if(data.status === 'to-paid'){
							statusText = 'รอชำระเงิน'
						} else if(data.status === 'packing'){
							statusText = 'เตรียมจัดส่ง'
						} else if(data.status === 'shipped'){
							statusText = 'จัดส่งขนส่งแล้ว'
						} else if(data.status === 'cancelled'){
							statusText = 'ยกเลิก'
						}
						return(
						<div key={i}>
							<Container className="table-border d-none d-sm-block">
								<Row style={{padding:0}}>
									<Col className="ql-align-left" xs={1} md={1} style={{width:30}}>
										<input
										  type="checkbox"
										  value={i}
										  onChange={handleSelectChange}
										/>
									</Col>
									<Col className="ql-align-left">
										<Container>
										<Row>
											<Col>											
												<Row style={{padding:1,marginBottom:8}}>
													<Col className="ql-align-left" sm>
													<b>{data.product_name}</b></Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left" sm >
													<i style={{paddingRight:6,fontSize:11}}>ชื่อ:</i>
													{data.name}</Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left">
													<i style={{paddingRight:6,fontSize:11}}>เลขที่คำสั่งซื้อ:</i>
													{id}</Col>
												</Row>
											</Col>
										</Row>											
										</Container>
									</Col>
									<Col className="ql-align-left" xs={1} md={1}>
									&#3647;{data.purchase_price}<br/>{data.payment_option}</Col>
									<Col className="ql-align-left" xs={2} md={2}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span>
									</Col>
									<Col className="ql-align-left" xs={1} md={1}>
									<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin"
									onClick={() => openOrderDetail(id)}/>	
									</Col>

								</Row>
							</Container>
							<Container className="table-border d-block d-sm-none" key={ii}>
								<Row style={{padding:0}}>									
									<Col className="ql-align-left">					
												<Row style={{padding:1,marginBottom:8}}>
													<Col className="ql-align-left" sm>
													<b>{data.product_name}</b></Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left" sm >
													<i style={{paddingRight:6,fontSize:11}}>ชื่อ:</i>
													{data.name}</Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left">
													<i style={{paddingRight:6,fontSize:11}}>เลขที่คำสั่งซื้อ:</i>
													{id}</Col>
												</Row>
									</Col>
									<Col className="ql-align-right" sm style={{marginRight:8,marginTop:8}}>
									&#3647;{data.purchase_price} ({data.payment_option})</Col>
									<Col className="ql-align-right" sm style={{marginTop:4}}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue',marginRight:4}}>{statusText}</span></Col>
									

								</Row>
							</Container>
						</div>
						);
					})
					:
					<div style={{marginTop:24}}><span style={{fontSize:14}}>ไม่มีข้อมูล</span></div>
					}
					
					
					
				</div></div>
				</div>
			</div>
		);
	}
	
}


export default OrderList;


/*

									
									<Col className="ql-align-right d-block d-sm-none" style={{backgroundColor:'green'}}>
									&#3647;{data.purchase_price} - {data.payment_option}</Col>
									<Col className="ql-align-left d-block d-sm-none" style={{backgroundColor:'green'}}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span></Col>
									

									<Col className="ql-align-right d-block d-sm-none" style={{backgroundColor:'green'}}>
									&#3647;{data.purchase_price} - {data.payment_option}</Col>
									<Col className="ql-align-left d-block d-sm-none" style={{backgroundColor:'green'}}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span></Col>
									

<Container style={{marginTop:24}} className="d-none d-lg-block">
						<Row style={{padding:8,backgroundColor:'#dddddd'}} className="table-border">
							<Col className="ql-align-left" xs={1} md={1} style={{width:40}}></Col>
							<Col className="ql-align-left" sm>รายละเอียด</Col>
							<Col className="ql-align-left d-none d-lg-block" xs={1} md={1}><b>ราคา</b></Col>
							<Col className="ql-align-left d-none d-lg-block" xs={2} md={2}><b>สถานะ</b></Col>
							<Col className="ql-align-left d-none d-lg-block" xs={1} md={1}></Col>
						</Row>
					</Container>
					{orderList.map((data,key)=>{
						const id = orderId[i];
						console.log('order id:'+id);
						i++;
						var statusText = '';
						if(data.status === 'to-ship' || data.status === 'new') {
							statusText = 'ที่ต้องจัดส่ง'
						} else if(data.status === 'to-paid'){
							statusText = 'รอชำระเงิน'
						} else if(data.status === 'packing'){
							statusText = 'เตรียมจัดส่ง'
						} else if(data.status === 'shipped'){
							statusText = 'จัดส่งขนส่งแล้ว'
						} else if(data.status === 'cancelled'){
							statusText = 'ยกเลิก'
						}
						return(
							<Container className="table-border d-none d-lg-block" key={i}>
								<Row style={{padding:8}}>
									<Col className="ql-align-left" xs={1} md={1} style={{width:30,backgroundColor:'yellow'}}>
										<input
										  type="checkbox"
										  value={i}
										  onChange={handleSelectChange}
										/>
									</Col>
									<Col className="ql-align-left">
										<Container style={{backgroundColor:'red'}}>
										<Row>
											<Col>											
												<Row style={{padding:1,marginBottom:8}}>
													<Col className="ql-align-left" sm>
													<b>{data.product_name}</b></Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left" sm >
													<i style={{paddingRight:6,fontSize:11}}>ชื่อ:</i>
													{data.name}</Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left">
													<i style={{paddingRight:6,fontSize:11}}>เลขที่คำสั่งซื้อ:</i>
													{id}</Col>
												</Row>
											</Col>
										</Row>
											
										</Container>
									</Col>
									<Col className="ql-align-left d-none d-lg-block" xs={1} md={1} style={{backgroundColor:'blue'}}>
									&#3647;{data.purchase_price}<br/>{data.payment_option}</Col>
									<Col className="ql-align-left d-none d-lg-block" xs={2} md={2}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span>
									</Col>
									<Col className="ql-align-left d-none d-lg-block" xs={1} md={1}>
									<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin"
									onClick={() => openOrderDetail(id)}/>	
									</Col>
									
									<Col className="ql-align-right d-block d-sm-none" style={{backgroundColor:'green'}}>
									&#3647;{data.purchase_price} - {data.payment_option}</Col>
									<Col className="ql-align-left d-block d-sm-none" style={{backgroundColor:'green'}}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span></Col>
									
								</Row>
							</Container>
							<Container className="table-border" key={i}>
								<Row style={{padding:8}}>
									<Col className="ql-align-left" xs={1} md={1} style={{width:30,backgroundColor:'yellow'}}>
										<input
										  type="checkbox"
										  value={i}
										  onChange={handleSelectChange}
										/>
									</Col>
									<Col className="ql-align-left">
										<Container style={{backgroundColor:'red'}}>
										<Row>
											<Col>											
												<Row style={{padding:1,marginBottom:8}}>
													<Col className="ql-align-left" sm>
													<b>{data.product_name}</b></Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left" sm >
													<i style={{paddingRight:6,fontSize:11}}>ชื่อ:</i>
													{data.name}</Col>
												</Row>
												<Row style={{padding:1}}>
													<Col className="ql-align-left">
													<i style={{paddingRight:6,fontSize:11}}>เลขที่คำสั่งซื้อ:</i>
													{id}</Col>
												</Row>
											</Col>
										</Row>
											
										</Container>
									</Col>
									<Col className="ql-align-left d-none d-lg-block" xs={1} md={1} style={{backgroundColor:'blue'}}>
									&#3647;{data.purchase_price}<br/>{data.payment_option}</Col>
									<Col className="ql-align-left d-none d-lg-block" xs={2} md={2}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span>
									</Col>
									<Col className="ql-align-left d-none d-lg-block" xs={1} md={1}>
									<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin"
									onClick={() => openOrderDetail(id)}/>	
									</Col>
									
									<Col className="ql-align-right d-block d-sm-none" style={{backgroundColor:'green'}}>
									&#3647;{data.purchase_price} - {data.payment_option}</Col>
									<Col className="ql-align-left d-block d-sm-none" style={{backgroundColor:'green'}}>
									<span onClick={() => openOrderDetail(id)} style={{color:'blue'}}>{statusText}</span></Col>
									
								</Row>
							</Container>
						);
					})
					}

html: {
    over-flow: "hidden"
}

<Form noValidate validated={validated} onSubmit={handleSearch}>				
					<div className="row" style={{marginBottom:16,marginTop:16,padding:0}}>
						<div className="col-3 ql-align-left">
							<Form.Select aria-label="" onChange={ e => setField('searchType', e.target.value) }	style={{fontSize:12}} >
							  <option value="byOrderNo">ค้นหาจากเลขที่คำสั่งซื้อ</option>
							  <option value="byName">ค้นหาจากชื่อ</option>
							</Form.Select>	
						</div>
						<div className="col-5 ql-align-left" style={{margin:0,padding:0}}>							
								<Form.Group  controlId="form.searchKey" style={{marginBottom:12,textAlign:'left'}}>				
									<Form.Control type="text" placeholder="" required
									placeholder="ระบุคำค้นหา"	style={{fontSize:12}}
									onChange={ e => setField('searchKey', e.target.value) }	
									isInvalid={ !!errors.searchKey }/>
									<Form.Control.Feedback type="invalid">{ errors.searchKey}</Form.Control.Feedback>
								</Form.Group>									
						</div>
						<div className="col-2 ql-align-left" style={{paddingLeft:10,paddingRight:10}}>
							<Button type='submit' disabled={isSubmitting}	style={{fontSize:12}}>ค้นหา</Button>		
						</div>
						<div className="col-2 cell-border ql-align-right"><Button onClick={exportToExcel} style={{fontSize:12}}>Export</Button></div>
					</div>
					</Form>		

											<Col className="ql-align-left" xs={1} md={1} style={{width:0}}>
												<img
												  src={}
												  className='img-thumbnail'
												  alt='รูปสินค้า'
												/>
											</Col>
<div className="row table-border" style={{backgroundColor:'#dddddd'}}>
							<div className="col-auto cell-border ql-align-left" style={{width:40}}></div>
							<div className="col-7 cell-border ql-align-left"><b>ชื่อ นามสกุล</b></div>
							<div className="col-2 cell-border ql-align-center"><b>ราคา</b></div>
							<div className="col-1 cell-border ql-align-right"><b>จัดส่ง</b></div>
							<div className="col-auto cell-border ql-align-right"></div>
					</div>
					{orderList.map((data,key)=>{
						const id = orderId[i];
						console.log('order id:'+id);
						i++;
						return(
							<div key={i} className="row table-border">
								<div className="col-auto cell-border ql-align-left" style={{width:40}}>
									<input
									  type="checkbox"
									  value={i}
									  onChange={handleSelectChange}
									/>
								</div>
								<div className="col-7 cell-border ql-align-left">
									{data.name}
								
								</div>
								<div className="col-2 cell-border ql-align-center">{data.purchase_price}</div>
								<div className="col-1 cell-border ql-align-right">{data.payment_option}</div>
								<div className="col-1 cell-border ql-align-right" style={{backgroundColor:'red'}}>
									<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin"
									onClick={() => openOrderDetail(id)}/>							
									</div>
								</div>
						)
					  })  
					}
*/


/*
					<div className="row table-border" id={i} style={{backgroundColor:'#dddddd'}}>
							<div className="col-auto cell-border ql-align-left" style={{width:40}}></div>
							<div className="col-4 cell-border ql-align-left"><b>ชื่อ นามสกุล</b></div>
							<div className="col-3 cell-border ql-align-left"><b>โปรโมชั่น</b></div>
							<div className="col-2 cell-border ql-align-center"><b>ราคา</b></div>
							<div className="col-1 cell-border ql-align-right"><b>จัดส่ง</b></div>
							<div className="col-1 cell-border ql-align-right"></div>
					</div>
<div key={i} className="row table-border">
							<div className="col-auto cell-border ql-align-left" style={{width:40}}>
								<input
								  type="checkbox"
								  value={i}
								  onChange={handleSelectChange}
								/>
							</div>
							<div className="col-4 cell-border ql-align-left">{data.name}</div>
							<div className="col-3 cell-border ql-align-left">Promotion1</div>
							<div className="col-2 cell-border ql-align-center">{data.purchase_price}</div>
							<div className="col-1 cell-border ql-align-right">{data.payment_option}</div>	
							<div className="col-1 cell-border ql-align-right">
							<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin"
							 onClick={() => openOrderDetail(id)}/>							
							</div>
						 </div>
*/