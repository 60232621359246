import { Icon } from '@iconify/react';


export const HeaderBar = (props) => {
	return(
	<div className="headerBar">
      <div className="priceBar">
        <span className="bigText"><b>{props.txt}</b></span>
      </div>
    </div>
	);
}

export const Space = (props) => {
	return(
		<div style={{height:props.h,backgroundColor:props.color}} >
		</div>
	);
}

export const ImageBar = (props) => {
	return (
		<div>
			<img src={props.src} width="80%" alt={props.alt}/>
        </div>            
    );
}

export const TextBar = (props) => {
	var txtColor = '#000000';
	if(props.color!=null) {
		txtColor = props.color;
	}
	if(props.icon!=null) {
		return(
		<div style={{backgroundColor: "#ffffff", paddingLeft:10,paddingTop:4,paddingBottom:4}}>
			<Icon icon={props.icon} className="iconSmall" style={{color:txtColor}}/>
			<span className={props.txtStyle} style={{color:txtColor}}>{props.txt}</span>
		</div>
		);
	} else {
		return(
		<div style={{backgroundColor: "#ffffff", paddingLeft:10,paddingTop:4,paddingBottom:4}}>
			<span className={props.txtStyle} style={{color:txtColor}}>{props.txt}</span>
		</div>
		);	
	}	
}

export const FooterFixed = () => {
	return(
		<div className="footerBar" style={{backgroundColor: "#eeeeee", padding:10,marginBottom:0}}>
			<span className="smallText" style={{color:'#000000'}}>CopyRight 2023 HengHengSalePage</span>
		</div>
	);
}

export const FooterNotFixed = () => {
	return(
		<div className="ql-align-center" style={{backgroundColor: "#eeeeee", padding:10}}>
			<span className="smallText" style={{color:'#000000'}}>© CopyRight 2023 HengHengSalePage</span>
		</div>
	);
}

export const getBankName = (bank) => {
	let bankMap = new Map();
	bankMap.set("kbank","ธนาคารกสิกร");
	bankMap.set("bbl","ธนาคารกรุงเทพ");
	bankMap.set("scb","ธนาคารไทยพาณิชย์");
	bankMap.set("gsb","ธนาคารออมสิน");
	bankMap.set("ghb","ธนาคารอาคารสงเคราะห์");
	bankMap.set("bay","ธนาคารกรุงศรี");
	bankMap.set("ttb","ธนาคารทหารไทยธนชาต");
	bankMap.set("ktb","ธนาคารกรุงไทย");
	bankMap.set("lhb","ธนาคารแลนด์ แอนด์ เฮ้าส์");
	bankMap.set("ibank","ธนาคารอิสลามแห่งประเทศไทย");
	bankMap.set("kk","ธนาคารเกียรตินาคิน");
	bankMap.set("tisco","ธนาคารทิสโก้");
	bankMap.set("cimb","ธนาคารซีไอเอ็มบีไทย");
	bankMap.set("baac","ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร​ (ธ.ก.ส.)");
	bankMap.set("uob","ธนาคารยูโอบี");
	bankMap.set("tcrb","ธนาคารไทยเครดิต เพื่อรายย่อย");
	bankMap.set("citi","ธนาคารซิตี้แบงก์ประเทศไทย");
	bankMap.set("promptpay","พร้อมเพย์");	
	
	return bankMap.get(bank);
	
}


