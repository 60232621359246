import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword , onAuthStateChanged, signOut } from "firebase/auth";
import { app } from './firebase'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import { Space, FooterFixed } from './component/PageElement';

function Logout() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);	
	const [isLogout, setIsLogout] = useState(false);
	
	const auth = getAuth();
	signOut(auth).then(() => {
	  // Sign-out successful.
	  setIsLogout(true)
	  setIsLoading(false);		
	}).catch((error) => {
	  // An error happened.
	  console.log('logout failed:'+error);
	  setIsLoading(false);		
	});
		
	useEffect(() => {
		//setIsLoading(false);		
	}, [])

	if(isLoading) {
		//return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
		return (
			<div>
				<Navbar showTitle={false} />
				<span style={{marginTop:30}} className="alignCenter">Loading...</span>
			</div>
		)
	} else {
		return (
			<div>
				<Navbar showTitle={false} />	
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
					<div >
						<div className="row">
							{
							isLogout || !auth.currentUser ?
								<div className="col-12">
									<span style={{marginTop:30,marginBottom:40}} className="alignCenter">ออกจากระบบเรียบร้อย</span>
									<Link to="/login">
							<Button type='submit' className="loginButton" style={{paddingLeft:32,paddingRight:32}}>เข้าสู่ระบบใหม่อีกครั้ง</Button>
									</Link>
								</div>
								:
								<div className="col-12">
									Test
								</div>
							}
						</div>
					</div>
				</div>
				<FooterFixed/>	
			</div>	  
		);
	}
	
	
}



export default Logout;