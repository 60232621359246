import { React, useState } from "react";
import { Nav, Navbar, NavLink, Container, Modal, Button} from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom";
import { Icon } from '@iconify/react';
import LineIcon from '../images/line_crop.png';

import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

const Navigationbar = (props) => {
	const navigate = useNavigate();
	const [currentUser, setCurrentUser] = useState({});
	
	const auth = getAuth();
	onAuthStateChanged(auth, (user) => {
	  if (user) {
		setCurrentUser(user);	
	  } else {
		console.log('user not login');
	  }
	});
	
	const [isLogout, setIsLogout] = useState(false);
	const logout = () => {
		console.log('logout');
		signOut(auth).then(() => {
		  // Sign-out successful.
		  navigate("/login", {state: { }});
		}).catch((error) => {
		  // An error happened.
		  console.log('logout failed:'+error);
		  setError(error);
		  showError();
		});
		
	  };
	
	const [showDialog, setShowDialog] = useState(false);
	const closeConfirm = () => setShowDialog(false);
	const showConfirm = () => setShowDialog(true);
	
	const [showErrorDialog, setShowErrorDialog] = useState(false);
	const closeError = () => setShowErrorDialog(false);
	const showError = () => setShowErrorDialog(true);	
	const [error, setError] = useState("");
	  
	return (	
			<div>
			
			<Navbar collapseOnSelect expand="sm" bg="light" variant="light" className="navBarStyle" fixed="top" >
				<Navbar.Brand>
				<img src={LineIcon} width="40" style={{paddingLeft:10,marginRight:10}} alt="logo"/>
				<b>HengHeng</b>
				</Navbar.Brand>	
				<Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" 
				style={{marginRight:16, }} >
				 <Icon icon="ic:baseline-menu" />
				</Navbar.Toggle>
				<Navbar.Collapse id="navbarScroll" style={{marginLeft:16}}>
					{props.handleClickScroll?
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('top')}>หน้าแรก</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('price_bar')}>ราคาแพคเกจ</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('portfolio_bar')}>ตัวอย่าง</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('contact_bar')}>ติดต่อเรา</Nav.Link>
							</Nav.Item>							
							<Nav.Item>
								<Nav.Link href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					:
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link href='/'>หน้าแรก</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
							{
							  !currentUser ? 
								<Nav.Item>
									<Nav.Link href="/login">เข้าสู่ระบบ</Nav.Link>
								</Nav.Item>
							  : 
								<Nav.Item>
									<Nav.Link onClick={() => showConfirm()}>ออกจากระบบ</Nav.Link>
								</Nav.Item>
							  /*!props.auth ? 
								<Nav.Item>
									<Nav.Link href="/login">เข้าสู่ระบบ</Nav.Link>
								</Nav.Item>
							  : 
								<Nav.Item>
									<Nav.Link onClick={() => showConfirm()}>ออกจากระบบ</Nav.Link>
								</Nav.Item>
							*/
							}
						</Nav>
					}
				</Navbar.Collapse>     
				
			</Navbar>
			<Modal show={showDialog} onHide={closeConfirm} className="modal">				
				<Modal.Body>คุณต้องการออกจากระบบ?</Modal.Body>
				<Modal.Footer>
				  <Button variant="secondary" onClick={closeConfirm}>
					No
				  </Button>
				  <Button variant="primary" onClick={logout}>
					Yes
				  </Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showErrorDialog} onHide={closeError} className="modal">				
				<Modal.Body>พบความผิดพลาดในการ logout - {error}</Modal.Body>
				<Modal.Footer>
				  <Button variant="secondary" onClick={closeError}>
					ปิดหน้าต่าง
				  </Button>
				</Modal.Footer>
			</Modal>
			
			</div>
			
		);
}
 
export default Navigationbar;

/*
{props.handleClickScroll?
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('top')}>หน้าแรก</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('price_bar')}>ราคาแพคเกจ</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('portfolio_bar')}>ตัวอย่าง</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('contact_bar')}>ติดต่อเรา</Nav.Link>
							</Nav.Item>
							{!props.auth ? 
								<Nav.Item>
									<Nav.Link>
									เข้าสู่ระบบ
									</Nav.Link>
								</Nav.Item>
							  : 
								<Nav.Item>
									<Nav.Link>ออกจากระบบ</Nav.Link>
								</Nav.Item>
							}
							<Nav.Item>
								<Nav.Link disabled={!props.auth ? true:false} href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					:
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link href='/'>หน้าแรก</Nav.Link>
							</Nav.Item>
							{!props.auth ? 
								<Nav.Item>
									<Nav.Link>
									เข้าสู่ระบบ
									</Nav.Link>
								</Nav.Item>
							  : 
								<Nav.Item>
									<Nav.Link>ออกจากระบบ</Nav.Link>
								</Nav.Item>
							  }
							<Nav.Item>
								<Nav.Link disabled={!props.auth ? true:false} href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					}
*/

/*
<Nav className='ms-auto'>
						{props.handleClickScroll?
						  <Nav.Item>
							<Nav.Link onClick={() => props.handleClickScroll('top')}>หน้าแรก</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							<Nav.Link onClick={() => props.handleClickScroll('price_bar')}>ราคาแพคเกจ</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							 <Nav.Link onClick={() => props.handleClickScroll('portfolio_bar')}>ตัวอย่าง</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							 <Nav.Link onClick={() => props.handleClickScroll('contact_bar')}>ติดต่อเรา</Nav.Link>
						  </Nav.Item>
						:
						  <Nav.Item>
							<Nav.Link href='/'>หน้าแรก</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							<Nav.Link>ออกจากระบบ</Nav.Link>
						  </Nav.Item>
						}
						  {!props.auth ? 
						  <Nav.Item>
							<Nav.Link>
							  เข้าสู่ระบบ
							</Nav.Link>
						  </Nav.Item>
						  : null
						  }
						  <Nav.Item>
							<Nav.Link disabled={!props.auth ? true:false} href="/member">
							  หน้าสมาชิก
							</Nav.Link>
						  </Nav.Item>
						  
					</Nav>
*/
/*
					!props.auth.currentUser ?
					<Nav className='ms-auto'>
						<NavLink  eventKey="1" as={Link} to="/">หน้าแรก</NavLink>
						<NavLink  eventKey="2" as={Link} to="/">ราคาแพคเกจ</NavLink>
						<NavLink  eventKey="3" as={Link} to="/">ตัวอย่าง</NavLink>
						<NavLink  eventKey="4" as={Link} to="/">ติดต่อเรา</NavLink>
						<NavLink  eventKey="5" as={Link} to="/login">เข้าสู่ระบบ</NavLink>
					</Nav>
					:
					<Nav className='ms-auto'>
						<NavLink  eventKey="6" as={Link} to="/">หน้าแรก</NavLink>
						<NavLink  eventKey="7" as={Link} to="/member">หน้าสมาชิก</NavLink>
						<NavLink  eventKey="8" as={Link} to="/logout">ออกจากระบบ</NavLink>
					</Nav>
				*/
/*
<Navbar.Brand href="/SalePage"><Icon icon="material-symbols:arrow-back-rounded" width="24" color="#999999" style={{marginLeft:10}}/></Navbar.Brand>


<Navbar collapseOnSelect expand="sm" bg="light" variant="light">
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav>
                    <NavLink  eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink  eventKey="2" as={Link} to="/about">About</NavLink>
                    <NavLink  eventKey="3" as={Link} to="/contact">Contact</NavLink>
                </Nav>
            </Navbar.Collapse>     
        </Navbar>
*/