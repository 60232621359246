import React from 'react';
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom";


function RedirectPage() {
	const navigate = useNavigate();
	navigate('/home')
	
	//const history = useHistory();
	//history.push('/home')
	
}



export default RedirectPage;