import {React, useState, useEffect, useRef} from "react";
import { useLocation, Link, useNavigate,  } from "react-router-dom";
import { Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/Navbar';
import SideBar from './component/SideBarMember';
import { Space,FooterFixed, FooterNotFixed } from './component/PageElement';

function Landing() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [isSuccess, setSuccess] = useState(false);
	/*const [statusCode, setStatusCode] = useState(0);	
	const [url,setURL] = useState("");
	const [expiredDate,setExpiredDate] = useState("");
	const [lastDate,setLastDate] = useState("");	
	const [errorMessage, setErrorMessage] = useState("");*/
		
	useEffect(() => {
		/*setStatusCode(locationRef.current.state.statusCode);
		setErrorMessage(locationRef.current.state.errorMessage);
		if(locationRef.current.state.statusCode ===1){
			let saleLink = 'http://localhost:3000/salepage?hhid='+locationRef.current.state.prodId;
			setURL(saleLink);
			setExpiredDate(locationRef.current.state.expiredDate);
			setLastDate(locationRef.current.state.lastDate);
			console.log('expire date:'+locationRef.current.state.expiredDate);
			console.log('last date:'+locationRef.current.state.lastDate);
			console.log('prod id:'+locationRef.current.state.prodId);
			console.log('url:'+url);
		}*/
		setIsLoading(false);	
	}, [])
	
		
	const goToPage = (path) => {
		console.log('goto:'+path);
		navigate(path, {state: { }});
	}
	

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		let pageURL = 'http://localhost:3000/salepage?hhid='+locationRef.current.state.prodId
		console.log('page url:'+pageURL);
		console.log('json state:'+JSON.stringify(locationRef.current.state));
		return (
			<div>
			<Navbar showTitle={false} />	
			<div className="row">
				<div className="col-2">
					<SideBar />
				</div>
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="col-10 alignCenter">
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="row alignCenter">
					<div style={{display: locationRef.current.state.statusCode === 1 ? 'inline' : 'none'}}>
						<div  className="barCenter" style={{color:'#289973', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', marginTop:40}}>
							Sale Page สร้างสำเร็จแล้ว
						</div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:24}}>
						ลิงค์ sale page ของคุณคือ :
						</div>
						<div  className="barCenter80 wrapword" style={{color:'#000000', fontWeight: 600, fontSize: 18, fontFamily: 'Sarabun',marginTop:8}}>
						<a href={pageURL}>{pageURL}</a>
						</div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:30}}>
						Sale Page สามารถใช้ได้ถึง : 
						</div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 600, fontSize: 18, fontFamily: 'Sarabun',marginTop:8}}>
						 {locationRef.current.state.expiredDate} </div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:30}}>						
						สามารถดูข้อมูลได้ถึง : 
						</div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 600, fontSize: 18, fontFamily: 'Sarabun',marginTop:8}}>
						{locationRef.current.state.lastDate}
						</div>
						<div style={{height:40}}/>	
						<div className='row center'>
							<Button className='greyButton' style={{margin:12}} onClick={() => goToPage('/createsalepage')} >สร้างใหม่</Button>				
							<Button className='greyButton' style={{margin:12}} onClick={() => goToPage('/managepage')} >จัดการ sale page</Button>
						</div>						

					</div>
					
					<div style={{display: locationRef.current.state.statusCode === 2 ? 'inline' : 'none'}}>
						<div  className="barCenter" style={{color:'#289973', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', marginTop:40}}>
							แก้ไข Sale Page สำเร็จแล้ว
						</div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:24}}>
						ลิงค์ sale page ของคุณคือ :
						</div>
						<div  className="barCenter80 wrapword" style={{color:'#000000', fontWeight: 600, fontSize: 18, fontFamily: 'Sarabun',marginTop:8}}>
						<a href={pageURL}>{pageURL}</a>
						</div>
						<div style={{height:40}}/>	
						<div className='row center'>
							<Button className='greyButton' style={{margin:12}} onClick={() => goToPage('/createsalepage')} >สร้างใหม่</Button>				
							<Button className='greyButton' style={{margin:12}} onClick={() => goToPage('/managepage')} >จัดการ sale page</Button>
						</div>						

					</div>
					
					<div style={{display: locationRef.current.state.statusCode === 404 ? 'inline' : 'none'}} className='row' >
						<div  className="barCenter" style={{color:'#a62126	', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', 
						paddingLeft:20, paddingRight:20, paddingTop:40}}>
						พบปัญหาการทำงาน กรุณาลองใหม่อีกครั้ง
						</div>
						<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:8}}>
						{locationRef.current.state.errorMessage}
						</div>
						<div style={{height:50}}/>
					</div>
					
				</div>
				</div></div>
			</div>
		);
	}

}




function PurchaseStatus(props) {
	
	return (
	<div>
		<div style={{display: props.isSuccess ? 'inline' : 'none'}}>
			<div  className="barCenter" style={{color:'#289973', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', marginTop:40}}>
			การสั่งซื้อสำเร็จเรียบร้อย
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:24}}>
			หมายเลขสั่งซื้อของคุณคือ :
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 600, fontSize: 18, fontFamily: 'Sarabun',marginTop:8}}>
			{props.prodId}
			</div>
			<div style={{height:50}}/>
		</div>
		<div style={{display: props.isSuccess ? 'none' : 'inline'}}>
			<div  className="barCenter" style={{color:'#a62126	', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', 
			paddingLeft:20, paddingRight:20, paddingTop:40}}>
			การสั่งซื้อไม่สำเร็จสำเร็จ กรุณาลองใหม่อีกครั้ง
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:8}}>
			{props.errorMessage}
			</div>
			<div style={{height:50}}/>
		</div>
	</div>
	);
}

export default Landing;