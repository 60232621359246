import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';
import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { app } from './firebase'
import { db } from './firebase'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/Navbar';
import { Space, FooterFixed } from './component/PageElement';

function Register() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
		
	useEffect(() => {
		setIsLoading(false);	
	}, [])

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		return (
			<div>
				<Navbar showTitle={false} />	
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
					<div className="roundBox" style={{backgroundColor:'#dae0f2'}}>
						<div className="row">
							<div className="col-12" style={{marginTop:20}}>
								<h3><b>สมัครใช้บริการ</b></h3>
							</div>
							<div className="col-12" style={{}}>
								<RegisterForm />
							</div>
						</div>
					</div>
				</div>
				<FooterFixed/>	
			</div>	  
		);
	}
	
	
}

function RegisterForm() {
	const [showLoading, setShowLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);

	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})	
	const navigate = useNavigate();
	
	// Initialize Firebase Authentication and get a reference to the service
	const auth = getAuth(app);
	
	
	const setField = (field, value) => {
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	  }
	
	const handleSubmit = e => {
		/*if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
		}else{
			setValidated(true);
			e.preventDefault(); 
	    }
		//e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);*/
		e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);		

		// get our new errors
		const newErrors = findFormErrors()
		// Conditional logic:
		if ( Object.keys(newErrors).length > 0 ) {
		  // We got errors!
		  console.log('set error');
		  setErrors(newErrors);
		  setSubmitting(false);  
		  setShowLoading(false);
		} else {
		  // No errors! Put any logic here for the form submission!
		  console.log('register success');
		  createFirebaseUser();
		}
		
		//navigate("/landing", {state: { isSuccess: true, orderId: '444444444asdfasdf234234', errorMessage: 'sdfasdfasdfasdfdasdfasdfasdf' }});
	}	
	
	const findFormErrors = () => {
		console.log('find error');
		const newErrors = {}
		
		const { email, mobile, password, repassword } = form
		console.log('email:'+email+','+password);
		if ( !email || email === '') {
			newErrors.email = 'กรุณาระบุอีเมล์'
		} else if ( !email || email === '' || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
			newErrors.email = 'กรุณาระบุอีเมล์ให้ถูกต้อง'
		}
		if ( !mobile || mobile === '') {
			newErrors.mobile = 'กรุณาระบุเบอร์โทรศัพท์'
		} else if ( !mobile || mobile.length <10 ) {
			newErrors.mobile = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง'
		}	
		if ( !password || password === '' || password.length<=6) {
			newErrors.password = 'กรุณาระบุรหัสผ่าน ความยาวมากกว่า 6 ตัวอักษร'
		}
		if ( !repassword || repassword === '' || repassword !== password) {
			newErrors.repassword = 'กรุณายืนยันรหัสผ่านให้ตรงกับรหัสผ่าน'
		}		

		return newErrors
	}
	
	const createFirebaseUser = () => {
		const { email, mobile, password, repassword } = form
		
		createUserWithEmailAndPassword(auth, email, password)
		  .then((userCredential) => {
			// Signed in 
			const user = userCredential.user;		
			console.log('user:'+user);			
			//save user data
			saveUserData(email,mobile);		
			
		  })
		  .catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			
			console.log('error register:'+error.message);			
			setShowLoading(false);
			alert('พบปัญหาในการสมัครสมาชิก กรุณาลองใหม่อีกครั้งค่ะ');
		  });
	}
	
	const saveUserData = async (email,mobile) => {
		let created_date = serverTimestamp()
        try {
            const docRef = await addDoc(collection(db, "users"), {
				email: email,
				mobile: mobile,
				user_type: 'trial',
				status: 'active',
				created_date: created_date,
            });
			
			setShowLoading(false);
			navigate("/member", {state: { }});
			
		 } catch (e) {
            console.error("Error adding document: ", e);
			setShowLoading(false);			
			navigate("/member", {state: { }});
			//goToLandingPage({isSuccess:false,orderId:'',errorMessage:e});
			//alert('Purchase error try again later');
         }		
	}
	
	return(
		<div style={{margin:20}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>Loading...</div>
				</div>
			</div>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Form.Group  controlId="form.username" style={{marginBottom:12,textAlign:'left'}}>				
					<Form.Label>อีเมล์ *</Form.Label>
					<Form.Control type="email" placeholder="" required 
					onChange={ e => setField('email', e.target.value) }	
					isInvalid={ !!errors.email }/>
					<Form.Control.Feedback type="invalid">{ errors.email }</Form.Control.Feedback>
				</Form.Group>				
				<Form.Group  controlId="form.mobile" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Label>เบอร์โทรศัพท์ *</Form.Label>
					<Form.Control type="number" maxLength="10" placeholder=""  required 
					onChange={ e => setField('mobile', e.target.value) }
					isInvalid={ !!errors.mobile }/>
					<Form.Control.Feedback type="invalid">{ errors.mobile }</Form.Control.Feedback>
				</Form.Group>	
				<Form.Group  controlId="form.password" style={{marginBottom:12,textAlign:'left'}}>
					<Form.Label>รหัสผ่าน *</Form.Label>
					<Form.Control type="password" placeholder=""  required 
					onChange={ e => setField('password', e.target.value) }
					isInvalid={ !!errors.password }/>
					<Form.Control.Feedback type="invalid">{ errors.password }</Form.Control.Feedback>
				</Form.Group>
				<Form.Group  controlId="form.repassword" style={{marginBottom:40,textAlign:'left'}}>
					<Form.Label>ยืนยันรหัสผ่าน *</Form.Label>
					<Form.Control type="password" placeholder=""  required 
					onChange={ e => setField('repassword', e.target.value) }
					isInvalid={ !!errors.repassword }/>
					<Form.Control.Feedback type="invalid">{ errors.repassword }</Form.Control.Feedback>
				</Form.Group>
				<Button type='submit' className="loginButton"  disabled={isSubmitting}>สมัครใช้บริการ</Button>
			</Form>	
			<Space h={40}/>
			<div className="row">
				<div className="col-12 alignCenter">
					มีบัญชีอยู่แล้ว? 
					<Link to="/login" className="linkMinimal" style={{paddingLeft:6}}>
					เข้าสู่ระบบ
					</Link>
				</div>
			</div>
			<Space h={16}/>
		</div>			
	);
}


export default Register;