import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav, Navbar, NavLink, Container} from "react-bootstrap";

import { Icon } from '@iconify/react';

const SideBar = () => {
	const navigate = useNavigate();
	const gotoPage = (page) => {
		console.log('goto page:'+page);
		if(page==='create'){
			navigate("/createsalepage", {});
		} else if(page==='manage') {
			navigate("/managepage", {});
		} else if(page==='order') {
			navigate("/orderlist", {});
		} else if(page==='manage') {
			navigate("/createsalepage", {});
		}
	}
	
	return (
    <div>
		<div style={{backgroundColor:"#f8f9fa",marginTop:0,paddingTop:100, height: '120vh', overflow: 'scroll initial',fontcolor:'black' }}>
			<Nav className='ms-auto'>
				<div className="col-12">
				<Nav.Item>
					<div className="row"  onClick={() => gotoPage('create')}>
						<div className="col-2"><Icon icon="ic:baseline-fiber-new" className="iconNav" style={{color:'#999999'}} /></div>						
						<div className="col-10 d-none d-md-table-cell"><Nav.Link className="sidemenulink">สร้างเซลเพจ</Nav.Link></div>
					</div>
				</Nav.Item>
				</div><div className="col-12">
				<Nav.Item>
					<div className="row"  onClick={() => gotoPage('manage')}>
						<div className="col-2"><Icon icon="material-symbols:edit-square-outline-rounded" className="iconNav" style={{color:'#999999'}}/>	</div>						
						<div className="col-10 d-none d-md-table-cell"><Nav.Link className="sidemenulink hidden-xs hidden-sm">จัดการเซลเพจ</Nav.Link></div>
					</div>					
				</Nav.Item>
				</div><div className="col-12">
				<Nav.Item>
					<div className="row"  onClick={() => gotoPage('order')}>
						<div className="col-2"><Icon icon="icon-park-outline:transaction-order" className="iconNav" style={{color:'#999999'}}/>	</div>						
						<div className="col-10 d-none d-md-table-cell"><Nav.Link className="sidemenulink hidden-xs hidden-sm">คำสั่งซื้อ</Nav.Link></div>
					</div>						
				</Nav.Item>
				</div><div className="col-12">
				<Nav.Item>
					<div className="row"  onClick={() => gotoPage('report')}>
						<div className="col-2"><Icon icon="mdi:report-box-outline" className="iconNav" style={{color:'#999999'}}/>	</div>						
						<div className="col-10 d-none d-md-table-cell"><Nav.Link className="sidemenulink hidden-xs hidden-sm">รายงาน</Nav.Link></div>
					</div>									
				</Nav.Item>				
				</div><div className="col-12">
				<Nav.Item>
					<div className="row"  onClick={() => gotoPage('upgrade')}>
						<div className="col-2"><Icon icon="icon-park-outline:buy" className="iconNav" style={{color:'#999999'}}/>	</div>						
						<div className="col-10 d-none d-md-table-cell"><Nav.Link className="sidemenulink hidden-xs hidden-sm">อัพเกรดเซลเพจ</Nav.Link></div>
					</div>					
				</Nav.Item>
				</div>
			</Nav>
		</div>
    </div>
	);
};
export default SideBar;


/*

<Nav.Link href='/createsalepage' className="sidemenulink">สร้างเซลเพจ</Nav.Link>
<div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
      <CDBSidebar textColor="#898a8a" backgroundColor="#f8f9fa">
        <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
          <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
            Sidebar
          </a>
        </CDBSidebarHeader>

        <CDBSidebarContent className="sidebar-content">
          <CDBSidebarMenu>
            <NavLink exact to="/" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/tables" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="table">Tables</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/profile" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="user">Profile page</CDBSidebarMenuItem>
            </NavLink>
            <NavLink exact to="/analytics" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="chart-line">Analytics</CDBSidebarMenuItem>
            </NavLink>


            <NavLink exact to="/hero404" target="_blank" activeClassName="activeClicked">
              <CDBSidebarMenuItem icon="exclamation-circle">404 page</CDBSidebarMenuItem>
            </NavLink>
          </CDBSidebarMenu>
        </CDBSidebarContent>


        <CDBSidebarFooter style={{ textAlign: 'center' }}>
          <div
            style={{
              padding: '20px 5px',
            }}
          >
            Sidebar Footer
          </div>
        </CDBSidebarFooter>
      </CDBSidebar>
    </div>
*/