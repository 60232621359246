import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

import { initializeApp } from "firebase/app";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { app } from './firebase'
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from './firebase'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import SideBar from './component/SideBarMember';
import { Space, FooterFixed } from './component/PageElement';
import { Icon } from '@iconify/react';
import { read, utils, writeFile } from 'xlsx';

function Orders() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState({});
	const [orderList, setOrderList] = useState([]);
	const [orderId, setOrderId] = useState([]);
	const [orderJson, setOrderJson] = useState([]);
	const auth = getAuth();	
	
	const [ selectedList, setSelectedList ] = useState([])
	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})	
	const setField = (field, value) => {
		
		console.log('set field:'+field+','+value);
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	}
		
	useEffect(() => {
		onAuthStateChanged(auth, (user) => {
		  if (user) {
			const uid = user.uid;
			console.log('user login:'+ uid);
			setCurrentUser(user);
			queryOrders(locationRef.current.state.saleid);	
		  } else {
			console.log('user not login');
			goToPage('/login');
		  }
		});		
	}, [])
	
	const goToPage = (path) => {
		console.log('goto:'+path);
		navigate(path, {state: { }});
	}
	const openOrderDetail = (id) => {
		console.log('open order detail');
		let state = { order_id: id}
		console.log('state:'+state+','+JSON.stringify(state));
		navigate("/orderdetails", {state})
	}
	
	const queryOrders =  async (saleid) => {
		console.log('query orders:'+saleid);
		const q = query(collection(db, "purchases"), where("sale_id", "==", saleid));
		const querySnapshot = await getDocs(q);
		await querySnapshot.forEach((doc) => {
		  console.log(doc.id, " => ", doc.data());
		  orderList.push(doc.data());
		  orderId.push(doc.id);
		});
		setIsLoading(false);
		console.log('order list:'+orderList);
		
	}
	
	const handleSelectChange = (e) => {
		console.log('select change:'+e.target.checked+','+e.target.value);
		//setField('selected_order'+e.target.value, e.target.checked)
		//selectedList.push({'selected_order'+e.target.value, e.target.checked})
	}
	const prepareJsonToExport = () => {
		console.log('prepare json');
		var i=0;
		orderList.map((data,key)=>{
			console.log('i:'+i+','+data.name);
			var json = {
				name: data.name,
				mobile: data.mobile,
				product: data.product_name,
				promotion: data.promotion,
				purchased_price: data.purchase_price,
			}
			orderJson.push(json);
			i++;
		});
		console.log('order json:'+JSON.stringify(orderJson));
		
	}
	
	const exportToExcel = () => {
		prepareJsonToExport();
		const headings = [[
            'name',
            'mobile',
            'product',
			'promotion',
			'purchased_price'
		]];
			
		/*
		const headings = [[
            'postalcode',
            'address',
            'payment_option',
            'email',
			'province',
			'status',
			'district',
			'purchase_price',
			'name',
			'sale_id',
			'subdistrict',
			'purchased_date',
			'remark',
			'mobile'			
        ]];*/
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
		utils.sheet_add_aoa(ws, headings);
		utils.sheet_add_json(ws, orderJson , { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'DeliveryList.xlsx');
		
	}
	//test excel
	/*const [movies, setMovies] = useState([]);
	const handleImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    setMovies(rows)
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }
	const handleExport = () => {
        const headings = [[
            'postalcode',
            'address',
            'payment_option',
            'email',
			'province',
			'status',
			'district',
			'purchase_price',
			'name',
			'sale_id',
			'subdistrict',
			'purchased_date',
			'remark',
			'mobile',
			'purchased_date',			
        ]];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
		console.log('movies:'+JSON.stringify(movies));
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, movies, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Movie Report.xlsx');
    }
	*/
	
	if(isLoading) {
		return (
			<div>
			<Navbar showTitle={false} />	
				<div style={{margin:30}} className="row alignCenter">
					Loading...				
				</div>
				<FooterFixed/>	
			</div>
		);
	} else {
		var i = 0;
		return (
			<div>
			<Navbar showTitle={false} />
				<div className="row">
				<div className="col-2">
					<SideBar />
				</div>
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="col-10 ql-align-center">			
				<div style={{marginTop:60,marginBottom:60,marginLeft:8,marginRight:8}} className="row">
					<div className="row" style={{marginBottom:16,marginTop:16,padding:8}}>
						<div className="col-1 ql-align-left" style={{margin:0,padding:0}}><h4><b>สินค้า:</b></h4></div>
						<div className="col-7 ql-align-left cell-border "><h5>{locationRef.current.state.product}</h5></div>
						<div className="col-4 cell-border ql-align-right"><Button onClick={exportToExcel}>Export</Button></div>
					</div>
					
					<div className="row table-border" id={i} style={{backgroundColor:'#dddddd'}}>
							<div className="col-auto cell-border ql-align-left" style={{width:40}}></div>
							<div className="col-4 cell-border ql-align-left"><b>ชื่อ นามสกุล</b></div>
							<div className="col-3 cell-border ql-align-left"><b>โปรโมชั่น</b></div>
							<div className="col-2 cell-border ql-align-center"><b>ราคา</b></div>
							<div className="col-1 cell-border ql-align-right"><b>จัดส่ง</b></div>
							<div className="col-1 cell-border ql-align-right"></div>
					</div>
					{orderList.map((data,key)=>{						
						//const str = data.expiredDate;
						//const expire = str?.substring(0, 10);
						
						//const url = '/orderdetails?id='+orderId[i];
						const id = orderId[i];
						console.log('order id:'+id);
						i++;
						return(
						<div key={i} className="row table-border">
							<div className="col-auto cell-border ql-align-left" style={{width:40}}>
								<input
								  type="checkbox"
								  value={i}
								  onChange={handleSelectChange}
								/>
							</div>
							<div className="col-4 cell-border ql-align-left">{data.name}</div>
							<div className="col-3 cell-border ql-align-left">Promotion1</div>
							<div className="col-2 cell-border ql-align-center">{data.purchase_price}</div>
							<div className="col-1 cell-border ql-align-right">{data.payment_option}</div>	
							<div className="col-1 cell-border ql-align-right">
							<Icon icon="material-symbols:file-open-outline-rounded" className="iconSmallNoMargin"
							 onClick={() => openOrderDetail(id)}/>
							
							</div>
						 </div>
						 )
					  })  
					}
				</div></div>
				</div>
			</div>
		);
	}
	
/*
<div className="row">
                        <div className="col-md-6">
                            <div className="input-group">
                                <div className="custom-file">
                                    <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={handleImport}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                    <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <button onClick={handleExport} className="btn btn-primary float-right">
                                Export <i className="fa fa-download"></i>
                            </button>
                        </div>
                    </div>
	if(isLoading) {
		return (		
			<div>
				<Navbar showTitle={false} />	
					<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
						<div className="row">
							<div className="alignCenter col-12" style={{marginTop:20}}>
								<h3><b>หน้าจัดการบัญชีสมาชิก</b></h3>
							</div>	
							
							<span style={{marginTop:50,fontSize:24}} className="alignCenter">Loading...</span>;
						</div>
					</div>
			</div>
		);
	} else {			
		return (
			<div>
				<Navbar showTitle={false} />	
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
					<div>
						<div className="row">
							<div className="alignCenter col-12" style={{marginTop:20}}>
								<h3><b>หน้าจัดการบัญชีสมาชิก</b></h3>
							</div>					
							{
								!auth.currentUser ?
									<div className="col-12">
										<div className="alignCenter" style={{margin:30}}>กรุณา login ก่อนเข้าใช้งาน</div>
										<Link to="/login">
										<Button type='submit' className="loginButton">เข้าสู่ระบบ</Button>
										</Link>
									</div>
								:
									<div className="col-12">
										<MemberForm user={auth.currentUser}/>
									</div>
							}	
						</div>
					</div>
				</div>
				<FooterMain/>	
			</div>	  
		);
	}
	*/
}

function MemberForm(props) {
	const [showLoading, setShowLoading] = useState(false);
	console.log('user:'+props.currentUser);
	return(
		<div style={{margin:20}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>Loading...</div>
				</div>
			</div>
			<div style={{ margin:20 }}>
				<Link to="/createsalepage">
					<Button className="bigButton">สร้าง SalePage</Button>
				</Link>
				<Space color='#ffffff' h={20}/>
				<Link to="/managepage">
					<Button className="bigButton">จัดการ SalePage</Button>
				</Link>
				<Space color='#ffffff' h={20}/>
				<Link to="/">
					<Button className="bigButton">ดูรายงาน</Button>
				</Link>		
				<Space color='#ffffff' h={20}/>
				<Link to="/test">
					<Button className="bigButton">Test</Button>
				</Link>					
			</div>
			<Space h={16}/>
		</div>			
	);
}


export default Orders;