import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, FloatingLabel } from 'react-bootstrap';
import { doc, collection, addDoc, serverTimestamp, updateDoc, increment } from "firebase/firestore";
import { db } from './firebase'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/Navbar';
import { Space, HeaderBar,FooterNotFixed } from './component/PageElement';
import {provinces} from './json/thai_provinces.js';
import {th_districts} from './json/thai_districts.js';
import {th_subdistricts} from './json/thai_subdistricts.js';
import CODImage from './images/cod.png';
import ReactPixel from 'react-facebook-pixel';

function Payment() {
	const location = useLocation();	
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedPromo, setSelectedPromo] = useState({});
	const [proListDivs, setProListDivs] = useState([]);
	const [saleId, setSaleID] = useState("");
	const [selectAmount, setSelectAmount] = useState([]);
	const [paidAmount, setPaidAmount] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [fullPrice, setFullPrice] = useState(0);
	const [totalProduct, setTotalProduct] = useState(0);
	var promoNo = 0;
		
	useEffect(() => {
		
		//console.log('json state:'+JSON.stringify(locationRef.current.state));
		//console.log('product:'+locationRef.current.state.product_id+','+locationRef.current.state.product_name);
		
		getPromotionOptions();
		setSaleID(locationRef.current.state.saleid);
		setSelectedPromo(locationRef.current.state.promotions[0]);
		setPaidAmount(locationRef.current.state.promotions[0].promo_price);
		setDiscount(locationRef.current.state.promotions[0].promo_discount);
		var full = parseInt(locationRef.current.state.promotions[0].promo_discount)+parseInt(locationRef.current.state.promotions[0].promo_price);
		setFullPrice(full);
		selectAmount.push(1);
		setTotalProduct(locationRef.current.state.promotions[0].promo_amount);
		console.log('id:'+locationRef.current.state.saleid);
		setIsLoading(false);	
	}, [])
	
	const getPromotionOptions = async () => {
		//console.log('promotion: '+locationRef.state.promotions.length);
		if(locationRef.current.state.promotions) {	
			var size = locationRef.current.state.promotions.length;		
			var proListDiv = [];
			for (var i = 0; i < size; i++) {
				console.log('pro:'+i);
				proListDiv.push(
					<div className="promoBar" key={i} style={{backgroundColor: promoNo===i? '#e3f3ff': '#ffffff'}} >						
						<input type="radio" value={i} name="promo" onChange={handleSelectPromo} style={{marginRight:16,marginBottom:14}}
						checked = {promoNo === i}/>
						{locationRef.current.state.promotions[i].promo_desc}
					</div>
				);
			}			
			setProListDivs(proListDiv);
		}		
	}
	
	const handleSelectPromo = async (event) => {
		console.log('handle select promo:'+event.target.value+',total:'+selectAmount[0]);
		promoNo = parseInt(event.target.value);
		setSelectedPromo(locationRef.current.state.promotions[event.target.value]);
		var price = locationRef.current.state.promotions[event.target.value].promo_price;
		var paid = price * selectAmount[0];
		setPaidAmount(paid);
		var disc = locationRef.current.state.promotions[event.target.value].promo_discount * selectAmount[0];
		setDiscount(disc);
		var full = parseInt(paid)+parseInt(disc);
		setFullPrice(full);
		var total = locationRef.current.state.promotions[event.target.value].promo_amount * selectAmount[0];
		setTotalProduct(total);

		console.log('paid amt after select pro:'+paid+','+price+','+selectAmount[0]+','+full);
		getPromotionOptions();
	}
	const incrementTotal = () => {
		var amt = selectAmount[0]+1;
		selectAmount[0] = amt;
		var paid = selectedPromo.promo_price * amt;
		setPaidAmount(paid);
		var disc = selectedPromo.promo_discount * amt;
		setDiscount(disc);
		var full = parseInt(paid)+parseInt(disc);
		setFullPrice(full);
		var total = selectedPromo.promo_amount * amt;
		setTotalProduct(total);
	}
	const decrementTotal = () => {
		if(selectAmount[0]>0){
			var amt = selectAmount[0]-1;
			selectAmount[0] = amt;
			var paid = selectedPromo.promo_price * amt;
			setPaidAmount(paid);
			var disc = selectedPromo.promo_discount * amt;
			setDiscount(disc);
			var full = parseInt(paid)+parseInt(disc);
			setFullPrice(full);
			var total = selectedPromo.promo_amount * amt;
			setTotalProduct(total);
		}
	}
	
	const Summary = () => {
		return(
		<div style={{backgroundColor:'#F9F9F9',padding:16}}>
			<div className="ql-align-center" style={{fontSize:24,color:'#00264d'}}><b>สรุปคำสั่งซื้อ</b></div>
			<div className="row" style={{marginTop:24,marginLeft:16,marginRight:16}}>
				<div className="col-6">จำนวนสินค้า:</div>
				<div className="col-6 ql-align-right">{totalProduct} ชิ้น</div>
			</div>
			<div className="row" style={{marginTop:8,marginLeft:16,marginRight:16}}>
				<div className="col-6">ราคาก่อนลด:</div>
				<div className="col-6 ql-align-right">{fullPrice} บาท</div>
			</div>
			<div className="row" style={{marginTop:8,marginLeft:16,marginRight:16}}>
				<div className="col-6">ส่วนลด:</div>
				<div className="col-6 ql-align-right" style={{color:'red'}}>-{discount} บาท</div>
			</div>
			<div className="row" style={{fontSize:18,marginTop:16,marginLeft:16,marginRight:16}}>
				<div className="col-12"><b>จำนวนเงินที่ต้องชำระ:</b></div>
			</div>
			<div className="row" style={{marginTop:8,marginLeft:16,marginRight:16}}>
				<div className="col-12 ql-align-right" style={{fontSize:24}}><b>{paidAmount} บาท</b></div>
			</div>
		</div>
		);

	}
	
	const PromotionOptions = (props) => {
		if(props.list) {
			return (
			<div>
				<div style={{backgroundColor:'#ffffff',margin:16,marginBottom:36}}>
					{props.list}
					<div className="ql-align-center" style={{marginTop:8}}>
					<b>จำนวน</b>
					</div>
					<div className="input-group ql-align-center" style={{marginTop:8}}>
						<Button onClick={decrementTotal}>-</Button>		
						<Form.Group controlId="form.total_amount" style={{width:100,textAlign:'center'}}>
							<Form.Control type="number" placeholder="" className="text-center" defaultValue={selectAmount[0]} />
						</Form.Group>
						<Button onClick={incrementTotal}>+</Button>
									 
					</div>				
				</div>

			  
			</div>
			);
		}
	}
	/*
	<div className="row ql-align-right">						
						<div className="col-3"><b>จำนวน :</b></div>
						<div className="col-3">
						<Form.Group  controlId="form.total_amount">
						<Form.Control type="total_amount" placeholder="" onChange={ e => setTotalAmount(e.target.value) }/>
						</Form.Group>
						</div>
					</div>
	*/
	/*address*/ 

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		return (
			<div>
				<Navbar title="ยืนยันคำสั่งซื้อ" showTitle={true} />
				<Space h={60} color='#ffffff'/>
				<ProductBar prodName={locationRef.current.state.product_name} 
				prodImage={locationRef.current.state.product_image[0]}/>

				<div style={{padding:16}}>
				<HeaderBar txt="กดเลือกโปรโมชั่น"/>
				<Space color="#cccccc" h={2}/>
				</div>
				<PromotionOptions list={proListDivs}/>
				<Summary promo_price={paidAmount}/>					
				<Space color="#F9F9F9" h={3}/>
				<div style={{padding:16}}>
				<HeaderBar txt="ระบุข้อมูลสำหรับจัดส่ง"/>	
				<Space color="#cccccc" h={2}/>
				</div>
				<AddressForm selectPromo={selectedPromo} saleId={locationRef.current.state.saleid} 
				prodId={locationRef.current.state.product_id} prodName={locationRef.current.state.product_name}
				paid={paidAmount} discount={discount} total_product={totalProduct} total_promotion={selectAmount[0]}
				uid={locationRef.current.state.uid} payment_options={locationRef.current.state.payment_options} 
				bank_accounts={locationRef.current.state.bank_accounts} pixel={locationRef.current.state.pixel}/>
				<Space color="#ffffff" h={12}/>
				<Space color="#eeeeee" h={3}/>	
				<FooterNotFixed/>	
			</div>	  
		);
	}
	
}

/*
<Summary promo_price={selectedPromo.promo_price}/>	
<HeaderBar txt="กดเลือกโปรโมชั่น"/>
				<div style={{paddingLeft:16,paddingRight:16}}>
				<Space color="#eeeeee" h={2}/>
				</div>
*/

function ProductBar(props) {
	console.log('prod image:'+props.prodImage);
	return(
      <div className="row" style={{textAlign: 'left',backgroundColor:'#eeeeee',paddingTop:24,paddingBottom:24,paddingLeft:24}} >
		<div className="col-4">
			<img src={props.prodImage} className='img-thumbnail' alt='รูปสินค้า' style={{width:100}} />
		</div>
		<div className="col-7">
        <span className="bigText"><b>{props.prodName}</b></span>
		</div>
      </div>
	);
}

function Footer() {
	return(
		<div className="alignCenter" style={{backgroundColor: "#eeeeee", padding:10}}>
			<span className="smallText" style={{color:'#000000'}}>Testasdfasdfasdfasdfasdf</span>
		</div>
	);
}


function AddressForm(props) {
	const [districts_options,setDistrictOptions] = useState([]);
	const [subdistricts_options,setSubDistrictOptions] = useState([]);
	const [selectedPostalCode,setPostalCode] = useState("");
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const [ form, setForm ] = useState({})
	const [ errors, setErrors ] = useState({})
	
	//let selectedPayment='COD';
	const [selectedPayment,setSelectedPayment] = useState('COD');
	console.log('product:'+props.prodId+','+props.prodName);
	
	const navigate = useNavigate();
	  
	const setField = (field, value) => {
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	  }
	const goToLandingPage = (props) => {
		console.log('order id:'+props.orderId);
		navigate("/landing", {state: { saleId: props.saleId ,isSuccess: props.isSuccess, orderId: props.orderId, errorMessage: props.errorMessage }});
	}
	const goToTransferPage = (props) => {
		console.log('order id:'+props.orderId);
		navigate("/transfer", {state: { saleId: props.saleId ,isSuccess: props.isSuccess, orderId: props.orderId, errorMessage: props.errorMessage, bank_accounts:props.bank_accounts, pixel: props.pixel }});
	}
	const goToTransferPageTest = () => {
		console.log('test transfer page');
		navigate("/transfer", {state: { saleId: props.saleId,isSuccess: true, orderId: 'ASDFASDVXCasasdfWERW#E', errorMessage: '', bank_accounts:props.bank_accounts }});
	}
	  
	const addPurchaseTransaction = async (e) => {
        //e.preventDefault();  		
		const { name, mobile,address,province,district,subdistrict,postalcode,email,remark } = form
	    console.log('add purchase trx:'+name+','+props.saleId+','+email+','+remark+','+postalcode+','+selectedPostalCode+','+subdistrict+','+district+','+address+','+province+','+mobile);
		var email_str='';
		var remark_str='';
		console.log('email:'+(email===undefined));
		if(email === undefined || !email) {
			email_str = '';
		} else {
			email_str = email;
		}
		console.log('email_str:'+email_str);
		if(remark === undefined || !remark) {
			remark_str = '';
		} else {
			remark_str = remark;
		}
		console.log('remark_str:'+remark_str);
		var statusText='to-paid';
		if(selectedPayment === 'COD') {
			statusText='to-ship';
		} else {
			statusText='to-paid';
		}
		/*if ( !email && email === undefined && email !=='') {
			console.log('email not empty:'+email);
			email_str = email;
		}
		if ( !remark && remark !== undefined && remark !=='') {
			remark_str = remark;
		}
		*/
		//payment_option: selectedPayment,
		let purchased_date = serverTimestamp()
        try {
            const docRef = await addDoc(collection(db, "purchases"), {
				sale_id: props.saleId,
				product_id: props.prodId,
				product_name: props.prodName,
				promotions: props.selectPromo.promo_desc,
				purchase_price: props.selectPromo.promo_price,
				purchase_amount: props.total_promotion,
				purchase_discount: props.discount,
				paid_amount: props.paid,
				product_amount: props.total_product,
				payment_option: selectedPayment,				
				name: name,
				mobile: mobile,
				email: email_str,
				remark: remark_str,
				postalcode: selectedPostalCode,
				province: province,
				district: district,
				subdistrict: subdistrict,
				address: address,
				purchased_date: purchased_date,
				uid: props.uid,
				status: statusText,				
            });
            console.log("Document written with ID: ", docRef.id);
			
			//track purchase event
			trackPurchase(props.pixel,props.paid,selectedPayment);
			  
			setShowLoading(false);
			//todo 
			if(selectedPayment==='COD') {
				goToLandingPage({saleId: props.saleId,isSuccess:true,orderId:docRef.id,errorMessage:''});
			} else {
				goToTransferPage({saleId: props.saleId,isSuccess:true,orderId:docRef.id,errorMessage:'',bank_accounts:props.bank_accounts,pixel: props.pixel});
			}
			//update sale no. ----> move to use function nodejs instead
			//updateTotalSale({total:1,id:props.saleId});
			
          } catch (e) {
            console.error("Error adding document: ", e);
			
			setShowLoading(false);			
			//goToLandingPage({isSuccess:false,orderId:'',errorMessage:e});
			alert('Purchase error try again later');
          }
    }
	const trackPurchase = async (pid,amount,payment) => {
		console.log('tracking purchase:'+amount+','+pid+','+payment);
		ReactPixel.trackSingleCustom(pid, 'confirmPurchase', {currency: "THB", value: amount, payment:payment});		
		//ReactPixel.trackSingleCustom(pid, 'purchase', {currency: "TH", value: amount, payment:payment});
	}
	
	/*not used
	const updateTotalSale = async (props) => {
		
		const purchaseRef = doc(db, "salepages", props.id);

		// Set the "capital" field of the city 'DC'
		await updateDoc(purchaseRef, {
		  total_order: increment(props.total)
		});
		
	}*/
	
	const handleSubmitTest = e => {
		e.preventDefault();
		navigate("/landing", {state: { isSuccess: true, orderId: '444444444asdfasdf234234', errorMessage: 'sdfasdfasdfasdfdasdfasdfasdf' }});
	}
	
	
	const handleSubmit = e => {
		
		e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);
		
		// get our new errors
		const newErrors = findFormErrors()
		//const newErrors = [];
		// Conditional logic:
		if ( Object.keys(newErrors).length > 0 ) {
		  // We got errors!
		  console.log('set error');
		  console.log('selected promo:'+props.selectPromo.promo_price+','+selectedPayment);
		  setErrors(newErrors);
		  setSubmitting(false);  
		  setShowLoading(false);
		} else {
		  // No errors! Put any logic here for the form submission!
		  addPurchaseTransaction();
		}
		
	}
	const findFormErrors = () => {
		console.log('find error');
		const { name, mobile,address,province,district,subdistrict} = form
		const newErrors = {}
		// name errors
		console.log('name:'+name+','+mobile+','+address+','+district+','+subdistrict+','+province);
		if ( !name || name === '' ) {
			newErrors.name = 'กรุณาระบุชื่อนามสกุล'
		}
		if ( !mobile || mobile === '' || mobile.length <9 ) {
			newErrors.mobile = 'กรุณาระบุเบอร์โทรศัพท์ให้ถูกต้อง'
		}	
		if ( !address || address === '' ) {
			newErrors.address = 'กรุณาระบุที่อยู่'
		}
		if ( !province || province === '' || province ==='000000' ) {
			newErrors.province = 'กรุณาเลือกจังหวัด'
		}
		if ( !district || district === '' || district ==='000000' ) {
			newErrors.district = 'กรุณาเลือกอำเภอ/เขต'
		}
		if ( !subdistrict || subdistrict === '' || subdistrict ==='000000' ) {
			newErrors.subdistrict = 'กรุณาเลือกตำบล/แขวง'
		}		

		return newErrors
	}
	
	const province_options = provinces.map((item) => {
    return (
		<option key={item.id} value={item.id}>
			{item.name_th}
		</option>
		)
	  });
	  
	let filter_districts = [];
	let filter_subdistricts = [];
	let dis_options = [];
	let subdis_options = [];
  
	const selectProvince = async (event) => {
		console.log('provice selected:'+event.target.value);
		console.log('before filter:'+th_districts.length);
		var index = event.nativeEvent.target.selectedIndex;
		var province_name = event.nativeEvent.target[index].text;
		console.log('provice name:'+province_name);
		//clear data
		setPostalCode("");
		setSubDistrictOptions([]);		
		//for validation
		
		let selectId = parseInt(event.target.value);
		console.log('select provice id:'+selectId);
		if (selectId === 0) {
			console.log('not select province');
			setField('province', '000000')
			setDistrictOptions([]);
			
		} else {
			setField('province', province_name )
			console.log('th_district:'+th_districts.length);	
			filter_districts = th_districts.filter(
				item => (item.province_id === selectId)
			);
			console.log('result:'+filter_districts.length);			
			filter_districts.splice(0,0,{'id':'000000','name_th':'เลือกอำเภอ/เขต','province_id':'999999'});
			dis_options = filter_districts.map((item) => {
				return (
					<option key={item.id} value={item.id}>
						{item.name_th}
					</option>
					)
				  });
			console.log('districts_options:'+dis_options.length);
			console.log('districts_options after add:'+dis_options.length);
			setDistrictOptions(dis_options);			
		}
	}
	
	const selectDistrict = async (event) => {
		console.log('district selected:'+event.target.value);
		console.log('before filter:'+th_subdistricts.length);
		let selectId = parseInt(event.target.value);
		var index = event.nativeEvent.target.selectedIndex;
		var district_name = event.nativeEvent.target[index].text;
		console.log('district name:'+district_name);
		
		if (selectId === 0) {
			console.log('not select district');
			setField('district', '000000');
		} else {
			console.log('select districtprovince:'+selectId);
			setField('district', district_name)			
			filter_subdistricts = th_subdistricts.filter(item => (item.amphure_id === selectId)
			);
			console.log('result:'+filter_subdistricts.length);
			filter_subdistricts.splice(0,0,{'id':'000000','name_th':'เลือกตำบล/แขวง','zip_code':''});
			subdis_options = filter_subdistricts.map((item) => {
				if(item.id==='000000'){
					return (
						<option key={item.id} value={item.zip_code}>
							{item.name_th}
						</option>
						)					
				} else {
					return (
						<option key={item.id} value={item.zip_code}>
							{item.name_th}
						</option>
						)
					}
				});
			console.log('subdistricts_options:'+subdistricts_options.length);
			setSubDistrictOptions(subdis_options);			
		}
	}
	
	const selectSubDistrict = async (event) => {
		console.log('subdistrict selected:'+event.target.value);
		let selectId = parseInt(event.target.value);
		var index = event.nativeEvent.target.selectedIndex;
		var subdistrict_name = event.nativeEvent.target[index].text;
		console.log('subdistrict name:'+subdistrict_name);
		if (selectId === 0) {
			console.log('not select subdistrict');
			setField('subdistrict', '000000');
		} else {
			setPostalCode(event.target.value);
			setField('subdistrict', subdistrict_name )			
		}
		
	}
	
	function handlePaymentOption(value){
		console.log('payment selected:'+value);
		setSelectedPayment(value);
		if(value === 'transfer') {
			console.log('to show tranfer detail');
		}
		
	}
	

	var i=0;
	return (
	<div style={{margin:20}}>
		<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
			<div className='modal-content'>
			  <div className='loader'></div>
			  <div className='modal-text'>Loading...</div>
			</div>
		</div>
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
			<Form.Group  controlId="form.name" style={{marginBottom:12}}>				
				<Form.Label>ชื่อ นามสกุล *</Form.Label>
				<Form.Control type="text" placeholder="" required 
				onChange={ e => setField('name', e.target.value) }
				isInvalid={ !!errors.name }/>
				<Form.Control.Feedback type="invalid">{ errors.name }</Form.Control.Feedback>
			</Form.Group>
			<Form.Group  controlId="form.mobile" style={{marginBottom:12}}>
				<Form.Label>เบอร์โทรศัพท์ *</Form.Label>
				<Form.Control type="number" maxLength="10" placeholder=""  required 
				onChange={ e => setField('mobile', e.target.value) }
				isInvalid={ !!errors.mobile }/>
				<Form.Control.Feedback type="invalid">{ errors.mobile }</Form.Control.Feedback>
			</Form.Group>
			<Form.Group  controlId="form.address1" style={{marginBottom:12}}>
				<Form.Label>ที่อยู่ * (บ้านเลขที่/หมู่บ้าน/ซอย/ถนน)</Form.Label>
				<Form.Control as="textarea" rows={3} style={{height: '80px'}} required 		
				onChange={ e => setField('address', e.target.value) }	
				isInvalid={ !!errors.address }				
				/>
				<Form.Control.Feedback type="invalid">{ errors.address }</Form.Control.Feedback>
			</Form.Group>
			<Form.Group  controlId="form.province" style={{marginBottom:12}}>
				<Form.Select size="lg" style={{color:'#666666',fontSize:16,marginBottom:16}} onChange={selectProvince} isInvalid={ !!errors.province }>
				{province_options}
				</Form.Select>
				<Form.Control.Feedback type="invalid">{ errors.province }</Form.Control.Feedback>
			</Form.Group>
			<Form.Group  controlId="form.district" style={{marginBottom:12}}>
				<Form.Select size="lg" style={{color:'#666666',fontSize:16,marginBottom:16}} onChange={selectDistrict} isInvalid={ !!errors.district }>
				{districts_options}
				</Form.Select>
				<Form.Control.Feedback type="invalid">{ errors.district }</Form.Control.Feedback>
			</Form.Group>
			<Form.Group  controlId="form.subdistrict" style={{marginBottom:12}}>
				<Form.Select size="lg" style={{color:'#666666',fontSize:16,marginBottom:16}} onChange={selectSubDistrict} isInvalid={ !!errors.subdistrict }>
				{subdistricts_options}
				</Form.Select>
				<Form.Control.Feedback type="invalid">{ errors.subdistrict }</Form.Control.Feedback>
			</Form.Group>			
			<Form.Group  controlId="form.postalcode" style={{marginBottom:12}}>
				<FloatingLabel label="รหัสไปรษณีย์ *" className="mb-3">					
				<Form.Control disabled type="text" placeholder="" value={selectedPostalCode} required/>
				</FloatingLabel>
			</Form.Group>
			<Form.Group  controlId="form.email">
				<Form.Label>อีเมล์</Form.Label>
				<Form.Control type="email" placeholder="" onChange={ e => setField('email', e.target.value) }/>
			</Form.Group>
			<Form.Group  controlId="form.remark">
				<Form.Label>ข้อความเพิ่มเติม</Form.Label>
				<Form.Control as="textarea" rows={2} style={{height: '30px'}} required
				onChange={ e => setField('remark', e.target.value) }/>
			</Form.Group>
			<div className="col-12" style={{textAlign: 'left',paddingBottom:12, paddingTop: 36,backgroundColor:'#ffffff'}} >
				<span className="bigText"><b>วิธีชำระเงิน</b></span>
			</div>
			<Space color="#cccccc" h={2}/>
			<div className="paymentOptionBar" >						
				<input type="radio" value='COD' name="paymentOption" onChange= { e => handlePaymentOption(e.target.value)} style={{marginRight:16,marginBottom:8}}
				checked={selectedPayment === 'COD'}
				/>
				เก็บเงินปลายทาง
				<img src={CODImage} width="60" style={{marginLeft:20,marginRight:20}} alt='COD'/>
			</div>
			{
			props.payment_options.includes('transfer') ?
			<div>
				<div className="paymentOptionBar" >						
					<input type="radio" value='transfer' name="paymentOption" onChange= { e => handlePaymentOption(e.target.value)} style={{marginRight:16,marginBottom:0}}
					checked={selectedPayment === 'transfer'}
					/>
					โอนเงิน
				</div>
				<div style={{marginLeft:28,padding:16,border:'solid 1px #cccccc',display: selectedPayment==='transfer' ? 'block' : 'none'}}>
					<div style={{marginBottom:16}}>รายละเอียดการโอนเงิน</div>
					<Space h={1} color='#000000'/>					
					{
					props.bank_accounts.length>0 ?
						props.bank_accounts.map((data,key)=>{
							i++;
							return(
								<div style={{marginTop:16,marginLeft:8}} className="row" key={i}>
									<div className="col-2" style={{padding:0}}>
									<img src={require(`./images/bank/${data.bank}.png`)} width='36' style={{textAlign:'left'}}/>				
									</div>	
									<div className="col-10">เลขที่บัญชี: <span style={{marginLeft:16}}>xxxxxxxxxx</span></div>
								</div>								
							);
						})
						:
						<div>no data</div>
					}
					<div style={{marginLeft:12,marginRight:12,marginTop:24,marginBottom:0,fontSize:13,fontWeight:700}}>
					เลขที่บัญชีจะแสดงหลังจากกดยืนยังคำสั่งซื้อ
					</div>
				</div>
				
			</div>
			:
			<div>{props.payment_options}</div>
				
			}
			
			<Button type='submit' className="confirmButton"  disabled={isSubmitting} style={{marginTop:40}}>ยืนยันคำสั่งซื้อ</Button>

			
			
		</Form>
	</div>
	);
	
}
/*
			<Button onClick={goToTransferPageTest}>Goto Transfer Page</Button>
<div style={{marginTop:16}} className="row">
						<div className="col-2" style={{padding:0}}>
						<img src={require(`./images/bank/${props.bank_accounts[0].bank}.png`)} width='36' style={{textAlign:'left'}}/>
						
						</div>	
						<div className="col-10">{props.bank_accounts[0]}</div>
					</div>
			<Button className="confirmButton" onClick={handleSubmitTest} >TestLanding</Button>
*/
/*<DeliveryOptions/>*/

function SummaryV0(props) {
	return(
	<div style={{backgroundColor:'#eeeeee'}}>
	  <div style={{padding:12,marginLeft:16}} className="alignLeft">
		<span style={{marginTop:12}}><b>รวมราคา : </b></span> 
		<span style={{width:20}}/>
		<span style={{color:'#0856a8', fontWeight: 600, fontSize: 30, fontFamily: 'Sarabun'}}><b>{props.promo_price} บาท</b></span> 
	  </div>
	</div>
	);
}



/*
function HeaderBar(props) {
	return(
      <div className="col-12" style={{textAlign: 'left',padding: 16,backgroundColor:'#ffffff'}} >
        <span className="bigText"><b>{props.txt}</b></span>
      </div>
	);
}
function Space(props) {
	return(
	<div style={{height:props.h,backgroundColor:props.color}} >
    </div>
	);
}
*/

export default Payment;