// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC8oYTy3V2opv6oPzONAvQUburJf5TWUXo",
  authDomain: "henghengsalepage.firebaseapp.com",
  projectId: "henghengsalepage",
  storageBucket: "henghengsalepage.appspot.com",
  messagingSenderId: "987503090202",
  appId: "1:987503090202:web:896ba18aa4f8b99c12cfa3",
  measurementId: "G-RCLM4ZXVN1"
};
// Initialize Firebase

export const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);



/*
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC8oYTy3V2opv6oPzONAvQUburJf5TWUXo",
  authDomain: "henghengsalepage.firebaseapp.com",
  projectId: "henghengsalepage",
  storageBucket: "henghengsalepage.appspot.com",
  messagingSenderId: "987503090202",
  appId: "1:987503090202:web:896ba18aa4f8b99c12cfa3",
  measurementId: "G-RCLM4ZXVN1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/