import {React, useState, useEffect, useRef, forwardRef} from "react";
import { useLocation, Link, useNavigate,  } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { getDoc, doc } from "firebase/firestore";
import { db } from './firebase'; 
//print
import ReactToPrint	 from 'react-to-print';
import { ComponentToPrint } from './ComponentToPrint';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import SideBar from './component/SideBarMember';
import { Space,FooterFixed, FooterNotFixed } from './component/PageElement';

function OrderDetails() {
	const navigate = useNavigate();
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [orderDetail, setOrderDetail] = useState({});
		
	useEffect(() => {
		queryOrderDetail(locationRef.current.state.order_id)
		
	}, [])
	
	const queryOrderDetail = async (id) => {
		console.log('get order detail:'+id);
		const docRef = doc(db, "purchases",  id);
		const docSnap = await getDoc(docRef)
	  
		if (docSnap.exists()) {
			console.log("Document data:", JSON.stringify(docSnap.data())+','+docSnap.id)
			setOrderDetail(docSnap.data());
			setIsLoading(false);	
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!")
		}
	}
		
	const goToPage = (path) => {
		console.log('goto:'+path);
		navigate(path, {state: { }});
		
	}
	const componentRef = useRef();
	
	const ComponentToPrintTest2 = forwardRef((props, ref) => {
	  console.log('props:'+props+','+JSON.stringify(props));
	  //const { value } = props;
	  return (
		<div className="print-source" ref={ref}>
		  	<div style={{backgroundColor:'#ffffff',marginTop:24, marginLeft:8,marginRight:8}} className="row alignLeft">
					<h4>รายละเอียดคำสั่งซื้อ</h4>
					<br/><br/>
					<div className="row">
						<div className="col-4"><b>เลขคำสั่งซื้อ:</b></div>
						<div className="col-8">{locationRef.current.state.order_id}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>ชื่อ:</b></div>
						<div className="col-8">{orderDetail.name}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>สินค้า:</b></div>
						<div className="col-8">{orderDetail.product_name}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>โปรโมชั่น:</b></div>
						<div className="col-8">{orderDetail.promotions}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>ราคา:</b></div>
						<div className="col-8">{orderDetail.purchase_price} บาท</div>
					</div>
					<div className="row">
						<div className="col-4"><b>วันที่สั่งซื้อ:</b></div>
						<div className="col-8">{orderDetail.purchased_date.toDate().toString()}</div>
					</div>
			</div>
		</div>
		
	  );
	});

	const ComponentToPrintTest =  () => {
			return (
			  <div style={{backgroundColor:'#ffffff',marginTop:24, marginLeft:8,marginRight:8}} className="row alignLeft">
					<h4>รายละเอียดคำสั่งซื้อ</h4>
					<br/><br/>
					<div className="row">
						<div className="col-4"><b>เลขคำสั่งซื้อ:</b></div>
						<div className="col-8">{locationRef.current.state.order_id}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>ชื่อ:</b></div>
						<div className="col-8">{orderDetail.name}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>สินค้า:</b></div>
						<div className="col-8">{orderDetail.product_name}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>โปรโมชั่น:</b></div>
						<div className="col-8">{orderDetail.promotions}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>ราคา:</b></div>
						<div className="col-8">{orderDetail.purchase_price} บาท</div>
					</div>
					<div className="row">
						<div className="col-4"><b>วันที่สั่งซื้อ:</b></div>
						<div className="col-8">{orderDetail.purchased_date.toDate().toString()}</div>
					</div>
				</div>
			);
		  
	}
	

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		return (
			<div>
			<Navbar showTitle={false} />	
				<div className="row">
				<div className="col-2">
					<SideBar />
				</div>
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="col-10 ql-align-center">
				<div>
				  <ReactToPrint
					trigger={() => <button>Print this out!</button>}
					content={() => componentRef.current}
				  />
				  <ComponentToPrintTest2 ref={componentRef} props={'test'}/>
				</div>
				</div></div>
			</div>
		);
	}

}



export default OrderDetails;


/*
<div style={{backgroundColor:'#ffffff',marginTop:24, marginLeft:8,marginRight:8}} className="row alignLeft">
					<h4>รายละเอียดคำสั่งซื้อ</h4>
					<br/><br/>
					<div className="row">
						<div className="col-4"><b>เลขคำสั่งซื้อ:</b></div>
						<div className="col-8">{locationRef.current.state.order_id}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>ชื่อ:</b></div>
						<div className="col-8">{orderDetail.name}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>สินค้า:</b></div>
						<div className="col-8">{orderDetail.product_name}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>โปรโมชั่น:</b></div>
						<div className="col-8">{orderDetail.promotions}</div>
					</div>
					<div className="row">
						<div className="col-4"><b>ราคา:</b></div>
						<div className="col-8">{orderDetail.purchase_price} บาท</div>
					</div>
					<div className="row">
						<div className="col-4"><b>วันที่สั่งซื้อ:</b></div>
						<div className="col-8">{orderDetail.purchased_date.toDate().toString()}</div>
					</div>
				</div>
*/