import { React, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

import { initializeApp } from "firebase/app";
import { getAuth , onAuthStateChanged } from "firebase/auth";
import { app } from './firebase'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/NavbarMember';
import SideBar from './component/SideBarMember';
import { Space, FooterFixed } from './component/PageElement';

function Member() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [currentUser, setCurrentUser] = useState({});
	
	const auth = getAuth();
	onAuthStateChanged(auth, (user) => {
	  if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/firebase.User
		const uid = user.uid;
		console.log('user login:'+ uid);
		setCurrentUser(user);
		setIsLoading(false);	
	  } else {
		console.log('user not login');
		setIsLoading(false);	
	  }
	});
		
	useEffect(() => {
		//checkAuthen();
		//setIsLoading(false);	
	}, [])

	const checkAuthen = async () => {
		const auth = await getAuth(app);
		const user = auth.currentUser;
		if(!user){
			console.log('login user:'+auth.currentUser+','+auth.currentUser);
			setCurrentUser(user);
		}			
	}
	
	return (	
	
		<div>			
			<Navbar showTitle={false} auth={auth.currentUser} />	
			<div className="row">
				<div className="col-2">
				<SideBar />
				</div>
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="col-10 ql-align-center">
					<div className="row">
						<div className="alignCenter col-12" style={{marginTop:100}}>
							<h3><b>หน้าจัดการบัญชีสมาชิก</b></h3>
						</div>	
						{
						isLoading ?
						<span style={{marginTop:50,fontSize:18}} className="alignCenter">Loading...</span>
						:
						!auth.currentUser ?
							<div className="col-12">
							<div className="alignCenter" style={{margin:30}}>กรุณา login ก่อนเข้าใช้งาน</div>
								<Link to="/login">
								<Button type='submit' className="loginButton">เข้าสู่ระบบ</Button>
								</Link>
							</div>
							:
							<div className="col-12">
								<MemberForm user={auth.currentUser}/>
							</div>
						}
					</div>
				</div>
			</div>	
		</div>
	);
	
/*

			<FooterFixed/>
	if(isLoading) {
		return (		
			<div>
				<Navbar showTitle={false} />	
					<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
						<div className="row">
							<div className="alignCenter col-12" style={{marginTop:20}}>
								<h3><b>หน้าจัดการบัญชีสมาชิก</b></h3>
							</div>	
							
							<span style={{marginTop:50,fontSize:24}} className="alignCenter">Loading...</span>;
						</div>
					</div>
			</div>
		);
	} else {			
		return (
			<div>
				<Navbar showTitle={false} />	
				<div style={{backgroundColor:'#ffffff', marginTop:24}} className="alignCenter">
					<div>
						<div className="row">
							<div className="alignCenter col-12" style={{marginTop:20}}>
								<h3><b>หน้าจัดการบัญชีสมาชิก</b></h3>
							</div>					
							{
								!auth.currentUser ?
									<div className="col-12">
										<div className="alignCenter" style={{margin:30}}>กรุณา login ก่อนเข้าใช้งาน</div>
										<Link to="/login">
										<Button type='submit' className="loginButton">เข้าสู่ระบบ</Button>
										</Link>
									</div>
								:
									<div className="col-12">
										<MemberForm user={auth.currentUser}/>
									</div>
							}	
						</div>
					</div>
				</div>
				<FooterMain/>	
			</div>	  
		);
	}
	*/
}

function TestSideBar () {
	return (
                  <ul className="sidebar">
                  <li><span>BBB</span></li>
                   <li><span><i className="fa fa-home"></i></span><span>Home</span></li>
                   <li><span><i className="fa fa-dashboard"></i></span><span>Dashboard</span></li>
                   <li><span><i className="fa fa-users"></i></span><span>Users</span></li>
                   <li><span><i className="fa fa-shopping-cart"></i></span><span>Products</span></li>
                   <li><span><i className="fa fa-bookmark"></i></span><span>Bookmarks</span></li>
                   <li><span><i className="fa fa-gear"></i></span><span>Settings</span></li>
                   
                   
                  </ul>
	);

}

function MemberForm(props) {
	const [showLoading, setShowLoading] = useState(false);
	console.log('user:'+props.currentUser);
	return(
		<div style={{margin:20}}>
			<div style={{ display: showLoading ? 'flex' : 'none' }} className='modal'>
				<div className='modal-content'>
				  <div className='loader'></div>
				  <div className='modal-text'>Loading...</div>
				</div>
			</div>
			<div style={{ margin:20 }}>
				<Link to="/createsalepage">
					<Button className="bigButton">สร้าง SalePage</Button>
				</Link>
				<Space color='#ffffff' h={20}/>
				<Link to="/managepage">
					<Button className="bigButton">จัดการ SalePage</Button>
				</Link>
				<Space color='#ffffff' h={20}/>
				<Link to="/">
					<Button className="bigButton">ดูรายงาน</Button>
				</Link>		
				<Space color='#ffffff' h={20}/>
				<Link to="/test">
					<Button className="bigButton">Test</Button>
				</Link>					
			</div>
			<Space h={16}/>
		</div>			
	);
}


export default Member;