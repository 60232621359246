import {React, useState, useEffect, useRef, forwardRef} from "react";
import { useLocation, Link, useNavigate,  } from "react-router-dom";
import { Button, Form } from 'react-bootstrap';

import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { doc, collection, addDoc, serverTimestamp } from "firebase/firestore"; 
import { db } from './firebase'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/Navbar';
import { Space, FooterFixed, getBankName } from './component/PageElement';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPixel from 'react-facebook-pixel';

function Transfer() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [isSuccess, setSuccess] = useState(false);	
	const [orderId, setOrderId] = useState("");	
	const [errorMessage, setErrorMessage] = useState("");
		
	useEffect(() => {
		console.log('use effect:'+locationRef.current.state.isSuccess+','+locationRef.current.state.orderId);	
		console.log('id:'+locationRef.current.state.saleId);
		console.log('banks:'+locationRef.current.state.bank_accounts);
		
		//setSuccess(locationRef.current.state.isSuccess);
		//setOrderId(locationRef.current.state.orderId);
		//setErrorMessage(locationRef.current.state.errorMessage);
		setIsLoading(false);	
	}, [])

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		return (
			<div>
				<Navbar title="การชำระเงิน" showTitle={true} />	
				<PurchaseStatus isSuccess={locationRef.current.state.isSuccess} errorMessage={locationRef.current.state.errorMessage} orderId={locationRef.current.state.orderId} saleId={locationRef.current.state.saleId} bank_accounts={locationRef.current.state.bank_accounts} pixel={locationRef.current.state.pixel}/>					
					
				<FooterFixed/>	
			</div>	  
		);
	}
	
}


function Footer() {
	return(
		<div className="alignCenter" style={{backgroundColor: "#eeeeee", padding:10}}>
			<span className="smallText" style={{color:'#000000'}}>Testasdfasdfasdfasdfasdf</span>
		</div>
	);
}

function PurchaseStatus(props) {
	const [ form, setForm ] = useState({})
	const [validated, setValidated] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [ errors, setErrors ] = useState({})
	const [imageList, setImageList] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [startDate, setStartDate] = useState('');
	const [startTime, setStartTime] = useState('');
	
	const setField = (field, value) => {
		setForm({
		...form,
		[field]: value
		})
		// Check and see if errors exist, and remove them from the error object:
		if ( !!errors[field] ) setErrors({
		  ...errors,
		  [field]: null
		})
	  }
	
	
	const navigate = useNavigate();
	const goToSalePage = () => {
		let path = '/salepage?hhid='+props.saleId;
		console.log('paht:'+path);
		navigate(path, {state: { }});
	}
	const goToPage = (path) => {
		//let path = '/salepages?hhid='+props.saleId;
		console.log('id:'+props.saleId);
		console.log('paht:'+path);
		navigate(path, {state: { }});
	}
	
	const handleSubmit = e => {
		e.preventDefault()
		setSubmitting(true);		
		setShowLoading(true);
		
		// get our new errors
		const newErrors = findFormErrors()
		//const newErrors = [];
		// Conditional logic:
		if ( Object.keys(newErrors).length > 0 ) {
		  // We got errors!
		  console.log('set error');
		  
		  setErrors(newErrors);
		  setSubmitting(false);  
		  setShowLoading(false);
		} else {
		  // No errors! Put any logic here for the form submission!
		  //addPurchaseTransaction();
		  setErrors(newErrors);
		  console.log('no error, to save payment proof');
		  uploadImages();
		}		
	}
	
	const uploadImages = async() => {		
		console.log('start upload images');
		const storage = getStorage();
		
		//if (selectedFiles.length > 0 || (selectedFiles !== undefined && selectedFiles !== null)) {
		if (selectedFiles.length > 0) {
			console.log('files:'+selectedFiles.length);
			selectedFiles.map((file) => {
					console.log('file:'+file.size);
					let time = new Date().getTime();
					let ext = file.type.substring(file.type.lastIndexOf('/')+1, file.type.length);
					console.log('file name:'+storage, '/'+props.saleId+'/'+time+'.'+ext);
					const storageRef = ref(storage, '/paid_document/'+props.saleId+'/'+time+'.'+ext);
					//const storageRef = ref(storage, '/paid_document/'+time+'.'+ext);
				
					uploadBytes(storageRef, file).then((snapshot) => {
						console.log('Uploaded a blob or file!:'+snapshot);
						getDownloadURL(snapshot.ref).then((downloadURL) => {
							console.log('File available at', downloadURL);
							//save url
							imageList.push(downloadURL);							
							console.log('imageList:'+imageList[0]);
							saveTransferDoc(downloadURL);
						});
					}, (error) => {
						// Handle unsuccessful uploads
						console.log('error upload:'+error);
						setShowLoading(false);
						alert('พบปัญหาในการอัพโหลดรูป กรุณาลองใหม่อีกครั้ง');
					});
			});
			
		} else {
			console.log('file is empty');
			setShowLoading(false);
			alert('กรุณาใส่รูปภาพสินค้า');			
		}
	}
	
	const saveTransferDoc = async(url) => {
		const { selected_account } = form
		
		var transferDate = startDate;
		var transferTime = transferTime;
		var paidAccount = selected_account;
		var imageUrl = url;
		let created_date = serverTimestamp()
		let saveData = {
			saleId: props.saleId,
			orderId: props.orderId,
			transferDate: startDate,
			transferTime: startTime,
			paidAccount: paidAccount,
			imageUrl: url,
			created_date: serverTimestamp()		
		}
		
		console.log('data:'+JSON.stringify(saveData));		
		//await addDoc(doc(db, "paid_document", saleId), saveData);
		const docRef = await addDoc(collection(db, "paid_document"), saveData);
		
		setShowLoading(false);	
		
		console.log("Document written with ID: ", docRef.id);
		trackPaid(props.pixel);
		
		goToLandingPage({saleId: props.saleId,isSuccess:true,orderId:props.orderId,errorMessage:''});
			
		//goToStatusPage({statusCode:1,prodId:saleId,errorMessage:'', expiredDate, lastDate});
		//let state = { statusCode: 1, prodId: props.saleId, errorMessage: '', expiredDate, lastDate}
		//console.log('state:'+state+','+JSON.stringify(state));
		//navigate("/status", {state})
		
	}
	
	const trackPaid = async (pid) => {
		console.log('tracking paid:'+pid);
		ReactPixel.trackSingleCustom(pid, 'confirmPaid');		
	}
	
	const goToLandingPage = (props) => {
		console.log('order id:'+props.orderId);
		navigate("/landing", {state: { saleId: props.saleId ,isSuccess: props.isSuccess, orderId: props.orderId, errorMessage: props.errorMessage }});
	}
	
	
	const findFormErrors = () => {
		console.log('find error');
		const { selected_account, selected_bank } = form
		const newErrors = {}
		console.log('transfer date/time:'+startDate+','+startTime);
		if ( !startDate || startDate === '' ) {
			console.log('error date');
			newErrors.transfer_date = 'กรุณาระบุวันที่โอนเงิน';
		} 
		if ( !startTime || startTime === '' ) {
			console.log('error time');
			newErrors.transfer_time = 'กรุณาระบุเวลาที่โอนเงิน';
		} 

		console.log('selected account:'+selected_account);
		if ( !selected_account || selected_account === '' ) {
			console.log('error');
			newErrors.paid_account = 'กรุณาระบุบัญชีที่โอนเงิน'
		} 

		
		return newErrors
	}
	
	const selectAccount = async (event) => {
		console.log('account selected:'+event.target.value);
		let account = event.target.value;
		
		if (account === 0) {
			console.log('not select account');
			setField('selected_account', '');
		} else {
			console.log('select account:'+account);
			setField('selected_account', account)						
		}
	}/*
	const selectAccount = async (account) => {
		console.log('select account:'+account);
		if (account === 0) {
			console.log('not select account');
			setField('selected_account', '');
			setField('selected_bank', '');						
		} else {
			console.log('select account:'+account.bank);
			setField('selected_account', account.bank_account);
			setField('selected_bank', account.bank);						
		}
	}*/
	
	const handleFileInput = (e) => {
	  // handle validations
	  const file = e.target.files[0];
	  var files = selectedFiles;
	  if (file.size > 3072000) {
		alert('File size cannot exceed more than 3MB:'+file.size);
	  } else {
		files.push(file);
		setSelectedFiles(files);
	  }
	};
	
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<button className="example-custom-input" onClick={onClick} ref={ref}>
		  {value}
		</button>
	  ));

	const MyDatePicker = forwardRef(({ value, onClick }, ref) => (
		<Form.Group  controlId="form.transfer_date" style={{marginBottom:12}}>				
			<Form.Label>วันที่โอนเงิน *</Form.Label>
			<Form.Control type="text" placeholder="" required 
				isInvalid={ !!errors.transfer_date }
				onChange={ e => setField('transfer_date', e.target.value) }
				onClick={onClick} ref={ref}						
				value={value}/>
			<Form.Control.Feedback type="invalid">{ errors.transfer_date }</Form.Control.Feedback>
		</Form.Group>
	  ));	
	const MyTimePicker = forwardRef(({ value, onClick }, ref) => (
		<Form.Group  controlId="form.transfer_time" style={{marginBottom:12}}>				
			<Form.Label>เวลาที่โอนเงิน *</Form.Label>
			<Form.Control type="text" placeholder="" required 
				isInvalid={ !!errors.transfer_time }
				onChange={ e => setField('transfer_time', e.target.value) }
				onClick={onClick} ref={ref}						
				value={value}/>
			<Form.Control.Feedback type="invalid">{ errors.transfer_time }</Form.Control.Feedback>
		</Form.Group>
	  ));	
	  
	var i=0,ii=0;
	return (
	<div>
		<div style={{display: props.isSuccess ? 'inline' : 'none'}}>
			<Space h={70} color='#ffffff'/>
			<div style={{color:'#000000', fontWeight: 700, fontSize: 20, fontFamily: 'Sarabun', marginTop:20,paddingLeft:16,paddingRight:16}}>
			ขั้นตอนการชำระเงิน
			</div>
			<div style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun', padding:16}}>
			1. โอนเงินตามรายละเอียดข้างล่าง<br/>
			2. แจ้งรายละเอียดการชำระเงินผ่านฟอร์มในเว็บ
			</div>
			<div style={{marginLeft:16,marginRight:16,padding:16,border:'solid 1px #cccccc'}}>
					<div style={{marginBottom:16}}>รายละเอียดการโอนเงิน</div>
					<Space h={1} color='#000000'/>					
					{
					props.bank_accounts.length>0 ?
						props.bank_accounts.map((data,key)=>{
							i++;				
							return(
								<div style={{marginTop:16,marginLeft:8}} className="row" key={i}>
									<div className="col-2" style={{padding:0}}>
									<img src={require(`./images/bank/${data.bank}.png`)} width='36' style={{textAlign:'left'}}/>				
									</div>	
									<div className="col-10">เลขที่บัญชี: <span style={{marginLeft:16}}>{data.bank_account}</span></div>
								</div>								
							);
						})
						:
						<div>no data</div>
					}
					<div style={{marginLeft:12,marginRight:12,marginTop:24,marginBottom:0,fontSize:13,fontWeight:700}}>
					
					</div>
			</div>
			<div style={{color:'#000000', fontWeight: 700, fontSize: 20, fontFamily: 'Sarabun', marginTop:20,paddingLeft:16,paddingRight:16}}>
			แจ้งการชำระเงิน
			</div>
			
			<div style={{marginLeft:16,marginRight:16,marginTop:16}}>
				<Form noValidate validated={validated} onSubmit={handleSubmit}>					
					<DatePicker
					  selected={startDate}
					  dateFormat="dd/MM/yyyy"
					  onChange={(date) => setStartDate(date)}
					  customInput={<MyDatePicker />}
					/>
					<DatePicker
					  selected={startTime}
					  onChange={(date) => setStartTime(date)}
					  showTimeSelect
					  showTimeSelectOnly
					  timeIntervals={5}
					  timeCaption="Time"
					  dateFormat="hh:mm aa"
					  customInput={<MyTimePicker />}
					/>
					
					<Form.Group  controlId="form.paid_account" style={{marginBottom:12}}>
					<Form.Label>บัญชีธนาคารที่โอนเงิน *</Form.Label>
					<Form.Select size="lg" style={{color:'#666666',fontSize:16,marginBottom:16}} onChange={selectAccount} isInvalid={ !!errors.paid_account }>
					<option key='0' value=''>กรุณาเลือกบัญชีธนาคาร</option>							
					{
					props.bank_accounts.length>0 ?
						props.bank_accounts.map((data,key)=>{
							ii=ii+100;	
							//var val = ''+${data.bank}'-'${data.bank_account};
							return(
								<option key={ii} value={`${data.bank}-${data.bank_account}`}>
									{getBankName(data.bank)} - {data.bank_account}
								</option>							
							);
						})
						:
						<option>no data</option>
					}
					</Form.Select>
					<Form.Control.Feedback type="invalid">{ errors.paid_account }</Form.Control.Feedback>
					</Form.Group>
					<div className="row">
						<Form.Group className="col-12" controlId="form.transfer_image" style={{marginBottom:20,textAlign:'left'}}>
						<Form.Label>แนบหลักฐานการโอนเงิน *</Form.Label>
						<Form.Control type="file" isInvalid={ !!errors.transfer_image} onChange={handleFileInput}
							accept=".png,.jpg,.jpeg,.webp"/>
						<Form.Control.Feedback type="invalid">{ errors.transfer_image}</Form.Control.Feedback>
						</Form.Group>								
					</div>
					<div className='center' style={{marginBottom:40,marginTop:20}}>
						<Button type='submit' className='confirmButton' style={{margin:0}}>ยืนยันการโอนเงิน</Button>		
					</div>	
				</Form>
			</div>
			
			
			}
		</div>
		<div style={{display: props.isSuccess ? 'none' : 'inline'}}>
			<Space h={70} color='#ffffff'/>
			<div  className="barCenter" style={{color:'#a62126	', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', 
			paddingLeft:20, paddingRight:20, paddingTop:40}}>
			การสั่งซื้อไม่สำเร็จสำเร็จ กรุณาลองใหม่อีกครั้ง
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:8}}>
			{props.errorMessage}
			</div>
			<div style={{height:50}}/>
		</div>
	</div>
	);
}

export default Transfer;

/*
{getBankName(data.bank)} - {data.bank_account}
<Form.Group  controlId="form.transfer_date" style={{marginBottom:12}}>				
						<Form.Label>วันที่โอนเงิน *</Form.Label>
						<Form.Control type="text" placeholder="" required 
						onChange={ e => setField('transfer_date', e.target.value) }
						isInvalid={ !!errors.transfer_date }/>
						<Form.Control.Feedback type="invalid">{ errors.transfer_date }</Form.Control.Feedback>
					</Form.Group>
			<div  className="barCenter" style={{color:'#289973', fontWeight: 500, fontSize: 20, fontFamily: 'Sarabun', marginTop:40,paddingLeft:16,paddingRight:16}}>
			คำสั่งซื้อถูกยืนยันเรียบร้อย
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 14, fontFamily: 'Sarabun',marginTop:8}}>
			หมายเลขสั่งซื้อของคุณคือ :
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 600, fontSize: 16, fontFamily: 'Sarabun',marginTop:8}}>
			{props.orderId}
			</div>
			<div style={{color:'#014f80', fontWeight: 700, fontSize: 19, fontFamily: 'Sarabun',marginTop:24,marginBottom:8,marginLeft:16,marginRight:16,textAlign:'center'}}>
			กรุณาโอนเงินตามเลขที่บัญชีธนาคารข้างล่าง และ 
			</div>
			
			<div  className="barCenter" style={{color:'#289973', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', marginTop:40}}>
			การสั่งซื้อสำเร็จเรียบร้อย
			</div>
*/