import { Nav, Navbar, NavLink, Container} from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom";
import { Icon } from '@iconify/react';
import LineIcon from '../images/line_crop.png';

const Navigationbar = (props) => {
	const navigate = useNavigate();
	if(props.showTitle) {
		return (	
			<Navbar collapseOnSelect expand="sm" bg="light" variant="light" className="navBarStyle"  fixed="top" >
				<Navbar.Brand>
				<Icon icon="material-symbols:arrow-back-rounded" width="24" color="#666666" style={{marginLeft:10}}
				onClick={() => navigate(-1)}
				/>
				</Navbar.Brand> 
				<Navbar.Brand>
				<b>{props.title}</b>
				</Navbar.Brand>			
				<Navbar.Brand>			
				</Navbar.Brand>
			</Navbar>
		);
	} else {
		return (	
			<Navbar collapseOnSelect expand="sm" bg="light" variant="light" className="navBarStyle" fixed="top" >
				<Navbar.Brand>
				<img src={LineIcon} width="40" style={{paddingLeft:10,marginRight:10}} alt="logo"/>
				<b>HengHeng</b>
				</Navbar.Brand>	
				<Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" 
				style={{marginRight:16, }} >
				 <Icon icon="ic:baseline-menu" />
				</Navbar.Toggle>
				<Navbar.Collapse id="navbarScroll" style={{marginLeft:16}}>
					{props.handleClickScroll?
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('top')}>หน้าแรก</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('price_bar')}>ราคาแพคเกจ</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('portfolio_bar')}>ตัวอย่าง</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('contact_bar')}>ติดต่อเรา</Nav.Link>
							</Nav.Item>							
							<Nav.Item>
								<Nav.Link href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					:
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link href='/'>หน้าแรก</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					}
				</Navbar.Collapse>     
			</Navbar>
		);
	}
}
 
export default Navigationbar;

/*
{props.handleClickScroll?
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('top')}>หน้าแรก</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link onClick={() => props.handleClickScroll('price_bar')}>ราคาแพคเกจ</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('portfolio_bar')}>ตัวอย่าง</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								 <Nav.Link onClick={() => props.handleClickScroll('contact_bar')}>ติดต่อเรา</Nav.Link>
							</Nav.Item>
							{!props.auth ? 
								<Nav.Item>
									<Nav.Link>
									เข้าสู่ระบบ
									</Nav.Link>
								</Nav.Item>
							  : 
								<Nav.Item>
									<Nav.Link>ออกจากระบบ</Nav.Link>
								</Nav.Item>
							}
							<Nav.Item>
								<Nav.Link disabled={!props.auth ? true:false} href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					:
						<Nav className='ms-auto'>
							<Nav.Item>
								<Nav.Link href='/'>หน้าแรก</Nav.Link>
							</Nav.Item>
							{!props.auth ? 
								<Nav.Item>
									<Nav.Link>
									เข้าสู่ระบบ
									</Nav.Link>
								</Nav.Item>
							  : 
								<Nav.Item>
									<Nav.Link>ออกจากระบบ</Nav.Link>
								</Nav.Item>
							  }
							<Nav.Item>
								<Nav.Link disabled={!props.auth ? true:false} href="/member">
								หน้าสมาชิก
								</Nav.Link>
							</Nav.Item>
						</Nav>
					}
*/

/*
<Nav className='ms-auto'>
						{props.handleClickScroll?
						  <Nav.Item>
							<Nav.Link onClick={() => props.handleClickScroll('top')}>หน้าแรก</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							<Nav.Link onClick={() => props.handleClickScroll('price_bar')}>ราคาแพคเกจ</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							 <Nav.Link onClick={() => props.handleClickScroll('portfolio_bar')}>ตัวอย่าง</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							 <Nav.Link onClick={() => props.handleClickScroll('contact_bar')}>ติดต่อเรา</Nav.Link>
						  </Nav.Item>
						:
						  <Nav.Item>
							<Nav.Link href='/'>หน้าแรก</Nav.Link>
						  </Nav.Item>
						  <Nav.Item>
							<Nav.Link>ออกจากระบบ</Nav.Link>
						  </Nav.Item>
						}
						  {!props.auth ? 
						  <Nav.Item>
							<Nav.Link>
							  เข้าสู่ระบบ
							</Nav.Link>
						  </Nav.Item>
						  : null
						  }
						  <Nav.Item>
							<Nav.Link disabled={!props.auth ? true:false} href="/member">
							  หน้าสมาชิก
							</Nav.Link>
						  </Nav.Item>
						  
					</Nav>
*/
/*
					!props.auth.currentUser ?
					<Nav className='ms-auto'>
						<NavLink  eventKey="1" as={Link} to="/">หน้าแรก</NavLink>
						<NavLink  eventKey="2" as={Link} to="/">ราคาแพคเกจ</NavLink>
						<NavLink  eventKey="3" as={Link} to="/">ตัวอย่าง</NavLink>
						<NavLink  eventKey="4" as={Link} to="/">ติดต่อเรา</NavLink>
						<NavLink  eventKey="5" as={Link} to="/login">เข้าสู่ระบบ</NavLink>
					</Nav>
					:
					<Nav className='ms-auto'>
						<NavLink  eventKey="6" as={Link} to="/">หน้าแรก</NavLink>
						<NavLink  eventKey="7" as={Link} to="/member">หน้าสมาชิก</NavLink>
						<NavLink  eventKey="8" as={Link} to="/logout">ออกจากระบบ</NavLink>
					</Nav>
				*/
/*
<Navbar.Brand href="/SalePage"><Icon icon="material-symbols:arrow-back-rounded" width="24" color="#999999" style={{marginLeft:10}}/></Navbar.Brand>


<Navbar collapseOnSelect expand="sm" bg="light" variant="light">
            <Navbar.Toggle aria-controls="navbarScroll" data-bs-toggle="collapse" data-bs-target="#navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav>
                    <NavLink  eventKey="1" as={Link} to="/">Home</NavLink>
                    <NavLink  eventKey="2" as={Link} to="/about">About</NavLink>
                    <NavLink  eventKey="3" as={Link} to="/contact">Contact</NavLink>
                </Nav>
            </Navbar.Collapse>     
        </Navbar>
*/