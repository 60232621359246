import React, { Component, createRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";

class Editor extends Component {
  state = { 
	editorHtml: this.props.value,
  };
  handleChange = (html) => {
    this.setState({ editorHtml: html });
    this.props.setHtml(html);
  };
  
  quillRef = createRef();
  
  /*modules = {
    toolbar: [
	
            [{ 'header': [1,2,3,4,5,6,false] }, { 'font': [] }],
      [{ 'header': [1, 2, false] }],
	  
            [{ size: [] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
	  
            [{ 'align': [] }],
    ],
  }*/

  formats = [
	  'header', 'font', 'size', 'color',
	  'bold', 'italic', 'underline', 'strike', 'blockquote',
	  'list', 'bullet', 'indent',
	  'align',
      'link', 'image', 'video',
  ]
  
  modules = {
    toolbar: {
        container: [
                [{ 'header': [1,2,3,4,5,6,false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
				[{ 'color': [] }],
                ['clean'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
				['link', 'image', 'video'],
            ],  
		/*handlers: {
			image: this.imageHandler
		},*/
		handlers: {
            image: () => this.imageHandler()
        },
		clipboard: {
            matchVisual: false,
        },
        /*imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
        }*/
	}
  }
	
  
	imageHandler = (a) => {
		//this.testInsert();
		console.log('image handler');
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();
		
        input.onchange = () => {
			console.log('input onchange');
            const file = input.files[0];
			console.log('file:'+file+','+file.type);

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.saveToServer(file);
				console.log('image is to upload ...');
            } else {
                console.warn("You could only upload images.");
            }
        };
    };
	
	saveToServer = (file) => {
		console.log('save to server:'+file.type);
		const storage = getStorage();
		let time = new Date().getTime();
		let ext = file.type.substring(file.type.lastIndexOf('/')+1, file.type.length);
		let uid = this.props.user.uid;
		console.log('uid:'+uid);
		console.log('file name:'+storage, '/'+uid+'/'+time+'.'+ext);
		//const storageRef = ref(storage, '/'+uid+'/'+time+'.'+ext);
		const storageRef = ref(storage, '/salepages/'+this.props.saleId+'/'+time+'.'+ext);

		// 'file' comes from the Blob or File API
		uploadBytes(storageRef, file).then((snapshot) => {
		  console.log('Uploaded a blob or file!:'+snapshot);
		  getDownloadURL(snapshot.ref).then((downloadURL) => {
			  console.log('File available at', downloadURL);
			  this.showImage(downloadURL);
			});
		}, 
		  (error) => {
			// Handle unsuccessful uploads
			console.log('error upload:'+error);
		  }, 		
		);
	}
	
	showImage = (url) => {
		let quillObj = this.quillRef.current.getEditor();
		const range = quillObj?.getSelection();
		quillObj.editor.insertEmbed(range.index, 'image', url);
		
		//update html
		var html = quillObj.root.innerHTML;
		console.log('html:'+html);
		this.handleChange(html);
		
		
		//let unprivilegedEditor = this.quillRef.current.unprivilegedEditor;
		//console.log('html:'+unprivilegedEditor.getHtml());
		
		//console.log('old html:'+this.state.editorHtml);
		//let newHtml = this.state.editorHtml+"";
		//this.setState({ editorHtml: newHtml });
		//console.log('new html:'+this.state.editorHtml);
		
		//let html = quillObj?.getSelection();
		//console.log('html:'+html+','+quillObj?.getHtml());
		
		//this.setState({ editorHtml: html });
		//this.props.setHtml(html);
	}
	
	testInsert = () => {
		console.log('test insert');
		let editor = this.quillRef.current.getEditor();
		var justHtml = editor.root.innerHTML;
		console.log('html:'+justHtml);
		
		//let editor = this.quillRef.current.getEditor();
		//let unprivilegedEditor = this.quillRef.makeUnprivilegedEditor(editor);
		
		/*let unprivilegedEditor = this.quillRef.current.unprivilegedEditor;
		// You may now use the unprivilegedEditor proxy methods
		console.log('html:'+unprivilegedEditor.getText());
		console.log('html:'+unprivilegedEditor.getHtml());
		console.log('html:'+unprivilegedEditor.getContents());*/
	}

  render() {
    return (
      <div className="text-editor">
		<ReactQuill
		  ref={this.quillRef}
          value={this.state.editorHtml}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          modules={this.modules}
          formats={this.formats}
		  defaultValue={this.props.value}
        />
      </div>
    );
  }
}

export default Editor;