import {React, useState, useEffect, useRef} from "react";
import { useLocation, Link, useNavigate,  } from "react-router-dom";
import { Button } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbar from './component/Navbar';
import { Space, FooterFixed } from './component/PageElement';

function Landing() {
	const location = useLocation();
	const locationRef = useRef(location);
	const [isLoading, setIsLoading] = useState(true);
	const [isSuccess, setSuccess] = useState(false);	
	const [orderId, setOrderId] = useState("");	
	const [errorMessage, setErrorMessage] = useState("");
		
	useEffect(() => {
		console.log('use effect:'+locationRef.current.state.isSuccess+','+locationRef.current.state.orderId);	
		console.log('id:'+locationRef.current.state.saleId);
		//setSuccess(locationRef.current.state.isSuccess);
		//setOrderId(locationRef.current.state.orderId);
		//setErrorMessage(locationRef.current.state.errorMessage);
		setIsLoading(false);	
	}, [])

	if(isLoading) {
		return <span style={{marginTop:30}} className="alignCenter">Loading...</span>;
	} else {
		return (
			<div>
				<Navbar title="คำสั่งซื้อ" showTitle={true} />	
				<PurchaseStatus isSuccess={locationRef.current.state.isSuccess} errorMessage={locationRef.current.state.errorMessage} orderId={locationRef.current.state.orderId} saleId={locationRef.current.state.saleId}/>					
					
				<FooterFixed/>	
			</div>	  
		);
	}
	
}


function Footer() {
	return(
		<div className="alignCenter" style={{backgroundColor: "#eeeeee", padding:10}}>
			<span className="smallText" style={{color:'#000000'}}>Testasdfasdfasdfasdfasdf</span>
		</div>
	);
}

function PurchaseStatus(props) {
	const navigate = useNavigate();
	const goToSalePage = () => {
		let path = '/salepage?hhid='+props.saleId;
		console.log('paht:'+path);
		navigate(path, {state: { }});
	}
	const goToPage = (path) => {
		//let path = '/salepages?hhid='+props.saleId;
		console.log('id:'+props.saleId);
		console.log('paht:'+path);
		navigate(path, {state: { }});
	}
		
	return (
	<div>
		<div style={{display: props.isSuccess ? 'inline' : 'none'}}>
			<Space h={70} color='#ffffff'/>
			<div  className="barCenter" style={{color:'#289973', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', marginTop:40}}>
			การสั่งซื้อสำเร็จเรียบร้อย
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:24}}>
			หมายเลขสั่งซื้อของคุณคือ :
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 600, fontSize: 18, fontFamily: 'Sarabun',marginTop:8}}>
			{props.orderId}
			</div>
			<div style={{height:30}}/>
		    {
			props.saleId === undefined ?
			<div className='center'>
			</div>	
			:
			<div className='center'>
				<Button className='greyButton' style={{margin:12}} onClick={() => goToPage('/salepage?hhid='+props.saleId)} >สั่งซื้อใหม่</Button>		
			</div>	
			}
		</div>
		<div style={{display: props.isSuccess ? 'none' : 'inline'}}>
			<Space h={70} color='#ffffff'/>
			<div  className="barCenter" style={{color:'#a62126	', fontWeight: 500, fontSize: 22, fontFamily: 'Sarabun', 
			paddingLeft:20, paddingRight:20, paddingTop:40}}>
			การสั่งซื้อไม่สำเร็จสำเร็จ กรุณาลองใหม่อีกครั้ง
			</div>
			<div  className="barCenter" style={{color:'#000000', fontWeight: 400, fontSize: 16, fontFamily: 'Sarabun',marginTop:8}}>
			{props.errorMessage}
			</div>
			<div style={{height:50}}/>
		</div>
	</div>
	);
}

export default Landing;