import { React, useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Button, Row} from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { getDoc, doc } from "firebase/firestore";
import { db } from './firebase'; 
import renderHTML from 'react-render-html';

import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import './App.css';
import { Space, HeaderBar, ImageBar, TextBar } from './component/PageElement';
import { Icon } from '@iconify/react';
import LineIcon from './images/line_crop.png';
import FBIcon from './images/fb.png';
import EmailIcon from './images/email.png';

import ReactPixel from 'react-facebook-pixel';

function SalePage() {		

	const [salepage, setSalepage] = useState("");
	const [isSubmitting, setSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(true);	
	
	const queryParameters = new URLSearchParams(window.location.search)
	const hhid = queryParameters.get("hhid")
	
	const initialPixel = async (pid) => {
		//var pid = '976483400280595';
		console.log('init pixel id:'+pid);
		ReactPixel.init(pid)
		ReactPixel.pageView(); 
		
		/*		
		ReactPixel.init(pxid)
		ReactPixel.pageView(); // For tracking page view
		*/
		/*const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
		const options = {
		  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
		  debug: false, // enable logs
		};
		ReactPixel.init('721440493055420', advancedMatching, options);
		ReactPixel.init(pxid, options);
				
		ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
		ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
		ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
		ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.*/
		
	}
		
	const getSaleDetail = async () => {
		console.log('get sale page of:'+hhid);
		const docRef = doc(db, "salepages",  hhid);
		const docSnap = await getDoc(docRef)
	  
		if (docSnap.exists()) {
			console.log("Document data:", JSON.stringify(docSnap.data())+','+docSnap.id)
			setSalepage(docSnap.data()); 
			console.log('pixel id:'+docSnap.data().facebook_pixel);
			initialPixel(docSnap.data().facebook_pixel);
		} else {
			// doc.data() will be undefined in this case
			console.log("No such document!")
		}
	}
	
	useEffect( () => {		
		getSaleDetail();
		console.log('test async:'+salepage.store_contact);
		setIsLoading(false);	
	}, [])
	
	return (
	  <div>
		  <ImageSlider list={salepage.product_images}/>
		  <PriceBar ori_price={salepage.ori_price} sale_price={salepage.sale_price} discount={salepage.discount}/>
		  <DeliveryBar list={salepage.delivery_options}/>
		  <TextBar txt={salepage.product_name} txtStyle="bigText"/>	
		  <Space color="white" h={10}/>
		  <Space color="#eeeeee" h={1.5}/>
		  <PromotionBar list={salepage.promotions}/>
		  <Space color="#eeeeee" h={1.5}/>
		  <RemarkBar list={salepage.remarks}/>
		  <Space color="#eeeeee" h={3}/>	
		  <HeadTableBar/>
		  <Space color="#eeeeee" h={1.5}/>
		  <DetailBar list={salepage.product_details}/>
		  <HtmlBar detail={salepage.product_detail_html}/>
		  <Space color="#eeeeee" h={10}/>
		  <OrderInstruction src={salepage.order_instruction}/>
		  <HeaderBar txt="เกี่ยวกับเรา"/>
		  <Space color="#eeeeee" h={1.5}/>
		  <AboutBar data={salepage.about_us}/>
		  <Space color="#eeeeee" h={10}/>
		  <LinkBar list={salepage.links}/>
		  <Space color="#eeeeee" h={3}/>
		  <Space color="#eeeeee" h={80}/>
		  <Footer store_contact={salepage.store_contact} promotions={salepage.promotions} isLoading={isLoading} hhid={hhid}
		  product_id={salepage.id} product_name={salepage.product_name} product_images={salepage.product_images} uid={salepage.created_by}
		  payment_options={salepage.payment_options} bank_accounts={salepage.bank_accounts} pixel={salepage.facebook_pixel}/>
		  
		  
	  </div>
	);
}
/*
function HtmlLink(props) {
	return(
			<div style={{ display: showHtmlLink ? 'flex' : 'none' }} className='modal'>
				<div style={{width:'80%', margin:'24px', padding:'32px', height:'auto', backgroundColor:'#ffffff'}}>
				<Row style={{marginTop:12,marginBottom:24}}>
				<h4><b>props.desc</b></h4>
				</Row>
				<Row className="center" >  
					<div style={{width:'100%',height:400,padding:20,border: '1px solid #888'}}>
					<span className="normalText">{renderHTML(props.html)}</span>
					</div>					
				</Row>
				<Row className="center" style={{marginTop:'20px'}} >  
				<Button className="center loginButton" style={{width:'auto'}} onClick={()=>setShowHtmlLink(false)}>ปิด</Button>
				</Row>
				</div>
			</div>
	);
}*/

function OrderInstruction(props) {
	console.log('src order:'+props.src);
	if (!( !props.src || props.src === '' )){		
		return(
			<div>
			<HeaderBar txt="ขั้นตอนการสั่งซื้อ"/>
			<Space color="#eeeeee" h={10}/>
			<ImageBar src={props.src} alt="ขั้นตอนสั่งซื้อ"/>
			<Space color="#eeeeee" h={10}/>
			</div>
		)
	} else {
		return (
			<div></div>
		)
	}
}
/*
{
	<Space color="#eeeeee" h={1.5}/>
	
			 if(salepage.order_instruction!=='' || salepage.order_instruction!==undefined) {
			  <HeaderBar txt="ขั้นตอนการสั่งซื้อ"/>
			  <Space color="#eeeeee" h={1.5}/>
			  <ImageBar src={salepage.order_instruction} alt="ขั้นตอนสั่งซื้อ"/>
			  <Space color="#eeeeee" h={10}/>
			 } else {
			  <Space color="#eeeeee" h={10}/>
			 }
		 }
*/

function ImageSlider(props) {
	if(!props.list) return <div className="imageLoading"><p>Loading...</p></div>
	
	var size = props.list.length;
	
	console.log('slide :'+props.list+','+size);
	var imageSlide = [];
	for (var i = 0; i < size; i++) {
		console.log('image:'+props.list[i]);
		imageSlide.push(
				<div key={i}>
                    <img src={props.list[i]} alt="product"/>
                </div>
			);
	}
	
	return (
		<Carousel swipeable={true} emulateTouch={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
			{imageSlide}
        </Carousel>
    );
}


function PriceBar(props) {
	return(
	<div>
      <div className="priceBar">
        <div className="col-6" style={{paddingBottom:0}}>
			<span className="priceText">฿{props.sale_price}</span>
			<span className="oriPriceText">฿{props.ori_price}</span>
		</div>
		<div className="col-6" style={{textAlign: 'left'}}>
			<span className="discountText">{props.discount}% off</span>
		</div>
      </div>
    </div>
	);
}

function DeliveryBar(props) {
	if(props.list) {	
		var size = props.list.length;	
		
		var dList = [];
		for (var i = 0; i < size; i++) {
			dList.push(
				<span className="deliveryText" key={i}>{props.list[i]}</span>
			);
		}
	
		return(
		<div style={{backgroundColor: "white", paddingLeft:10,paddingTop:4,paddingBottom:4,display: 'flex',justifyContent:'left'}} >
			{dList}
		</div>
		);
	}
}

function PromotionBar(props){
	if(props.list) {	
		var size = props.list.length;	
		console.log('pro :'+props.list+','+size);	

		var proList = [];
		for (var i = 0; i < size; i++) {
			console.log('pro:'+props.list[i]);
			if(i===0) {
				proList.push(
				<div  className='priceBar' key={i}>
					<div className="col-3">
					<span className="smallText"><b>โปรโมชั่น :</b></span> 
					</div>
					<div className="col-9">
					<span className="smallText" style={{color:'#2d72e0'}}>{props.list[i].promo_desc}</span>
					</div>
				</div>
				);
			} else {
				proList.push(
				<div  className='priceBar' key={i}>
					<div className="col-3">
					<span className="smallText"><b></b></span> 
					</div>
					<div className="col-9">
					<span className="smallText" style={{color:'#2d72e0'}}>{props.list[i].promo_desc}</span>
					</div>
				</div>
				);			
			}
		}
		return (
		<div style={{margin:10}}>
			{proList}
		</div>
		);
	}

	
}
function HeadTableBar() {
	return(
	<div className="tableWithBorder">
      <div className="priceBar">
        <div className="col-6 tableMiddleBorder" style={{textAlign: 'center',padding: 10}} >
			<span className="normalText">รายละเอียดสินค้า</span>
		</div>
		<div className="col-6" style={{textAlign: 'center',padding: 10}} >
			<span className="normalText">รีวิว</span>
		</div>
      </div>
    </div>
	);
}
/*
function ImageBar(props) {
	return (
		<div>
			<img src={props.src} width="100%" alt={props.alt}/>
        </div>            
    );
}
function Space(props) {
	return(
	<div style={{height:props.h,backgroundColor:props.color}} >
    </div>
	);
}
function HeaderBar(props) {
	return(
	<div className="headerBar">
      <div className="priceBar">
        <span className="bigText"><b>{props.txt}</b></span>
      </div>
    </div>
	);
}
function TextBar(props) {
	var txtColor = '#000000';
	if(props.color!=null) {
		txtColor = props.color;
	}
	if(props.icon!=null) {
		return(
		<div style={{backgroundColor: "#ffffff", paddingLeft:10,paddingTop:4,paddingBottom:4}}>
			<Icon icon={props.icon} className="iconSmall" style={{color:txtColor}}/>
			<span className={props.txtStyle} style={{color:txtColor}}>{props.txt}</span>
		</div>
		);
	} else {
		return(
		<div style={{backgroundColor: "#ffffff", paddingLeft:10,paddingTop:4,paddingBottom:4}}>
			<span className={props.txtStyle} style={{color:txtColor}}>{props.txt}</span>
		</div>
		);	
	}	
}
*/
function AboutBar(props) {
	
	if(props.data) {
		console.log('icon:'+props.data.about_icon);
		return(
		<div>
		  <div className="priceBar">
			<div className="col-8" style={{textAlign: 'left',padding: 10}} >
				<span className="smallText">{props.data.about_detail}</span>
			</div>
			<div className="col-4" style={{textAlign: 'center',padding: 10}} >
				<Icon icon="{props.data.about_icon}"  className="iconBig"/>
			</div>
		  </div>
		</div>
		);
	}
}
function HtmlBar(props) {
	console.log('html:'+props.detail);
	var html = props.detail?.replaceAll("<img", "<img width='100%'");
	
	return (
			<div style={{width:'100%'}} dangerouslySetInnerHTML={{ __html: html }}/>
	);
}

function DetailBar(props){
	if(props.list) {	
		var size = props.list.length;	
		console.log('detail :'+props.list+','+size);	

		var detailList = [];
		for (var i = 0; i < size; i++) {
			if(props.list[i].type === 'img') {			
				detailList.push(
					<div key={i}>
						<img src={props.list[i].content} width="100%" alt="product detail" />
					</div> 
				);
			} else if(props.list[i].type === 'text') {
				detailList.push(
					<div style={{paddingLeft:16,paddingRight:16,paddingBottom:16,paddingTop:16}} key={i}>
						<span className="normalText">{renderHTML(props.list[i].content)}</span>
					</div>
				);
			}
		}
		return (
			<div>{detailList}</div>
		);
		
	}
}

function RemarkBar(props){
	if(props.list) {	
		var size = props.list.length;	
		console.log('remark :'+props.list+','+size);	

		var remarkList = [];
		for (var i = 0; i < size; i++) {
			if(props.list[i].remark_icon!=null) {
				remarkList.push(
				<div style={{backgroundColor: "#ffffff", paddingLeft:10,paddingTop:4,paddingBottom:4}} key={i}>
					<Icon icon={props.list[i].remark_icon} className="iconSmall" style={{color:'#999999'}}/>
					<span className="smallText" style={{color:'#999999'}}>{props.list[i].remark_desc}</span>
				</div>
				);
			} else {
				remarkList.push(
				<div style={{backgroundColor: "#ffffff", paddingLeft:10,paddingTop:4,paddingBottom:4}} key={i}>
					<span className="smallText" style={{color:'#999999'}}>{props.list[i].remark_desc}</span>
				</div>
				);	
			}
			remarkList.push(
			<div style={{height:1.5,backgroundColor:'#eeeeee'}}  key={i+100}></div>
			);
		}
		return (
			<div>{remarkList}</div>
		);
		
	}
}
/*
function openOwnLink(content){
	console.log('open own link:'+content);
}
*/
function LinkBar(props){
	
	const [showHtmlLink, setShowHtmlLink] = useState(false);
	const HtmlLink = (link) => {
		console.log('html:'+JSON.stringify(link));
		if(link.html || link.html !== undefined){
		return(		
			<div style={{ display: showHtmlLink ? 'flex' : 'none' }} className='modal'>
				<div style={{width:'100%', margin:'8px', height:'auto', backgroundColor:'#ffffff'}}>
				<Row style={{padding:8,marginTop:16, marginBottom:8}}>
				<h4><b>{link.desc}</b></h4>
				</Row>
				<Space color="#eeeeee" h={2}/>
				<Row className="center" >  
					<div style={{width:'100%',height:450,padding:24,marginLeft:8,marginRight:8,border: '0px solid #888'}}>
					<span className="normalText">{renderHTML(link.html)}</span>
					</div>					
				</Row>
				<Row className="center" style={{marginTop:'20px'}} >  
				<Button className="center loginButton" style={{width:'auto',marginBottom:16}} onClick={()=>setShowHtmlLink(false)}>ปิด</Button>
				</Row>
				</div>
			</div>
		);
		} else {
			return (<div></div>);
		}
	}
	
	const openOwnLink = () => {		
		console.log('open own link:');
		setShowHtmlLink(true);
	}
	
	if(props.list) {	
		var size = props.list.length;	
		console.log('links :'+props.list+','+size);	

		var linkList = [];
		for (var i = 0; i < size; i++) {
			console.log('links:'+props.list[i].link_url+","+props.list[i].link_content);
			var ownLink = false;
			var url = props.list[i].link_url;
			var content = props.list[i].link_content;
			var desc = props.list[i].link_desc;
			if(url === "ใช้ที่สร้างใหม่") {
				ownLink = true;
			} else {
				if(!url.includes("http://")){
					url = "http://"+url;
				}
			}
			console.log('ownlink:'+ownLink);
			linkList.push(
				<div className="tableWithBorder" key={i}>
				  <div className="priceBar">
					<div className="col-12 tableMiddleBorder" style={{textAlign: 'center',padding: 10}} >
						<span className="smallText">
						{
							ownLink?
							<div onClick={() => openOwnLink(content)} className="linkGrey">
							{desc}
							</div>
							:							
							<a href={url} className="linkGrey" target="_blank" >{desc}</a>
						}
						</span> 
					</div>
				  </div>
				  <HtmlLink html={content} desc={desc}/>
				</div>						
			);
		}
		return (
			<div>{linkList}</div>
		);
		
	}
}

function Footer(props) {
	/*let url ="";
	console.log('contact:'+props.store_contact);
	if(props.store_contact!==undefined) {
		if(props.store_contact.contact_type!==undefined && props.store_contact.contact_type==='line') {
				url = 'https://line.me/R/ti/p/'+props.store_contact.contact_info;
		} else if(props.store_contact.contact_type==='facebook') {
				url = 'http://m.me/'+props.store_contact.contact_info;
		} else if(props.store_contact.contact_type==='email') {
				url = 'mailto:'+props.store_contact.contact_info;
		}
		console.log('contact url:'+url+','+props.store_contact.contact_type);
	} else {
	}*/
	
	
	const contactSupport = () => {
		var url = "";
		if(props.store_contact.contact_type!==undefined && props.store_contact.contact_type==='line') {
			url = 'https://line.me/R/ti/p/'+props.store_contact.contact_info;
		} else if(props.store_contact.contact_type!==undefined && props.store_contact.contact_type==='facebook') {
			url = 'http://m.me/'+props.store_contact.contact_info;
		} else if(props.store_contact.contact_type!==undefined && props.store_contact.contact_type==='email') {
			url = 'mailto:'+props.store_contact.contact_info;
		}
		console.log('click contact:'+url);		
		window.open(url, '_blank', 'noopener,noreferrer');
		//window.open(props.store_contact.contact_info, '_blank', 'noopener,noreferrer');
		//console.log('url:'+url);
		//window.open(url, '_blank', 'noopener,noreferrer');
	}
	//console.log('isLoading:'+props.isLoading+','+props.promotions);	
	/*let contactType = '';
	if(!props.isLoading) {
		contactType = props.store_contact.contact_type;
	}*/
	console.log('test fix footer:'+( props.store_contact !== undefined && props.store_contact.contact_type !== undefined));
	console.log('test fix footer2:'+props.isLoading);
	
	const navigate = useNavigate();
	console.log('store contact:'+props.store_contact+','+props.hhid);
	
	const openPayment = () => {
		console.log('openPayment:'+props.hhid+','+props.promotions+','+props.product_id+','+props.product_name+','+props.product_images);
		trackClickBuy();		
		
		let state = { promotions:props.promotions,saleid:props.hhid, product_id: props.product_id, 
		product_name: props.product_name, product_image: props.product_images,uid:props.uid, payment_options:props.payment_options,
		bank_accounts:props.bank_accounts, pixel: props.pixel}
		console.log('state:'+state+','+JSON.stringify(state));
		
		navigate("/payment", {state})
	}
	
	const trackClickBuy = async () => {
		console.log('tracking buy');
		ReactPixel.trackCustom('clickBuyNow') 		
	}
	/*
	return(
	<div>
		<div className="col-3 center" style={{display: (props.isLoading) ? 'none' : 'flex', paddingBottom:0,paddingTop:0}} >
			<div className="vertical-center"  onClick={contactSupport} style={{backgroundColor:'red'}}>
			<img src={LineIcon} width="40" height="38" style={{display: 'flex', paddingRight:4}} alt="contact"/>
			สอบถาม
			</div>		
		</div>
	</div>
	);*/
	
	return(
	<div>
	  {
	   props.store_contact !== undefined && props.store_contact.contact_type !== undefined && props.store_contact.contact_type !== '' ?	  
	   <div className="footerBar">
        <div className="col-3 center" style={{display: (props.isLoading) ? 'none' : 'flex', paddingBottom:0,paddingTop:0}} >
			<div className="vertical-center"  onClick={contactSupport}>
			<img src={LineIcon} width="40" height="38" style={{display: (!(!props.store_contact || props.store_contact.contact_type.length===0) && props.store_contact.contact_type === 'line') ? 'flex' : 'none', paddingRight:4}} alt="contact"/>
			<img src={EmailIcon} width="40" height="38" style={{display: (!(!props.store_contact || props.store_contact.contact_type.length===0) &&  props.store_contact.contact_type === 'email') ? 'flex' : 'none', paddingRight:4}} alt="contact"/>
			<img src={FBIcon} width="40" height="38" style={{display: (!(!props.store_contact || props.store_contact.contact_type.length===0) && props.store_contact.contact_type === 'facebook') ? 'flex' : 'none', paddingRight:4}} alt="contact"/>
			สอบถาม
			</div>		
		</div>
		<div className="col-9" style={{display: 'flex',justifyContent:'right',paddingBottom:10,paddingTop:10,marginLeft:0}}>			
			{ 
			  !props.promotions || props.promotions.length===0
			  ? <Link to="#" className="buyButton" onClick={ (event) => event.preventDefault() }  style={{backgroundColor:'#cccccc'}}>
			  <Button className="buyButton">Loading</Button>
			  </Link>
			  : 
			  <Button className="buyButton" onClick={openPayment}  style={{paddingTop:8}}>ซื้อทันที</Button>
			}			
		</div>
		</div>
		:
		<div className="footerBar">
		<div className="col-12" style={{display: 'flex',justifyContent:'right',paddingBottom:10,paddingTop:10,}}>			
			{ 
			  !props.promotions || props.promotions.length===0
			  ? <Link to="#" className="buyButton" onClick={ (event) => event.preventDefault() }  style={{backgroundColor:'#cccccc'}}>
			  <Button className="buyButton">Loading</Button>
			  </Link>
			  : 
			  <Button className="buyButton" onClick={openPayment}>ซื้อทันที</Button>
			}			
		</div>
		</div>
	  }
    </div>
	);
}


export default SalePage;


/*
<Link to="/payment" className="buyButton" state={{promotions:props.promotions}} >
			  <Button className="buyButton" onClick={openPayment}>ซื้อทันที</Button>
			  </Link>
<Link to="/payment" className="buyButton" state={{promotions:props.promotions,saleid:props.hhid,test:'test'}} >
			  <Button className="buyButton" onClick={openPayment}>ซื้อทันที</Button>
			  </Link>
*/