import React from "react";
import {Route, Routes} from "react-router-dom";
import {Navigate} from 'react-router-dom';

import Home from "./Home";
import LoginPage from "./Login";
import RegisterPage from "./Register";
import MemberPage from "./Member";
import LogoutPage from "./Logout";
import CreateSalePage from "./CreateSalePage";
import StatusPage from "./Status";
import SalePage from "./SalePage";
import PaymentPage from "./Payment";
import LandingPage from "./Landing";
import ManagePage from "./ManagePages";
import EditSalePage from "./EditSalePage";
import OrdersPage from "./Orders";
import OrderDetailsPage from "./OrderDetails";
import OrderList from "./OrderList";
import Redirect from "./RedirectPage";
import TransferPage from "./Transfer";
/*

import EditTest from "./EditTest";

function App() {
	return (

		<div>
			<Routes>
			<Route exact path="/" element={<Navigate to="/home" />} />
			<Route exact path="/"  element={<Navigate to="/home" />} />
				<Route exact path="/" element={<Home/>} />
				<Route exact path="/page1" element={<Page1/>} />
				<Route exact path="/page2" element={<Page2/>} />
				<Route exact path="/salepage" element={<SalePage/>} />				
				<Route exact path="/login" element={<LoginPage/>} />		
				<Route exact path="/register" element={<RegisterPage/>} />
				<Route exact path="/member" element={<MemberPage/>} />
				<Route exact path="/createsalepage" element={<CreateSalePage/>} />
				<Route exact path="/status" element={<StatusPage/>} />
				<Route exact path="/edittest" element={<EditTest/>} />
			</Routes>
		</div>
	);
}
*/

function App() {
	
	
	return (
		<div>			
			<Routes>							
				<Route exact path="/" element={<Home/>} />
				<Route exact path="/home" element={<Home/>} />
				<Route exact path="/login" element={<LoginPage/>} />		
				<Route exact path="/register" element={<RegisterPage/>} />
				<Route exact path="/member" element={<MemberPage/>} />
				<Route exact path="/logout" element={<LogoutPage/>} />	
				<Route exact path="/createsalepage" element={<CreateSalePage/>} />
				<Route exact path="/status" element={<StatusPage/>} />
				<Route exact path="/salepage" element={<SalePage/>} />
				<Route exact path="/payment" element={<PaymentPage/>} />		
				<Route exact path="/landing" element={<LandingPage/>} />	
				<Route exact path="/managepage" element={<ManagePage/>} />
				<Route exact path="/editsalepage" element={<EditSalePage/>} />
				<Route exact path="/orders" element={<OrdersPage/>} />
				<Route exact path="/orderdetails" element={<OrderDetailsPage/>} />
				<Route exact path="/orderlist" element={<OrderList/>} />
				<Route exact path="/transfer" element={<TransferPage/>} />
			</Routes>
		</div>
	);
}


export default App
/*
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"
import { BrowserRouter, Routes, Route, Link, useNavigate} from "react-router-dom"
import { Alert, Form, Button, Card } from 'react-bootstrap';
import Auth from "./Auth"

function App() {
  const navigate = useNavigate();
  
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate('/contacts');
  };

  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  const navigateToAuth = () => {
    // 👇️ navigate to /
    navigate('/Auth');
  };
  
  return (
     <div>
      <div>
        <Button onClick={navigateHome}>Home</Button>
        <hr />
        <Button onClick={navigateToContacts}>Contacts</Button>
		<hr />
        <Button onClick={navigateToAuth}>Login</Button>
		<hr/>
		<Link to={'/Auth'} >
		<button > Click Me </button>
		</Link>
		<hr/>

        <Routes>
          <Route path="/contacts" element={<Contacts />} />
          <Route exact path="/" element={<Home />} />
		  <Route path="/Auth" element={<Auth />} />
        </Routes>
      </div>
    </div>

  );
}

function Home() {
  return <h2>Home</h2>;
}

function Contacts() {
  return <h2>Contacts</h2>;
}

export default App
*/